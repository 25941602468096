import { useState } from "react";
import { IProduct } from "../../../../../types/proCart.types";
import style from "./productItem.module.scss";
import { DEFAULT_PRO_PRODUCT } from "../../../../../utils/booking.init";
import WorkshopProInfoForm from "../../workshopInfoForm/WorkshopProInfoForm";
import translate from "../../../../../utils/translation";
import WorkshopId from "../../../../workshopId/workshopId";
import { Delete } from "@mui/icons-material";

interface ProductItemProps {
  product: IProduct;
  handleEditProduct: () => void;
  handleDeleteProduct: () => void;
}

export default function ProductItem({
  product,
  handleEditProduct,
  handleDeleteProduct,
}: ProductItemProps) {
  return (
    <li className={style["product-item"]}>
      <div className={style["product-item-left"]} onClick={handleEditProduct}>
        <WorkshopId workshopId={product.workshopId} size="xxx-small" />
        <div className={style["product-brand-group"]}>
          {`${translate(product.productGroup)} - ${product.brand}`}
        </div>
      </div>
      <div className={style["dots"]}></div>
      <div className={style["delete"]} onClick={handleDeleteProduct}>
        <Delete />
      </div>
    </li>
  );
}
