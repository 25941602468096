import { useState } from "react";
import TrackerLink from "../../../../components/TrackerLink/TrackerLink";
import styles from "./orders.module.scss";
import Input from "../../../../components/inputs/Input/Input";
import Button from "../../../../components/Button/Button";
import { toast } from "react-toastify";
import { STD_ERROR_MESSAGE } from "../../../../utils/constants";
import { fetchTracker, getTracker } from "../../../../requests/tracker";
import { TOAST_ERROR_OPTIONS } from "../../../../utils/toast.options";

interface OrdersProps {
  trackers: string[];
  onTrackersChange: (trackers: string[]) => void;
}

export default function Orders({ trackers, onTrackersChange }: OrdersProps) {
  const [newTracker, setNewTracker] = useState<string>("");

  return (
    <div className={styles["orders"]}>
      {trackers.map((trackId, index) => (
        <div className={styles["order-item"]} key={index}>
          <TrackerLink trackId={trackId} />
          <Button
            className={styles["remove-button"]}
            onClick={() => removeTracker(trackId)}
          >
            retirer
          </Button>
        </div>
      ))}
      <div className={styles["add-order-input"]}>
        <Input
          className={styles["tracker-input"]}
          name={"tracker"}
          placeholder="Veuillez entrez un trackId..."
          onChange={handleOnChange}
          value={newTracker}
          type="text"
        />
        <Button className={styles["add-button"]} onClick={handleAddTracker}>
          ajouter
        </Button>
      </div>
    </div>
  );

  function addTracker(tracker: string) {
    if (trackers.includes(tracker)) {
      return;
    }
    onTrackersChange([...trackers, tracker]);
  }

  function removeTracker(tracker: string) {
    onTrackersChange(trackers.filter((t) => t !== tracker));
  }

  function handleOnChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    setNewTracker(target.value);
  }

  async function handleAddTracker(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.preventDefault();
    if (!newTracker) {
      return;
    }
    try {
      await getTracker(newTracker);
      addTracker(newTracker);
      setNewTracker("");
    } catch (e: any) {
      toast.error(
        "ID de la commande erroné ou inexistant.",
        TOAST_ERROR_OPTIONS
      );
    }
  }
}
