const trackerStateMap = new Map<string, number>([
  ["UNKNOWN", 0],
  ["ESTIMATE_PENDING", 1],
  ["ESTIMATE_REACHED", 2],
  ["ESTIMATE_SENT", 3],
  ["CART_PENDING", 4],
  ["INCOMING_PENDING", 5],
  ["INCOMING_TRANSITING", 6],
  ["INCOMING_DELIVERED", 7],
  ["COLLECTED", 8],
  ["QUOTE_SENT", 9],
  ["PAYED", 10],
  ["REFURBISHED", 11],
  ["CONTROLLED", 12],
  ["DELIVERED", 13],
  ["CANCELED", 14],
  ["NON_FEASIBLE", 15],
]);

const eventStateMap = new Map<string, number>([
  ["ESTIMATE_PENDING", 1],
  ["ESTIMATE_REACHED", 2],
  ["ESTIMATE_SENT", 3],
  ["CART_PENDING", 4],
  ["INCOMING_PENDING", 5],
  ["INCOMING_TRANSITING", 6],
  ["INCOMING_DELIVERED", 7],
  ["LOGISTIC_INCOMING", 8],
  ["QUOTE_SENT", 9],
  ["BOOKING_PAYMENT_RECEIVED", 10],
  ["LOGISTIC_OUTGOING", 11],
  ["LOGISTIC_DONE", 12],
  ["OUTGOING_DELIVERED", 13],
  ["ESTIMATE_CANCELED", 14],
  ["CART_CANCELED", 14],
  ["QUOTE_CANCELED", 14],
  ["CANCELLATION_PAYMENT_RECEIVED", 14],
  ["NON_FEASIBLE", 15],
]);

const ESTIMATE_PENDING = 1;
const ESTIMATE_REACHED = 2;
const ESTIMATE_SENT = 3;
const CART_PENDING = 4;
const INCOMING_PENDING = 5;
const INCOMING_TRANSITING = 6;
const INCOMING_DELIVERED = 7;
const COLLECTED = 8;
const QUOTE_SENT = 9;
const PAYED = 10;
const REFURBISHED = 11;
const CONTROLLED = 12;
const DELIVERED = 13;
const CANCELED = 14;
const NON_FEASIBLE = 15;

export {
  trackerStateMap,
  eventStateMap,
  ESTIMATE_PENDING,
  ESTIMATE_REACHED,
  ESTIMATE_SENT,
  CART_PENDING,
  INCOMING_PENDING,
  INCOMING_TRANSITING,
  INCOMING_DELIVERED,
  COLLECTED,
  QUOTE_SENT,
  PAYED,
  REFURBISHED,
  CONTROLLED,
  DELIVERED,
  CANCELED,
  NON_FEASIBLE,
};
