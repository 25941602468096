import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../errorPage/error-page";
import { authloader } from "../../routes";
import Shops from "./index";
import Edit from "./edit/Edit";

export const route: RouteObject = {
  path: "/shops",
  element: <Outlet />,
  errorElement: <ErrorPage />,
  loader: authloader,
  children: [
    {
      index: true,
      element: <Shops />,
      loader: authloader,
    },
    {
      path: "edit/:id",
      element: <Edit />,
      loader: authloader,
    },
  ],
};
