import { useLoaderData } from "react-router-dom";
import { fetchOperation } from "../../../requests/operation";
import { OperationForm } from "../../../types/accounting.types";
import Create from "../create/create";
import { authloader } from "../../../routes";

export async function loader({
  request,
  params,
}: {
  request: Request;
  params: any;
}) {
  try {
    const operation = await fetchOperation({ request, params });
    authloader();
    return { operation };
  } catch (error: any) {
    throw new Response(error);
  }
}

export default function Edit() {
  const { operation } = useLoaderData() as {
    operation: OperationForm;
    productGroups: string[];
    operationGroups: string[];
    operationTypes: string[];
  };

  if (!operation) {
    return <>Opération introuvable.</>;
  }

  return <Create operation={operation} />;
}
