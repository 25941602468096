import { ReactNode } from "react";
import styles from "./overviewSection.module.scss";
import clsx from "clsx";

interface OverviewSectionProps {
  children: ReactNode;
  className?: string;
}

export default function OverviewSection({
  children,
  className,
}: OverviewSectionProps) {
  return (
    <section className={clsx(styles["overview-section"], className)}>
      {children}
    </section>
  );
}
