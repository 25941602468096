import { ReactNode } from "react";
import styles from "./overviewSectionHeader.module.scss";
import clsx from "clsx";
import { ExpandLess, ExpandMore, Launch } from "@mui/icons-material";

interface OverviewSectionHeaderProps {
  title: string;
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
  expanded?: boolean;
}

export default function OverviewSectionHeader({
  title,
  className,
  children,
  onClick,
  expanded = false,
}: OverviewSectionHeaderProps) {
  return (
    <div className={clsx(styles["section-header"], className)}>
      <h3 className={styles["title"]} onClick={onClick}>
        {title}{" "}
        {!!onClick && <IconDispatch expanded={expanded} title={title} />}
      </h3>
      {children && <>{children}</>}
    </div>
  );
}

interface IconDispatchProps {
  title: string;
  expanded: boolean;
}

function IconDispatch({ title, expanded }: IconDispatchProps) {
  switch (true) {
    case title.includes("Notes"):
      return expanded ? (
        <ExpandLess className={styles["icon"]} />
      ) : (
        <ExpandMore className={styles["icon"]} />
      );
    default:
      return <Launch className={styles["icon"]} />;
  }
}
