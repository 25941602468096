import InputContainer from "../forms/inputs/inputContainer/inputContainer";
import ChecklistItem from "./checklistItem";
import style from "./checklist.module.scss";
import translate from "../../utils/translation";

interface ShippingMethodChecklistProps {
  title: string;
  items: Array<{ checked: boolean; name: string }>;
  onChange: (items: Array<{ checked: boolean; name: string }>) => void;
}
export default function ShippingMethodChecklist({
  title,
  items,
  onChange,
}: ShippingMethodChecklistProps) {
  function handleChange({ name, checked }: { name: string; checked: boolean }) {
    let itemIndex = items.findIndex((i) => i.name === name);
    if (itemIndex >= 0) {
      let update = [...items];
      update[itemIndex].checked = checked;
      onChange(update);
    }
  }
  return (
    <InputContainer>
      <label>{title}</label>
      <div className={style["checklist"]}>
        {items.map((item, index) => (
          <ChecklistItem
            key={`checklist-${item.name}-${index}`}
            name={item.name}
            text={translate(item.name)}
            checked={item.checked}
            onChange={handleChange}
          />
        ))}
      </div>
    </InputContainer>
  );
}
