import { useState } from "react";
import { IContact } from "../../../types/logistic.types";
import style from "./contactForm.module.scss";
import { ICustomer } from "../../../types/customer.type";
import Input from "../../inputs/Input/Input";
import PhoneInput from "../../PhoneInput/PhoneInput";
import clsx from "clsx";

interface contactProps {
  detailedForm?: boolean;
  autoComplete?: boolean;
  contact: IContact;
  setContact: (contact: IContact) => void;
  className?: string;
}

export default function ContactForm({
  detailedForm = true,
  autoComplete = true,
  contact,
  setContact,
  className,
}: contactProps) {
  const [autoCompleteDisable, setAutoCompleteDisable] =
    useState<boolean>(false);

  return (
    <div className={clsx(style["contact-form"], className)}>
      <Input
        label="Prénom"
        name={"givenname"}
        handleAutoComplete={autoComplete ? handleAutoComplete : undefined}
        autoCompleteDisable={autoCompleteDisable}
        onChange={handleOnChange}
        value={contact?.givenname ?? ""}
        // disabled={!!contact.id}
        type="text"
      />
      <Input
        label="Nom"
        name={"familyname"}
        handleAutoComplete={autoComplete ? handleAutoComplete : undefined}
        autoCompleteDisable={autoCompleteDisable}
        onChange={handleOnChange}
        value={contact?.familyname ?? ""}
        // disabled={!!contact.id}
        type="text"
      />
      <PhoneInput value={contact?.phone || ""} onChange={handleOnChange} />
      <Input
        autoCompleteDisable={autoCompleteDisable}
        label="Email"
        name={"email"}
        handleAutoComplete={autoComplete ? handleAutoComplete : undefined}
        onChange={handleOnChange}
        value={contact?.email ?? ""}
        // disabled={!!contact.id}
        type="email"
      />
      {detailedForm && (
        <>
          <Input
            label="Nom Complet"
            disabled
            name={"displayname"}
            value={contact?.displayname ?? ""}
            type="text"
          />
          <Input
            label="Organisation"
            name={"organization"}
            onChange={handleOnChange}
            value={contact?.organization ?? ""}
            type="text"
          />
        </>
      )}
    </div>
  );

  function handleOnChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    let displayname = contact?.displayname || "";
    if (target.name === "givenname") {
      displayname = `${target.value} ${contact?.familyname || ""}`;
    }

    if (target.name === "familyname") {
      displayname = `${contact?.givenname || ""} ${target.value}`;
    }
    setContact({ ...contact, [target.name]: target.value, displayname });
  }

  function handleAutoComplete(customer: ICustomer | null) {
    if (!customer) {
      setContact({
        ...contact,
        familyname: "",
        givenname: "",
        phone: "",
        email: "",
        displayname: "",
        id: "",
      });
      setAutoCompleteDisable(false);
      return;
    }
    setContact({
      ...contact,
      familyname: customer.familyname,
      givenname: customer.givenname,
      phone: customer.phone,
      email: customer.email,
      displayname: customer.givenname + " " + customer.familyname,
      id: customer.id,
    });
    setAutoCompleteDisable(true);
  }
}
