import style from "./selectState.module.scss";
import Select from "../../../../components/Select/Select";
import translate from "../../../../utils/translation";
import { STATE_OPTIONS } from "../../../../utils/shipping.init";
import getStatusColor from "../../../../utils/translation/statusColor";

interface SelectDirectionProps {
  label?: string;
  state: string;
  setState: (value: string) => void;
  stateOption: string[];
}

export default function SelectState({
  label,
  state,
  setState,
  stateOption,
}: SelectDirectionProps) {
  return (
    <div className={style["state"]}>
      <label>{label}</label>
      <Select
        className={style["select-state"]}
        style={{
          backgroundColor: getStatusColor(state),
          color: "white",
          borderRadius: "3px",
        }}
        optionsList={stateOption}
        setValue={handleChangeState}
        value={translate(state)}
      />
    </div>
  );

  function handleChangeState(value: string) {
    setState(value);
  }
}
