import { toast } from "react-toastify";
import SectionFormTemplate from "../../../../../../components/forms/sectionFormTemplate/SectionFormTemplate";
import Input from "../../../../../../components/inputs/Input/Input";
import Select from "../../../../../../components/Select/Select";
import { useDebounce } from "../../../../../../hooks/useDebounce";
import { OperationForm } from "../../../../../../types/accounting.types";
import styles from "./informationSection.module.scss";
import { TOAST_ERROR_OPTIONS } from "../../../../../../utils/toast.options";
import { checkIfSlugExist } from "../../../../../../requests/operation";
import { useState } from "react";
import { InfoOutlined } from "@mui/icons-material";

interface InformationSectionProps {
  operationForm: OperationForm;
  operationGroups: string[];
  operationTypes: string[];
  productGroups: string[];
  onChange: (name: string, value: string | boolean) => void;
}

export default function InformationSection({
  operationForm,
  operationGroups,
  operationTypes,
  productGroups,
  onChange,
}: InformationSectionProps) {
  const [slugExist, setSlugExist] = useState<boolean>(false);

  useDebounce(async () => {
    if (!operationForm.slug) {
      return;
    }
    try {
      const response = await checkIfSlugExist(operationForm.slug);
      setSlugExist(response);
    } catch (e: any) {
      toast.error(`Une erreur est survenue`, TOAST_ERROR_OPTIONS);
    }
  }, [operationForm.slug]);

  return (
    <SectionFormTemplate title={"Informations"}>
      <div className={styles["information-grid-input"]}>
        <Input
          placeholder="Nom"
          name="name"
          type="text"
          value={operationForm.name}
          onChange={handleOnChange}
        />
        <Select
          placeholder="Groupe d'article"
          name="productGroup"
          optionsList={productGroups!}
          value={operationForm.productGroup}
          setValue={(value: string) => onChange("productGroup", value)}
        />
        <Select
          placeholder="Groupe d'opération"
          name="operationGroup"
          optionsList={operationGroups!}
          value={operationForm.operationGroup}
          setValue={(value: string) => onChange("operationGroup", value)}
        />
        <Select
          placeholder="Type d'opération"
          name="operationType"
          optionsList={operationTypes!}
          value={operationForm.operationType}
          setValue={(value: string) => onChange("operationType", value)}
        />
        <div>
          <Input
            className={styles["price-input"]}
            name="slug"
            placeholder="slug"
            type="text"
            value={operationForm.slug}
            disabled={!!operationForm.id}
            onChange={({ target }) => onChange("slug", target.value)}
          />
          {!!slugExist && !!!operationForm.id && (
            <div className={styles["error-bubble-container"]}>
              <div className={styles["error-bubble"]}>
                <InfoOutlined className={styles["icon"]} />
                {
                  "Ce slug existe déjà. Veuillez en choisir un autre afin qu'il soit unique."
                }
              </div>
            </div>
          )}
        </div>
        <Input
          name="check"
          type="checkbox"
          className={styles["checkbox"]}
          label="Affichage publique"
          checked={operationForm.publicDisplay ?? false}
          onChange={() =>
            onChange("publicDisplay", !operationForm.publicDisplay)
          }
        />
      </div>
    </SectionFormTemplate>
  );

  function handleOnChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    onChange(target.name, target.value);
  }
}
