import { Note } from "../../types/tracker.types";
import styles from "./timeline.module.scss";
import TimelineItem from "./TimelineItem/TimelineItem";

interface TimelineProps {
  notes: Note[];
  onRemove: (index: number) => void;
  showAll?: boolean;
}

export default function Timeline({
  notes,
  onRemove,
  showAll = false,
}: TimelineProps) {
  return (
    <ul className={styles["timeline"]}>
      {notes
        .sort(sortByDate)
        .filter((_, i) => (showAll ? _ : i < 2))
        .map((note, index) => {
          return (
            <TimelineItem
              note={note}
              key={index}
              rank={rank(index)}
              onRemove={() => onRemove(index)}
              canBeDeleted={showAll}
            />
          );
        })}
    </ul>
  );

  function rank(index: number) {
    switch (index) {
      case notes.length - 1:
        return "first";
      case 0:
        return "last";
      default:
        return "middle";
    }
  }

  function sortByDate(a: Note, b: Note) {
    return new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime();
  }
}
