import styles from "./modalContainer.module.scss";
import { Close } from "@mui/icons-material";
import ModalPortal from "./ModalPortal";
import RemoveBodyScroll from "./RemoveBodyScroll";
import BackButtonInterceptor from "./BackButtonInterceptor";

interface ModalContainerProps {
  children: React.ReactNode;
  onCancel?: () => void;
  isOpen: boolean;
}

export function ModalContainer({
  children,
  onCancel,
  isOpen,
}: ModalContainerProps) {
  return (
    <ModalPortal isOpen={isOpen}>
      <RemoveBodyScroll>
        <div className={styles["modal-container"]}>
          <div className={styles["close-button-container"]}>
            <div className={styles["close-button"]} onClick={onCancel}>
              <Close />
            </div>
          </div>
          <div className={styles["modal-container-children"]}>{children}</div>
          <div className={styles["background"]} onClick={onCancel}></div>
        </div>
      </RemoveBodyScroll>
    </ModalPortal>
  );
}
