import { IShippingPoint } from "../../../../../../../types/shipping.types";
import styles from "./address.module.scss";

interface AddressProps {
  shippingPoint?: IShippingPoint;
}

export default function Address({ shippingPoint }: AddressProps) {
  if (!shippingPoint) {
    return <>unexpected error shipping address is missing</>;
  }

  return (
    <p className={styles["address"]}>
      {shippingPoint.address1 +
        ", " +
        shippingPoint.zipCode +
        " " +
        shippingPoint.city}
    </p>
  );
}
