import Widget from "../Widget";
import style from "./incomingShippingWidget.module.scss";
import { BoxSeam } from "react-bootstrap-icons";

export default function IncomingShippingWidget() {
  return (
    <Widget to={"incoming-shipping"}>
      <div className={style["icon-container"]}>
        <BoxSeam className={style["icon"]} />
      </div>
      <div>Réceptionner colis</div>
    </Widget>
  );
}
