import clsx from "clsx";
import styles from "./unfinishedList.module.scss";
import UnfinishedListItem from "../UnfinishedListItem/UnfinishedListItem";
import ListHeader from "./ListHeader/ListHeader";

interface UnfinishedListProps {
  list: any;
  // filters: { internal: boolean; chronopost: boolean; counter: boolean };
}

export default function UnfinishedList({ list }: UnfinishedListProps) {
  return (
    <div className={styles["list-container"]}>
      <div className={clsx(styles["list"])}>
        <table className={styles["table"]}>
          <ListHeader />
          <tbody>
            {list.map((value: any, index: number) => {
              return <UnfinishedListItem tracker={value} key={index} />;
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
