import clsx from "clsx";
import OperationGroupLogo from "../../../../../../components/OperationGroupLogo/OperationGroupLogo";
import { IProduct } from "../../../../../../types/logistic.types";
import QuoteOperation from "../QuoteOperation/QuoteOperation";
import styles from "./quoteProduct.module.scss";

interface QuoteProductProps {
  product: IProduct;
}

export default function QuoteProduct({ product }: QuoteProductProps) {
  const isCanceled = product.operationsList.every((op) => op.canceled);

  return (
    <li>
      <div className={styles["product-description"]}>
        <OperationGroupLogo
          className={styles["operation-logo"]}
          operationGroup={product.operationGroup}
        />
        <p className={clsx(styles["brand"], isCanceled && styles["canceled"])}>
          {product.brand}
        </p>
      </div>
      <ul className={styles["operation-list"]}>
        {product.operationsList.map((op, index) => (
          <QuoteOperation operation={op} key={index} />
        ))}
      </ul>
    </li>
  );
}
