import style from "./readModal.module.scss";
import { ICrossSell } from "../../../types/accounting.types";
import ColorComponent from "../../../components/colorComponent/colorComponent";
import translate from "../../../utils/translation";
import { handleImageError } from "../../../utils/utils";
import { Add, Remove } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { changeCrossSellQuantity } from "../../../requests/crossSelling";
import { TOAST_OPTIONS } from "../../../utils/toast.options";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import Button from "../../../components/Button/Button";
import Price from "../../../components/Price/Price";

interface ReadModalProp {
  data: ICrossSell;
  handleCloseModal: () => void;
}

export default function ReadModal({ data, handleCloseModal }: ReadModalProp) {
  const [quantity, setQuantity] = useState<number | undefined>(0);
  const [inputEdit, setInputEdit] = useState<boolean>(false);

  return (
    <div className={style["read-modal-container"]}>
      <div className={style["read-modal-body"]}>
        <div className={style["read-modal-left"]}>
          <div className={style["title-container"]}>
            <span className={style["title"]}>{data.description}</span>
            {data.color && <ColorComponent color={data.color} />}
          </div>
          <div className={style["product-group-list"]}>
            {data.productGroup &&
              data.productGroup.map((p) => translate(p)).join(" - ")}
          </div>
          <div className={style["product-info-line"]}>
            <span>Catégorie</span>
            {translate(data.category ?? "-")}
          </div>
          <div className={style["product-info-line"]}>
            <span>Prix (TTC)</span>
            <Price price={data.price} />
          </div>
          <div className={style["product-info-line"]}>
            <span>Prix (HT)</span>
            <Price price={data.priceNoTax} />
          </div>
          <div className={style["product-info-line"]}>
            <span>Slug</span>
            <div className={style["slug"]}>{data.slug}</div>
          </div>
          <div className={style["quantity-container"]}>
            Quantité en stock
            <div className={style["quantity-change-container"]}>
              <div
                className={style["quantity-button"]}
                onClick={removeQuantity}
              >
                <Remove className={style["icon"]} />
              </div>
              <span
                className={style["quantity-value"]}
                onDoubleClick={changeToInputEdit}
              >
                {inputEdit ? (
                  <>
                    {data.quantity && (
                      <input
                        className={style["quantity-input"]}
                        type="number"
                        value={quantity ? data.quantity + quantity : undefined}
                        onChange={handleQuantityChange}
                      />
                    )}
                  </>
                ) : (
                  <span>
                    {data.quantity && data.quantity + (quantity ?? 0)}
                  </span>
                )}
              </span>
              <div className={style["quantity-button"]} onClick={addQuantity}>
                <Add className={style["icon"]} />
              </div>
            </div>
          </div>
        </div>
        <div className={style["read-modal-right"]}>
          <img
            className={style["cross-sell-image"]}
            src={`${process.env.REACT_APP_API_URL}/file/${data.image}`}
            alt={data.slug}
            onError={handleImageError}
          />
        </div>
      </div>
      <div className={style["button-container"]}>
        {quantity !== 0 && (
          <>
            <Button
              className={style["save-changes-button"]}
              onClick={handleCancelChanges}
            >
              Annuler
            </Button>
            <Button onClick={handleSaveChanges}>Enregistrer</Button>
          </>
        )}
      </div>
    </div>
  );

  function handleQuantityChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    if (!data.quantity) {
      return;
    }
    if (isNaN(parseInt(target.value)) || parseInt(target.value) < 0) {
      if (isNaN(parseInt(target.value))) {
        setQuantity(-data.quantity);
      }
      return;
    }
    const newQuantity = parseInt(target.value) - data.quantity;
    setQuantity(newQuantity);
  }

  function changeToInputEdit() {
    setInputEdit(true);
  }

  function handleCancelChanges() {
    setQuantity(0);
    setInputEdit(false);
  }

  async function handleSaveChanges() {
    if (!data.slug) {
      return;
    }
    if (!quantity) {
      return;
    }
    try {
      const response = await changeCrossSellQuantity(data.slug, quantity);
      if (response) {
        toast.success("La quantité en stock a été modifiée.", TOAST_OPTIONS);
      }
    } catch (error: any) {
      toast.error(
        "La modification de la quantité n'est pas aboutie.",
        TOAST_OPTIONS
      );
    } finally {
      handleCloseModal();
    }
  }

  function addQuantity() {
    if (quantity !== undefined) {
      setQuantity(quantity + 1);
    }
  }

  function removeQuantity() {
    if (data.quantity !== undefined && quantity !== undefined) {
      if (data.quantity + quantity - 1 >= 0) {
        setQuantity(quantity - 1);
      }
    }
  }
}
