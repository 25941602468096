import { Link } from "react-router-dom";
import styles from "./index.module.scss";
import SearchByKeyComponent from "../../components/searchByKeyComponent/searchByKeyComponent";
import { useState } from "react";
import { queryObject } from "../../types/query.types";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import { useQuery } from "react-query";
import { deleteLicense, getLicensesList } from "../../requests/licenses";
import { ILicense } from "../../types/license.types";
import Item from "./listItem/item";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import useSaveQueryStorage, {
  loadQueryStorage,
} from "../../hooks/useSaveQueryStorage";
import Button from "../../components/Button/Button";
import ListPageHeader from "../../components/ListPageHeader/ListPageHeader";
import ListHeader from "./ListHeader/ListHeader";

export const INITIAL_QUERY: queryObject = {
  limit: 10,
  offset: 0,
  sort: "",
};
const LICENSE_SEARCH_OPTIONS = ["name"];

export default function Licenses() {
  const [query, setQuery] = useSaveQueryStorage(INITIAL_QUERY, "licenses");
  const { refetch, data, isLoading }: any = useQuery(
    ["licensesList", query],
    () => getLicensesList(formatedQuery()),
    { keepPreviousData: true }
  );

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  if (!data || !data.licensesList) {
    return <>Error data is missing !</>;
  }

  return (
    <div className={styles["list-page"]}>
      <ListPageHeader
        title={"Licences"}
        actionSection={
          <Link onClick={() => window.scrollTo(0, 0)} to={"./create"}>
            <Button>+ Ajouter nouveau</Button>
          </Link>
        }
      />
      <div className={styles["list-container"]}>
        <div className={styles["paging-component-top"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
        <div className={styles["list"]}>
          <table className={styles["table"]}>
            <ListHeader />
            <tbody>
              {data &&
                data.licensesList &&
                data.licensesList.map((value: ILicense, key: number) => {
                  return (
                    <Item key={key} data={value} onDelete={handleDelete} />
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className={styles["paging-component"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
      </div>
    </div>
  );

  async function handleDelete(id: string) {
    await deleteLicense({ id });
    refetch();
  }

  function formatedQuery() {
    let formatedQuery = `?limit=${query.limit}&offset=${
      query.limit * query.offset
    }`;

    return formatedQuery;
  }
}
