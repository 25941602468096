import { FormEvent, useState } from "react";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { addUser, updateUser } from "../../../requests/user";
import translate from "../../../utils/translation";
import { IUserData } from "../../../types/user.types";
import style from "./create.module.scss";

const ROLES: string[] = [
  "ROLE_ADMIN",
  "ROLE_LOGISTICS",
  "ROLE_ACCOUNTING",
  "ROLE_DEVELOPER",
  "ROLE_WORKSHOP",
  "ROLE_SHIPPING",
  "ROLE_GUEST",
  "ROLE_ESTIMATE",
];

const INITALE_STATE: IUserData = {
  _id: "",
  username: "",
  password: "",
  roles: [],
};

export default function Create({ data }: { data?: IUserData }) {
  const [newUser, setNewUser] = useState<IUserData>(data || INITALE_STATE);
  const [visible, setVisible] = useState<Boolean>(false);
  const [IErrorMessage, setIErrorMessage] = useState<string>("");
  const navigate = useNavigate();

  function handleOnChange(
    target: (EventTarget & HTMLInputElement) | (EventTarget & HTMLSelectElement)
  ) {
    if (
      target.name === "roles" &&
      target.value !== "---choisissez un rôle---"
    ) {
      if (!newUser.roles.includes(target.value)) {
        newUser.roles.push(target.value);
        setNewUser({ ...newUser });
      }
      return;
    }
    setNewUser({ ...newUser, [target.name]: target.value });
  }

  function removeRole(role: string) {
    newUser.roles = newUser?.roles?.filter((value: string) => value !== role);
    setNewUser({ ...newUser });
  }

  async function handleUpdateUser(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const response = await updateUser(newUser, newUser._id);
    setIErrorMessage(response || "");
    if (response === undefined) {
      navigate("../");
    }
  }

  async function handleAddUser(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const response = await addUser(newUser as IUserData);
    setIErrorMessage(response || "");
    if (response === undefined) {
      navigate("../");
    }
  }

  return (
    <div className={style.create}>
      <div className={style.title}>
        {data
          ? `Modifier (utilisateur: ${data.username})`
          : "Ajouter un nouvel utilisateur"}
      </div>
      <form
        className={style.form}
        onSubmit={(event) =>
          data ? handleUpdateUser(event) : handleAddUser(event)
        }
      >
        <input
          type="text"
          name={"username"}
          value={newUser.username}
          placeholder={"nom d'utilisateur"}
          onChange={({ target }) => handleOnChange(target)}
        />
        <div className={style.password}>
          <input
            type={visible ? "text" : "password"}
            name={"password"}
            placeholder={"mot de passe"}
            value={newUser.password}
            onChange={({ target }) => handleOnChange(target)}
          />
          <div className={style.eyeIcon} onClick={() => setVisible(!visible)}>
            {visible ? <EyeSlash /> : <Eye />}
          </div>
        </div>
        <div className={style.roleInput}>
          <select
            name={"roles"}
            value={"--- choisir un rôle ---"}
            onChange={({ target }) => handleOnChange(target)}
          >
            {["--- choisir un rôle ---"].concat(ROLES).map((value) => (
              <option key={value} value={value}>
                {translate(value)}
              </option>
            ))}
          </select>
          <div className={style.roleList}>
            {newUser.roles?.map((value: string, key: number) => {
              return (
                <div
                  key={key}
                  className={style.roleItem}
                  onClick={() => removeRole(value)}
                >
                  {translate(value)}
                  <span>x</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className={style.addUser}>
          {data ? (
            <button className={style.button} type={"submit"}>
              Mettre à jour
            </button>
          ) : (
            <button className={style.button} type={"submit"}>
              Enregistrer
            </button>
          )}
        </div>
      </form>
      <div className={style.error}>
        {IErrorMessage ? `*${IErrorMessage}` : ""}
      </div>
    </div>
  );
}
