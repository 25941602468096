import clsx from "clsx";
import styles from "./requiredAction.module.scss";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface RequiredActionProps {
  className?: string;
  name?: string;
  isBouncing?: boolean;
}
export default function RequiredAction({
  className,
  isBouncing = true,
  name,
}: RequiredActionProps) {
  return (
    <div
      className={clsx(
        styles["required-action"],
        isBouncing && styles["bounce"],
        className
      )}
    >
      <ErrorOutlineIcon className={styles["icon"]} />
      {name}
    </div>
  );
}
