import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { fetchPayment, fetchPaymentByTrackId } from "../../../requests/payment";
import { finalizeInvoice, generateInvoice } from "../../../requests/pennylane";
import { fetchTracker } from "../../../requests/tracker";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import LoadingSpinner from "../../loadingSpinner/loadingSpinner";
import style from "./quoteValidationInvoice.module.scss";
import Price from "../../Price/Price";

interface QuoteValidateInvoiceProps {
  trackId: string;
}

export default function QuoteValidateInvoice({
  trackId,
}: QuoteValidateInvoiceProps) {
  const trackerQuery = useQuery("trackerQuery", () =>
    fetchTracker({ params: { id: trackId } })
  );

  const generateInvoiceMutation = useMutation(generateInvoice, {
    onError: () => {
      toast.error("Une erreur est survenue.", TOAST_ERROR_OPTIONS);
    },
    onSuccess: () => {
      toast.success("Brouillon générée !", TOAST_SUCCESS_OPTIONS);
      setTimeout(() => trackerQuery.refetch(), 500);
    },
  });

  const finalizeInvoiceMutation = useMutation(finalizeInvoice, {
    onError: () => {
      toast.error("Une erreur est survenue.", TOAST_ERROR_OPTIONS);
    },
    onSuccess: () => {
      toast.success("Facture finalisée !", TOAST_SUCCESS_OPTIONS);
      setTimeout(() => trackerQuery.refetch(), 500);
    },
  });

  return (
    <>
      <div className={style["container"]}>
        <div className={style["quote-container"]}>
          {trackerQuery.isLoading || generateInvoiceMutation.isLoading ? (
            <LoadingSpinner color="gold" />
          ) : trackerQuery?.data?.tracker?.invoice?.fileUrl ? (
            <>
              <iframe
                className={style["invoice-frame"]}
                src={trackerQuery.data.tracker.invoice.fileUrl}
              >
                {trackerQuery.data.tracker.invoice.fileUrl}
              </iframe>
              {trackerQuery?.data?.tracker?.invoice?.state === "DRAFT" && (
                <div>
                  <button
                    onClick={() =>
                      finalizeInvoiceMutation.mutate(
                        trackerQuery?.data?.tracker?.invoice?.invoiceId
                      )
                    }
                  >
                    Valider facture
                  </button>
                </div>
              )}
            </>
          ) : (
            <div>
              <div>Aucune facture disponible</div>
              <button onClick={() => generateInvoiceMutation.mutate(trackId)}>
                Nouvelle facture
              </button>
            </div>
          )}
        </div>

        <div className={style["payment-container"]}>
          <div className={style["subtitle"]}>Paiement</div>
          {trackerQuery.isLoading ? (
            <LoadingSpinner color="gold" />
          ) : (
            <>
              <div className={style["line"]}>
                <div className={style["line-name"]}>Etat</div>
                <div className={style["line-value"]}>
                  {trackerQuery.data?.tracker?.payment?.transactionStatus}
                </div>
              </div>
              <div className={style["line"]}>
                <div className={style["line-name"]}>Date</div>
                <div className={style["line-value"]}>
                  {trackerQuery.data?.tracker?.payment?.transactionDate
                    ? new Date(
                        trackerQuery.data?.tracker?.payment?.transactionDate
                      ).toLocaleDateString("FR")
                    : "Date inconnue"}
                </div>
              </div>
              <div className={style["line"]}>
                <div className={`${style["line-name"]} bold`}>Montant</div>
                <Price
                  className={`${style["line-value"]} bold`}
                  price={trackerQuery.data?.tracker?.payment?.amount}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
