interface CenterCellProps {
  children: React.ReactNode;
  className?: string;
}

export default function CenterCell({ children, className }: CenterCellProps) {
  return (
    <div
      style={{ display: "flex", justifyContent: "center" }}
      className={className}
    >
      {children}
    </div>
  );
}
