import styles from "./index.module.scss";
import { useQuery } from "react-query";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import Item from "./item/item";
import SearchByKeyComponent from "../../components/searchByKeyComponent/searchByKeyComponent";
import FilterByStatus from "../../components/filterByStatus/filterByStatus";
import { queryObject } from "../../types/query.types";
import { fetchPaymentsList } from "../../requests/payment";
import { IPayment } from "../../types/payment.type";
import { useAuth } from "../../context/auth.context";
import useSaveQueryStorage from "../../hooks/useSaveQueryStorage";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import ListPageHeader from "../../components/ListPageHeader/ListPageHeader";
import FiltersContainer from "../../components/Filters/FiltersContainer";
import ListHeader from "./ListHeader/ListHeader";

export const INITIAL_QUERY: queryObject = {
  field: "contact.displayname",
  value: "",
  service: "",
  direction: "",
  status: "",
  limit: 10,
  offset: 0,
  sort: "",
  dateFrom: "",
  dateTo: "",
  state: "",
};

const PAYMENT_SEARCH_OPTIONS = [
  "contact.displayname",
  "contact.phone",
  "contact.organization",
  "contact.email",
];

export const PAYMENT_STATE = ["AUTHORISED", "REFUSED"];

export default function Carts() {
  const [query, setQuery] = useSaveQueryStorage(INITIAL_QUERY, "payment");
  const { data, isLoading } = useQuery(
    ["payments", query],
    () => fetchPaymentsList(formatedQuery()),
    { keepPreviousData: true }
  );
  const { roles } = useAuth();

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  if (!data || !data.payments) {
    return <>Error data is missing !</>;
  }

  return (
    <div className={styles["list-page"]}>
      <ListPageHeader title={"Paiements"} />
      <FiltersContainer>
        <SearchByKeyComponent
          query={query}
          setQuery={setQuery}
          optionsList={PAYMENT_SEARCH_OPTIONS}
        />
        <FilterByStatus
          query={query}
          setQuery={setQuery}
          optionsList={PAYMENT_STATE}
          field={"transactionStatus"}
        />
      </FiltersContainer>
      <div className={styles["list-container"]}>
        <div className={styles["paging-component-top"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
        <div className={styles["list"]}>
          <table className={styles["table"]}>
            <ListHeader />
            <tbody>
              {data.payments.map((payment: IPayment, key: number) => {
                if (roles && roles.includes("ROLE_ADMIN")) {
                  return <Item key={key} data={payment} />;
                }
              })}
            </tbody>
          </table>
        </div>
        <div className={styles["paging-component"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
      </div>
    </div>
  );

  function formatedQuery() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery = formatedQuery + `?limit=${query.limit}`;
    }

    formatedQuery = formatedQuery + `&offset=${query.offset * query.limit}`;

    if (query.field && query.value) {
      formatedQuery = formatedQuery + `&${query.field}=${query.value}`;
    }
    if (query.sort) {
      formatedQuery = formatedQuery + `&sort=${query.sort}`;
    } else {
      formatedQuery += `&sort_field=transactionDate&sort_direction=desc`;
    }

    if (query.state && query.state !== "Tous") {
      formatedQuery = formatedQuery + `&transactionStatus=${query.state}`;
    }

    if (!(query.dateFrom === "" && query.dateTo === "")) {
      formatedQuery =
        formatedQuery +
        `&transactionDate=` +
        query.dateFrom +
        `&transactionDate=` +
        query.dateTo;
    }
    return formatedQuery;
  }
}
