import { ReactNode, useState } from "react";
import styles from "./filtersContainer.module.scss";
import { TuneOutlined } from "@mui/icons-material";
import clsx from "clsx";
import { isDesktop } from "react-device-detect";

interface FiltersContainerProps {
  children: ReactNode;
}

export default function FiltersContainer({ children }: FiltersContainerProps) {
  const [expand, setExpand] = useState<boolean>(true);

  return (
    <div className={styles["filters-container"]}>
      <div className={styles["filters-wrapper"]}>
        <p className={styles["filters-title"]} onClick={handleToogleFilters}>
          <TuneOutlined
            className={clsx(styles["icon"], expand && styles["visible"])}
          />{" "}
          {"Filtres"}
        </p>
        {expand && <div className={styles["filters"]}>{children}</div>}
      </div>
    </div>
  );

  function handleToogleFilters() {
    setExpand(!expand);
  }
}
