import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../errorPage/error-page";
import Quotes from ".";
import Read, { loader as readLoader } from "./read/read";
import Generate from "./generate/generate";
import { loader as createLoader } from "./create/create";

import { authloader } from "../../routes";
import Create from "./create/create";
import Edit from "./edit/edit";
// import { action as destroyAction } from "./destroy/destroy";

export const route: RouteObject = {
  path: "/quotes",
  element: <Outlet />,
  loader: authloader,
  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <Quotes />,
      loader: authloader,
    },
    {
      path: "create",
      element: <Create />,
      loader: createLoader,
    },
    {
      path: "edit/:id",
      element: <Edit />,
      loader: createLoader,
    },
    {
      path: "generate/:trackerId",
      element: <Generate />,
      loader: createLoader,
    },
    {
      path: "read/:id",
      element: <Read />,
      loader: readLoader,
    },
  ],
};
