import { IProduct } from "../../../../types/logistic.types";
import style from "./commentForm.module.scss";

interface CommentFormProps {
  productForm: IProduct;
  setProductForm: (product: IProduct) => void;
}

export default function CommentForm({
  productForm,
  setProductForm,
}: CommentFormProps) {
  return (
    <div className={style["comment-form"]}>
      <div className={style["left"]}>Commentaire</div>
      <div className={style["right"]}>
        <textarea
          className={style["comment"]}
          value={productForm.comments}
          onChange={({ target }) =>
            setProductForm({ ...productForm, comments: target.value })
          }
        />
      </div>
    </div>
  );
}
