import moment from "moment";
import { useState } from "react";
import style from "./index.module.scss";
import { useQuery } from "react-query";
import clsx from "clsx";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import {
  findPendingDeliveries,
  searchChronotrace,
} from "../../requests/chronotrace";
import { getProductsReport } from "../../requests/reports";
import { Link } from "react-router-dom";
import translate from "../../utils/translation";
import InputContainer from "../../components/forms/inputs/inputContainer/inputContainer";

function getFullMonth(referenceDate: Date) {
  let d = moment(referenceDate);
  let period = {
    dateFrom: d.startOf("month").toDate(),
    dateTo: d.endOf("month").toDate(),
  };
  return period;
}

function formatDate(date: string | undefined) {
  return date !== ""
    ? date && new Date(date).toISOString().substring(0, 10)
    : "";
}

function DateRange({
  dateFrom,
  dateTo,
  onChange,
  title,
  disabled,
}: {
  dateFrom: Date;
  dateTo: Date;
  onChange: ({ dateFrom, dateTo }: { dateFrom: Date; dateTo: Date }) => void;
  title?: string;
  disabled?: boolean;
}) {
  return (
    <div className={style["date-range-picker-container"]}>
      <div className={style["date-range-picker-title"]}>{title || ""}</div>
      <div className={style["shipping-date"]}>
        <label>Du</label>
        <input
          disabled={disabled || false}
          name={"dateFrom"}
          type="date"
          lang="fr-FR"
          value={formatDate(dateFrom.toISOString())}
          onChange={handleChange}
          className={style["date-input"]}
        />
      </div>
      <div className={style["shipping-date"]}>
        <label>Au</label>
        <input
          disabled={true}
          name={"dateTo"}
          type="date"
          lang="fr-FR"
          value={formatDate(dateTo.toISOString())}
          onChange={handleChange}
          className={style["date-input"]}
        />
      </div>
    </div>
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    let dateRange = { dateFrom, dateTo };
    dateRange[event.target.name as keyof typeof dateRange] = new Date(
      event.target.value
    );
    onChange && onChange(dateRange);
  }
}

function PODTable({
  from,
  to,
  state = "NONDISTRIBUES",
  account = "CHRONOPOST_2_SHOP",
  direction = "ANY",
  filter2days = true,
}: {
  from: Date;
  to: Date;
  state: string;
  account: string;
  direction: string;
  filter2days: boolean;
}) {
  const { data, isLoading } = useQuery({
    queryKey: [
      "search-chronotrace",
      {
        from,
        to,
        state,
        account,
        direction,
        filter2days,
      },
    ],
    queryFn: searchChronotrace,
  });

  return (
    <div className={style["report-table"]}>
      <div
        className={clsx(style["product-table-title"])}
      >{`${account} - ${state}`}</div>
      <div
        className={clsx(style["product-table-grid"], {
          [style["loading"]]: isLoading,
        })}
      >
        <div
          className={clsx(
            style["report-table-item"],
            style["product-table-item-count"],
            style["report-table-header"]
          )}
        >
          Direction
        </div>
        <div
          className={clsx(
            style["report-table-item"],
            style["report-table-header"]
          )}
        >
          Suivi
        </div>
        <div
          className={clsx(
            style["report-table-item"],
            style["product-table-item-count"],
            style["report-table-header"]
          )}
        >
          Client
        </div>
        <div
          className={clsx(
            style["report-table-item"],
            style["report-table-header"]
          )}
        >
          Evenement
        </div>
        <div
          className={clsx(
            style["report-table-item"],
            style["report-table-header"]
          )}
        >
          Date
        </div>

        {data &&
          data.infosPODList &&
          data.infosPODList.map((p, index) => (
            <>
              {index > 0 && <hr className={style["product-table-separator"]} />}
              <div className={clsx(style["report-table-item"])}>
                {translate(p.direction)}
              </div>

              <div className={clsx(style["report-table-item"])}>
                {p.skybillNumber}
              </div>

              <div className={clsx(style["report-table-item"])}>
                <Link to={`/overview/${p.trackId}`}>
                  {p.contactName && p.contactName.trim() !== ""
                    ? p.contactName
                    : "Client non renseigné"}
                </Link>
              </div>

              <div className={clsx(style["report-table-item"])}>
                {p.significantEvent.eventLabel}
              </div>
              <div className={clsx(style["report-table-item"])}>
                {moment(p.significantEvent.eventDate).format("DD/MM/YYYY")}
              </div>
            </>
          ))}
        <div
          className={clsx(
            style["report-table-item"],
            style["report-table-footer"]
          )}
        ></div>
        <div
          className={clsx(
            style["report-table-item"],
            style["product-table-item-count"],
            style["report-table-footer"]
          )}
        >
          {/* <ComparisonIcon
              className={style["comparison-icon"]}
              primaryNumber={primaryData.reduce(
                (acc, curr) => acc + curr.itemCount,
                0
              ) || 0}
              secondaryNumber={secondaryData?.reduce(
                (acc, curr) => acc + curr.itemCount,
                0
              ) || 0}
            />
            {primaryData?.reduce((acc, curr) => acc + curr.itemCount, 0)} */}
        </div>
        <div
          className={clsx(
            style["report-table-item"],
            style["secondary-report-table-item"],

            style["report-table-footer"]
          )}
        ></div>
      </div>
      <div
        className={clsx(style["loading-overlay"], {
          [style["loading"]]: isLoading,
        })}
      >
        <LoadingSpinner color="gold" />
      </div>
    </div>
  );
}

export default function Chronotrace() {
  const [dateRange, setDateRange] = useState(getFullMonth(new Date()));
  const [parcelState, setParcelState] = useState("NONDISTRIBUES");
  const [account, setAccount] = useState("CHRONOPOST_2_SHOP");
  const [direction, setDirection] = useState("ANY");
  const [filter2days, setFilter2days] = useState(true);
  function handleDateChange(dateRange: { dateFrom: Date; dateTo: Date }): void {
    setDateRange(dateRange);
  }

  const { data, isLoading } = useQuery({
    queryKey: ["find-pending-deliveries"],
    queryFn: findPendingDeliveries,
  });

  return (
    <div className={style["container"]}>
      <div className={style["date-range-container"]}>
        <DateRange
          dateFrom={dateRange.dateFrom}
          dateTo={dateRange.dateTo}
          onChange={handleDateChange}
          title="Période"
        />
      </div>
      <div className={style["date-range-container"]}>
        <InputContainer>
          <label>Etat</label>
          <select
            value={parcelState}
            onChange={(v) => setParcelState(v.target.value)}
          >
            <option>NONDISTRIBUES</option>
            <option>ANY</option>
            <option>DISTRIBUES</option>
            <option>INTERNATIONAL</option>
            <option>INSTANCE</option>
            <option>INCIDENT</option>
          </select>
        </InputContainer>
      </div>
      <div className={style["date-range-container"]}>
        <InputContainer>
          <label>Compte</label>
          <select value={account} onChange={(v) => setAccount(v.target.value)}>
            <option>CHRONOPOST_2_SHOP</option>
            <option>CHRONOPOST_DIRECT</option>
            <option>CHRONOPOST_EUR</option>
          </select>
        </InputContainer>
      </div>
      <div className={style["date-range-container"]}>
        <InputContainer>
          <label>Direction</label>
          <select
            value={direction}
            onChange={(v) => setDirection(v.target.value)}
          >
            <option>ANY</option>
            <option>INCOMING</option>
            <option>OUTGIONG</option>
          </select>
        </InputContainer>
      </div>
      <div className={style["date-range-container"]}>
        <InputContainer>
          <label>Filtre 2 jours</label>
          <input
            type="checkbox"
            checked={filter2days}
            onChange={(v) => setFilter2days(v.target.checked)}
          />
        </InputContainer>
      </div>
      <div className={style["tables-container"]}>
        <PODTable
          from={dateRange.dateFrom}
          to={dateRange.dateTo}
          state={parcelState}
          account={account}
          direction={direction}
          filter2days={filter2days}
        />
      </div>
    </div>
  );
}
