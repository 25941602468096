import { isDesktop } from "react-device-detect";
import { IAddress } from "../../../../types/customer.type";
import styles from "./address.module.scss";
import clsx from "clsx";

interface AddressProps {
  address: IAddress;
  onDelete: () => void;
  onSelect: () => void;
}

export default function Address({ address, onDelete, onSelect }: AddressProps) {
  return (
    <div className={styles["address-container"]}>
      <div className={styles["left"]}>
        <p className={styles["address"]}>
          {address.address1} {address.address2}, {address.zipCode}{" "}
          {address.city} {address.country}
        </p>
      </div>
      <div className={styles["right"]}>
        <div className={styles["edit-button"]} onClick={onSelect}>
          modifier
        </div>
        <div
          className={clsx(
            styles["delete-button"],
            isDesktop ? styles["hover"] : styles["active"]
          )}
          onClick={onDelete}
        >
          supprimer
        </div>
      </div>
    </div>
  );
}
