import { Close, ExpandMore } from "@mui/icons-material";
import style from "./searchByKeyComponent.module.scss";
import translate from "../../utils/translation";
import { useEffect, useRef, useState } from "react";
import { queryObject } from "../../types/query.types";
import { isDesktop } from "react-device-detect";
import useClickOutside from "../../hooks/useClickOutSide";
import clsx from "clsx";

interface SearchByKeyComponentProps {
  query: queryObject;
  optionsList: string[];
  setQuery: (query: queryObject) => void;
  className?: string;
}

export default function SearchByKeyComponent({
  optionsList,
  query,
  setQuery,
  className,
}: SearchByKeyComponentProps) {
  const [expand, setExpand] = useState<boolean>(false);
  const ref = useClickOutside<HTMLDivElement>(() => setExpand(false));

  return (
    <div className={clsx(style["search-by-key-component"], className)}>
      <div className={style["select-key-container"]} ref={ref}>
        <div className={style["select-key"]} onClick={handleExpand}>
          <span className={style["placeholder"]}>
            {translate(query.field || "rechercher par...")}
          </span>
          <div className={style["expand-icon-container"]}>
            <ExpandMore />
          </div>
        </div>
        {expand && (
          <div className={style["options-list-container"]}>
            <div className={style["options-list"]}>
              {optionsList.map((option: string, index: number) => {
                return (
                  <div
                    className={`${style["option-item"]} ${
                      !isDesktop && style["large-item"]
                    }`}
                    key={index}
                    onClick={() => handleChangeKey(option)}
                  >
                    {translate(option)}
                  </div>
                );
              })}
            </div>
            <div className={style["beautiful-div"]}></div>
          </div>
        )}
      </div>
      <div className={style["input-value-container"]}>
        <input
          type="text"
          inputMode={setInputMode()}
          value={query.value}
          placeholder={
            query.field && `rechercher par ${translate(query.field)}`
          }
          onChange={handleChangeValue}
          className={style["input-value"]}
        />
        {query.value && (
          <div
            className={style["close-icon-container"]}
            onClick={resetInputValue}
          >
            <Close />
          </div>
        )}
      </div>
    </div>
  );

  function resetInputValue() {
    const newQuery = { ...query };
    newQuery.value = "";
    setQuery(newQuery);
  }

  function handleExpand() {
    setExpand(!expand);
  }

  function setInputMode() {
    return query.field?.includes("workshopIds") ||
      query.field?.includes("phone") ||
      query.field?.includes("workshopId")
      ? "numeric"
      : "text";
  }

  function handleChangeKey(key: string) {
    const newQuery = { ...query };
    if (key !== newQuery.field) {
      newQuery.value = "";
    }
    newQuery.field = key;
    setQuery(newQuery);
    setExpand(!expand);
  }

  function handleChangeValue({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    setQuery({ ...query, value: target.value });
  }
}
