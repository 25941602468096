import { ChangeEvent, useEffect, useRef, useState } from "react";
import style from "./selectStatusComponent.module.scss";
import translate from "../../utils/translation";
import getStatusColor from "../../utils/translation/statusColor";
import { ExpandMore } from "@mui/icons-material";

interface SelectStatusProps {
  state: string;
  options: string[];
  onChange: (state: ChangeEvent<HTMLSelectElement>) => void;
}

export default function SelectStatus({
  state,
  options,
  onChange,
}: SelectStatusProps) {
  return (
    <div className={style["status-select-container"]}>
      {<label>Status</label>}
      <select
        className={style["status-select"]}
        name={"status"}
        value={state}
        onChange={onChange}
        style={{ backgroundColor: `${getStatusColor(state)}` }}
      >
        {options &&
          options.map((value) => (
            <option key={value} value={value}>
              {translate(value)}
            </option>
          ))}
      </select>
    </div>
  );
}
