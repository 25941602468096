import { useLoaderData } from "react-router-dom";
import { getUser } from "../../../requests/user";
import { IUserData } from "../../../types/user.types";
import Create from "../create/create";
import { authloader } from "../../../routes";

export async function loader({ params }: { params: any }) {
  try {
    const user = await getUser({ params });
    authloader();
    return user;
  } catch (error: any) {
    throw error;
  }
}

export default function Edit() {
  const data = useLoaderData() as IUserData;

  return <Create data={data} />;
}
