import { useLoaderData } from "react-router-dom";
import {
  fetchOperation,
  fetchOperationGroups,
  fetchOperationTypes,
  fetchProductGroups,
} from "../../../requests/operation";
import { IOperation } from "../../../types/accounting.types";
import Create from "../create/create";
import { authloader } from "../../../routes";
import { getOperationPro } from "../../../requests/operationPro";

export async function loader({
  request,
  params,
}: {
  request: Request;
  params: any;
}) {
  try {
    const operation = await getOperationPro({ request, params });
    const productGroups = await fetchProductGroups({ request });
    const operationGroups = await fetchOperationGroups({ request });
    const operationTypes = await fetchOperationTypes({ request });
    authloader();
    return { operation, productGroups, operationGroups, operationTypes };
  } catch (error: any) {
    throw new Response(error);
  }
}

export default function Edit() {
  const { operation, productGroups, operationGroups, operationTypes } =
    useLoaderData() as {
      operation: IOperation;
      productGroups: string[];
      operationGroups: string[];
      operationTypes: string[];
    };

  return (
    <>
      {operation && (
        <Create
          operation={operation}
          editProductGroups={productGroups}
          editOperationGroups={operationGroups}
          editOperationTypes={operationTypes}
        />
      )}
    </>
  );
}
