import { ReactNode, useState } from "react";
import styles from "./responsiveMenuExpandableItem.module.scss";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import MenuItemContainer from "../../../layouts/NavigationBar/MenuItemContainer/MenuItemContainer";
import ResponsiveMenuItem from "../ResponsiveMenuItem/ResponsiveMenuItem";
import clsx from "clsx";
import { useGetPath } from "../../../utils/utils";
import { ChevronRight } from "@mui/icons-material";

interface ResponsiveMenuExpandableItemProps {
  children: ReactNode;
  title: string;
  Logo: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  SelectedLogo: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
}

export default function ResponsiveMenuExpandableItem({
  children,
  title,
  Logo,
  SelectedLogo,
}: ResponsiveMenuExpandableItemProps) {
  const isSelected = useGetPath(title);
  const [expand, setExpand] = useState<boolean>(false);

  return (
    <>
      <MenuItemContainer title={title}>
        <div className={styles["menu-item-link"]} onClick={handleExpandList}>
          <div className={styles["menu-item-icon-container"]}>
            <div className={styles["menu-item-icon-container-left"]}>
              {isSelected ? (
                <SelectedLogo className={styles["menu-item-icon"]} />
              ) : (
                <Logo className={styles["menu-item-icon"]} />
              )}
              <p className={clsx(styles["menu-item-title"])}>{title}</p>
            </div>
          </div>
          <ChevronRight
            className={clsx(styles["icon"], expand && styles["rotate"])}
          />
        </div>
      </MenuItemContainer>
      {expand && <div className={styles["expandable-list"]}>{children}</div>}
    </>
  );

  function handleExpandList() {
    setExpand(!expand);
  }
}
