import { useState } from "react";
import SelectState from "../../../pages/shipping/create/SelectState/SelectState";
import { Referral } from "../../../types/referral.types";
import styles from "./referralForm.module.scss";
import { REFERRAL_DEFAULT, REFERRAL_STATE } from "../../../utils/referral.init";
import Input from "../../inputs/Input/Input";
import CustomerLink from "../../CustomerLink/CustomerLink";
import Button from "../../Button/Button";
import SectionFormTemplate from "../sectionFormTemplate/SectionFormTemplate";
import { useMutation } from "react-query";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import { toast } from "react-toastify";
import { updateReferral } from "../../../requests/referrals";

interface ReferralFormProps {
  referral: Referral;
}

export default function ReferralForm({ referral }: ReferralFormProps) {
  const [referralForm, setReferralForm] = useState<Referral>(
    referral || REFERRAL_DEFAULT
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const mutation = useMutation(updateReferral, {
    onError: () => {
      toast.error("Une erreur est survenue.", TOAST_ERROR_OPTIONS);
    },
    onSuccess: () => {
      toast.success("Enregistré avec succès.", TOAST_SUCCESS_OPTIONS);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <SectionFormTemplate title="Parrainage">
        <div className={styles["referral-section"]}>
          <div className={styles["date"]}>
            <label>Date de création</label>
            <p>
              {referralForm.createdAt
                ? new Date(referralForm.createdAt).toLocaleDateString()
                : "indisponible"}
            </p>
          </div>
          <div className={styles["date"]}>
            <label>Date de dernière mise à jour</label>
            <p>
              {referralForm.updatedAt
                ? new Date(referralForm.updatedAt).toLocaleDateString()
                : "indisponible"}
            </p>
          </div>
          <SelectState
            label="Status du parrainage"
            state={referralForm.state}
            stateOption={REFERRAL_STATE}
            setState={(state) => {
              setReferralForm((prev) => ({ ...prev, state: state }));
            }}
          />
        </div>
      </SectionFormTemplate>
      <SectionFormTemplate title="Informations Parrain/Filleul">
        <div>
          <label>Parrain</label>
          <CustomerLink id={referralForm.referralSource} />
          {!!referralForm.referralTarget ? (
            <>
              <label>Filleul</label>
              <CustomerLink id={referralForm.referralTarget} />
            </>
          ) : (
            <>
              <label>Email filleul</label>
              <p>{referralForm.referredEmail}</p>
            </>
          )}
        </div>
      </SectionFormTemplate>
      <SectionFormTemplate title="Montant">
        <Input
          className={styles["price-input"]}
          name={"price"}
          onChange={handleOnChange}
          value={!referralForm.amount ? "" : referralForm.amount}
          type="number"
          inputMode="numeric"
        />
      </SectionFormTemplate>
      <div className={styles["button-container"]}>
        <Button isLoading={isLoading} type={"submit"}>
          Enregistrer
        </Button>
      </div>
    </form>
  );

  function handleOnChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    const regex = /^[0-9]*[.,]?[0-9]*$/;

    if (!regex.test(target.value.toString())) {
      return;
    }
    target.value.replace(",", ".");
    const inputValue =
      target.value === ""
        ? 0
        : Math.floor(parseFloat(target.value as string) * 100);
    setReferralForm((prev) => ({ ...prev, amount: inputValue }));
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setIsLoading(true);
    mutation.mutate(referralForm);
  }
}
