import { useLoaderData } from "react-router-dom";
import { fetchShippingMethod } from "../../../requests/shippingMethod";
import { IShippingMethod } from "../../../types/accounting.types";
import Create from "../create/create";
import { authloader } from "../../../routes";

export async function loader({
  request,
  params,
}: {
  request: Request;
  params: any;
}) {
  try {
    authloader();
    const shippingMethod = await fetchShippingMethod({ request, params });
    return { shippingMethod };
  } catch (error: any) {
    throw new Response(error);
  }
}

export default function Edit() {
  const { shippingMethod } = useLoaderData() as {
    shippingMethod: IShippingMethod;
  };

  return <>{shippingMethod && <Create shippingMethod={shippingMethod} />}</>;
}
