import Price from "../../../../components/Price/Price";
import { IProduct } from "../../../../types/logistic.types";
import styles from "./productInformations.module.scss";
import ProductQuoteSummary from "./ProductQuoteSummary/ProductQuoteSummary";

interface ProductInformationsProps {
  productsList: IProduct[] | undefined;
  operationsTotal?: number;
  onRefashionChange?: (productsList: IProduct[]) => void;
}

export default function ProductInformations({
  productsList,
  operationsTotal,
  onRefashionChange,
}: ProductInformationsProps) {
  return (
    <div className={styles["product-informations"]}>
      <div className={styles["product-informations-container"]}>
        {productsList &&
          productsList.map((product: IProduct, productIndex: number) => {
            return (
              <ProductQuoteSummary
                key={productIndex}
                product={product}
                productsList={productsList}
                productIndex={productIndex}
                onRefashionChange={onRefashionChange}
              />
            );
          })}
      </div>
      <div className={styles["product-informations-bottom"]}>
        <div className={styles["product-informations-bottom-top"]}>
          <div className={styles["product-informations-number"]}>
            Total refashion
          </div>
          <Price price={getTotalRefashion(productsList ?? [])} />
        </div>
        {operationsTotal && (
          <div className={styles["product-informations-total"]}>
            <span>Total opérations</span> <Price price={operationsTotal} />
          </div>
        )}
      </div>
    </div>
  );

  function getTotalRefashion(productsList: IProduct[]) {
    let total = productsList.reduce((accProd, curProd) => {
      return (
        accProd +
        curProd.operationsList.reduce((accOp, curOp) => {
          return accOp + (curOp.refashionDiscount || 0);
        }, 0)
      );
    }, 0);
    return total;
  }
}
