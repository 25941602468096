import { ExpandMore } from "@mui/icons-material";
import translate from "../../utils/translation";
import style from "./select.module.scss";
import { useState } from "react";
import useClickOutside from "../../hooks/useClickOutSide";
import { isDesktop } from "react-device-detect";

interface SelectProps {
  optionsList: string[];
  value: string;
  setValue: (value: string) => void;
  doTranslate?: boolean;
  placeholder?: string;
}

export default function Select({
  optionsList,
  value,
  setValue,
  doTranslate = true,
  placeholder,
  ...props
}: SelectProps & React.SelectHTMLAttributes<HTMLDivElement>) {
  const [expand, setExpand] = useState<boolean>(false);
  const ref = useClickOutside<HTMLDivElement>(() => setExpand(false));

  return (
    <div className={style["select-component"]} {...props}>
      <div className={style["select-container"]} ref={ref}>
        <div
          className={style["select-option"]}
          onClick={() => setExpand(!expand)}
          style={props.style}
        >
          <span className={style["placeholder"]}>
            {doTranslate
              ? translate(value || placeholder)
              : value || placeholder}
          </span>
          <div className={style["expand-icon-container"]}>
            <ExpandMore />
          </div>
        </div>
        {expand && (
          <div className={style["options-list-container"]} style={props.style}>
            <div className={style["options-list"]}>
              {optionsList.map((option: string, index: number) => {
                return (
                  <div
                    className={`${style["option-item"]} ${
                      !isDesktop && style["large-item"]
                    }`}
                    key={index}
                    onClick={() => handleSelectOption(option)}
                  >
                    {doTranslate ? translate(option) : option}
                  </div>
                );
              })}
            </div>
            <div className={style["beautiful-div"]}></div>
          </div>
        )}
      </div>
    </div>
  );

  function handleSelectOption(option: string) {
    setValue(option);
    setExpand(false);
  }
}
