import Widget from "../Widget";
import style from "./quoteWidget.module.scss";
import { EditNote } from "@mui/icons-material";

export default function QuoteWidget() {
  return (
    <Widget to={"quote-edit-search"}>
      <div className={style["icon-container"]}>
        <EditNote className={style["icon"]} />
      </div>
      <div>Rédiger devis</div>
    </Widget>
  );
}
