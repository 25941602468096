import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  deleteDiscount,
  fetchDiscountList,
  substractDiscountQuantity,
} from "../../requests/discount";
import { ProtectedComponent } from "../../components/protectedComponent/protectedComponent";
import { Link } from "react-router-dom";
import style from "./index.module.scss";
import Item from "./item/item";
import { IDiscount } from "../../types/accounting.types";

import PagingComponent from "../../components/pagingComponent/pagingComponent";
import ListHeaderComponent from "../../components/listHeaderComponent/listHeaderComponent";
import SearchByKeyComponent from "../../components/searchByKeyComponent/searchByKeyComponent";
import { queryObject } from "../../types/query.types";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import Button from "../../components/Button/Button";
let controller = new AbortController();

export const DISCOUNT_INITIAL_QUERY: queryObject = {
  field: "code",
  value: "",
  service: "",
  direction: "",
  status: "Tous",
  limit: 10,
  offset: 0,
  sort: "",
  dateFrom: "",
  dateTo: "",
};

export default function Discount() {
  const [query, setQuery] = useState<queryObject>(DISCOUNT_INITIAL_QUERY);
  const { data, isLoading, error, refetch } = useQuery(
    ["discount", query],
    () => fetchDiscountList(formatedQuery()),
    { keepPreviousData: true }
  );

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <div className={style["item-list"]}>
      <div className={style["top"]}>
        <span className={style["title"]}>Codes Promos</span>
        <ProtectedComponent roles={["ROLE_ADMIN"]}>
          <Link onClick={() => window.scrollTo(0, 0)} to={"./create"}>
            <Button>+ Ajouter nouveau</Button>
          </Link>
        </ProtectedComponent>
      </div>
      <div className={style["filters-container"]}>
        <div className={style["filters"]}>
          <SearchByKeyComponent
            query={query}
            setQuery={setQuery}
            optionsList={["code"]}
          />
        </div>
      </div>
      <div className={style["bottom"]}>
        <PagingComponent query={query} setQuery={setQuery} />
        <div className={style["item"]}>
          <ListHeaderComponent
            name="code"
            title="Code"
            query={query}
            setQuery={setQuery}
            headerClassName={style["item-element"]}
          />
          <div className={style["item-element"]}>Valeur</div>
          <ListHeaderComponent
            name="expireAt"
            title="Date d'expiration"
            query={query}
            setQuery={setQuery}
            headerClassName={style["item-element"]}
          />
          <div className={style["item-element"]}>Quantité de base</div>
          <div className={style["item-element"]}>Quantité</div>
          <ProtectedComponent roles={["ROLE_ADMIN"]}>
            <div className={style["item-element"]}>Actions</div>
          </ProtectedComponent>
        </div>
        {data &&
          data.discounts &&
          data.discounts.map((value: IDiscount, key: number) => {
            return <Item key={key} discount={value} onDelete={handleDelete} />;
          })}
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
      </div>
    </div>
  );

  function formatedQuery() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery = formatedQuery + `limit=${query.limit}`;
    }

    formatedQuery = formatedQuery + `&offset=${query.offset * query.limit}`;

    if (query.sort) {
      formatedQuery = formatedQuery + query.sort;
    }

    if (query.field && query.value) {
      formatedQuery = formatedQuery + `&${query.field}=${query.value}`;
    }

    return formatedQuery;
  }

  function handleInputChange({ target }: { target: HTMLInputElement }) {
    setQuery((prev) => ({ ...prev, value: target.value }));
  }

  async function handleSubstractQuantity(id: string) {
    controller.abort();
    try {
      controller = new AbortController();
      await substractDiscountQuantity(id, controller.signal);
      refetch();
    } catch (error) {
      console.error(error);
    }
  }

  async function handleDelete(id: string) {
    controller.abort();
    try {
      controller = new AbortController();
      await deleteDiscount(id, controller.signal);
      refetch();
    } catch (error) {
      console.error(error);
    }
  }
}
