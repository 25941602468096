import style from "./error-page.module.scss";
import { useState } from "react";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error: any = useRouteError();

  return (
    <div id="error-page">
      <div className={style["layout"]}>
        <div className={style["outlet"]}>
          <div className={style["container"]}>
            <h1 className={style["title"]}>
              {error.status} {error.statusText}
            </h1>
            {error.data && <p className={style["body"]}>{error.data}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
