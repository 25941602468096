import FrontLabel from "./FrontLabel";
import styles from "./blePrinter.module.scss";
import RearLabelList from "./RearLabelList";
import { useQuery } from "react-query";
import { getProduct } from "../../../requests/product";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import {
  getOperationsListAcronym,
  parseNonConventionalOperations,
  parseOperationsAcronym,
} from "../../../utils/parseLabelOperation.function";
import PrinterConnectHeader from "./PrinterConnectHeader";
import TextToCanvas from "../../../components/TextToCanvas/TextToCanvas";

export default function BlePrinter() {
  const { data, isLoading } = useQuery(["product"], () =>
    getProduct("671662e2dfe53877ba945a39")
  );
  if (!data) {
    return <></>;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className={styles["ble-printer"]}>
      <PrinterConnectHeader />
      <FrontLabel
        operations={parseOperationsAcronym(
          data.operationsList || [],
          getOperationsListAcronym(data.operationsList || [])
        )}
        workshopId={data.workshopId || ""}
        comments={
          data.dueDate
            ? "à faire avant le: " +
              new Date(data.dueDate).toLocaleDateString("fr-FR")
            : ""
        }
      />
      <RearLabelList
        operations={parseNonConventionalOperations(data.operationsList || [])}
        workshopId={data.workshopId || ""}
        dueDate={data.dueDate || null}
      />

      <TextToCanvas />
    </div>
  );
}
