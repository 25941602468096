import { UnfoldMore } from "@mui/icons-material";
import getStatusColor from "../../utils/translation/statusColor";
import { translateTrackingState } from "../../utils/tracking.init";
import Button from "../Button/Button";
import styles from "./stateSelect.module.scss";
import clsx from "clsx";
import { useState } from "react";
import DropDownPortal from "../DropDownPortal/DropDownPortal";
import useElementDimensions from "../../hooks/useElementDimensions";
import BeautifulDiv from "../beautifulDiv/BeautifulDiv";
import translate from "../../utils/translation";

interface StateSelectProps {
  state: string;
  list: string[];
  onStateChange: (state: string) => void;
  className?: string;
  isTracker?: boolean;
}

export default function StateSelect({
  state,
  list,
  onStateChange,
  className,
  isTracker = false,
}: StateSelectProps) {
  const [expand, setExpand] = useState<boolean>(false);
  const [refDrop, widthDrop] = useElementDimensions<HTMLUListElement>([expand]);
  const [ref, width] = useElementDimensions<HTMLDivElement>([expand]);

  return (
    <div className={clsx(styles["select-state"], className)} ref={ref}>
      <div className={styles["select-state-container"]}>
        <Button
          className={styles["state-button"]}
          style={{ backgroundColor: getStatusColor(state) }}
          onClick={handleToggleDropDown}
        >
          {isTracker ? translateTrackingState(state) : translate(state)}
          <UnfoldMore className={styles["icon"]} />
        </Button>
      </div>
      <DropDownPortal<HTMLDivElement>
        buttonRef={ref}
        className={styles["state-select-dropdown"]}
        expand={expand}
        onClickOutside={handleCloseDropDown}
        offset={{ top: 4, left: (widthDrop || 0) - (width || 0) }}
      >
        <ul className={styles["dropdown"]} ref={refDrop}>
          {list.map((stateItem, index) => (
            <StateDropDownItem
              key={index}
              isSelected={state === stateItem}
              stateItem={stateItem}
              isTracker={isTracker}
              onStateChange={onStateChange}
            />
          ))}
        </ul>
        <BeautifulDiv />
      </DropDownPortal>
    </div>
  );

  function handleToggleDropDown() {
    setExpand(!expand);
  }

  function handleCloseDropDown() {
    setExpand(false);
  }
}

interface stateItem {
  isSelected: boolean;
  stateItem: string;
  isTracker: boolean;
  onStateChange: (state: string) => void;
}

function StateDropDownItem({
  isSelected,
  stateItem,
  isTracker,
  onStateChange,
}: stateItem) {
  return (
    <li
      className={clsx(
        styles["dropdown-item"],
        isSelected && styles["selected"]
      )}
      onClick={() => onStateChange(stateItem)}
    >
      {isTracker ? translateTrackingState(stateItem) : translate(stateItem)}
    </li>
  );
}
