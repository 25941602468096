import styles from "./item.module.scss";
import { useState } from "react";
import { ILogistic } from "../../../types/logistic.types";
import { Delete, Edit, Launch } from "@mui/icons-material";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import CenterCell from "../../../components/CenterCell/CenterCell";
import State from "../../../components/State/State";
import ProductsCell from "./ProductsCell";
import clsx from "clsx";
import LogisticModal from "../../../components/modals/logisticModal/logisticModal";
import ConfirmModalComp from "../../../components/confirmationModal/ConfirmModalComp";
import { useConfirmModal } from "../../../context/confirmationModalContext";
import { ModalContainer } from "../../../components/modals/ModalContainer";
import { isDesktop } from "react-device-detect";
import CrossSellCell from "./CrossSellCell";

interface ItemProps {
  data: ILogistic;
  onDelete: () => void;
  refetch: () => void;
}

export default function Item({ data, onDelete, refetch }: ItemProps) {
  const { setConfirmModalIsOpen, setConfirmModalChildren } = useConfirmModal();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Launch />,
      name: "Ouvrir",
      onClick: () => {
        toogleLogisticModal();
      },
    },
    {
      icon: <Edit />,
      name: "Modifier",
      to: `/logistic/edit/${data.id}`,
    },
    {
      icon: <Delete />,
      name: "Supprimer",
      onClick: () => handleConfirmDelete(),
    },
  ];

  return (
    <>
      <tr
        className={clsx(
          styles["item"],
          isDesktop ? styles["hover"] : styles["active"]
        )}
      >
        <td
          className={`${styles["item-cell"]} ${styles["item-date"]}`}
          title={`${
            data.createdAt ? new Date(data.createdAt).toLocaleString() : ""
          }`}
          onClick={toogleLogisticModal}
        >
          {data.createdAt
            ? new Date(data?.createdAt).toLocaleDateString("fr-FR")
            : "indisponible"}
        </td>
        <td
          className={clsx(styles["item-cell"], styles["coordinates"])}
          onClick={toogleLogisticModal}
        >
          <div className={styles["item-coord"]}>
            <span className={styles["displayname"]}>
              {data.contact.displayname}
            </span>
            <span>{data.contact.organization}</span>
            <span>{data.contact.phone}</span>
            <span>{data.contact.email}</span>
          </div>
        </td>
        <td
          className={clsx(styles["item-cell"], styles["product-cell"])}
          onClick={toogleLogisticModal}
        >
          <ProductsCell productsList={data?.productsList} />
          <CrossSellCell crossSell={data?.crossSell} />
        </td>
        <td
          className={`${styles["item-cell"]} ${styles["status-box"]}`}
          onClick={toogleLogisticModal}
        >
          <CenterCell>
            <State state={data.status} />
          </CenterCell>
        </td>
        <td className={`${styles["item-cell"]} ${styles["tools"]}`}>
          <ProtectedComponent roles={["ROLE_ADMIN"]}>
            <CenterCell>
              <MoreTools options={MORE_TOOLS_OPTIONS} />
            </CenterCell>
          </ProtectedComponent>
        </td>
      </tr>
      <ModalContainer isOpen={modalIsOpen} onCancel={toogleLogisticModal}>
        <LogisticModal id={data.id} requiredActions={false} />
      </ModalContainer>
    </>
  );

  async function toogleLogisticModal() {
    setModalIsOpen(!modalIsOpen);
  }

  function handleConfirmDelete() {
    setConfirmModalChildren(
      <ConfirmModalComp
        confirmButtonClassName={styles["delete-button"]}
        title={"Voulez vous supprimer ce document ?"}
        confirmText="Supprimer"
        onConfirm={handleDelete}
      />
    );
    setConfirmModalIsOpen(true);
  }

  function handleDelete() {
    onDelete();
    refetch();
  }
}
