import { useEffect, useState } from "react";
import OperationDateCard from "../OperationDateCard/OperationDateCard";
import OperationTypeCard from "../OperationTypeCard/OperationTypeCard";

export function OperationGroups({
  workshopId,
  showPro,
}: {
  workshopId?: string;
  showPro?: boolean;
}) {
  return (
    <>
      <OperationTypeCard
        operationType="SHOES_PATAFER"
        title="Patin, Talon, Fer"
        workshopId={workshopId}
        showPro={showPro}
      />
      <OperationTypeCard
        operationType="SHOES_RESSEMELAGE"
        title="Ressemellage"
        workshopId={workshopId}
        showPro={showPro}
      />
      <OperationTypeCard
        operationType="SHOES_ENTRETIEN"
        title="Soins"
        workshopId={workshopId}
        showPro={showPro}
      />
      <OperationTypeCard
        operationType="SHOES_MISE_EN_FORME"
        title="Mise en forme"
        workshopId={workshopId}
        showPro={showPro}
      />
      <OperationTypeCard
        operationType="SHOES_TEINTURE"
        title="Teinture"
        workshopId={workshopId}
        showPro={showPro}
      />
      <OperationTypeCard
        operationType="SHOES_COLLAGE_COUTURE"
        title="Collage / Couture"
        workshopId={workshopId}
        showPro={showPro}
      />
      <OperationTypeCard
        operationType="SHOES_AUTRE"
        title="Autres"
        workshopId={workshopId}
        showPro={showPro}
      />
    </>
  );
}

export function DateGroups({
  workshopId,
  showPro,
}: {
  workshopId?: string;
  showPro?: boolean;
}) {
  const [date, setDate] = useState(new Date());

  function getDay(date: Date, additionalDays: number): string {
    let newDate = new Date();
    newDate.setDate(date.getDate() + additionalDays);

    return newDate.toISOString().substring(0, 10);
  }

  return (
    <>
      <OperationDateCard
        from={getDay(date, -1)}
        to={getDay(date, -1)}
        title="Hier"
        workshopId={workshopId}
        showPro={showPro}
      />
      <OperationDateCard
        from={getDay(date, 0)}
        to={getDay(date, 0)}
        title="Aujourd'hui"
        workshopId={workshopId}
        showPro={showPro}
      />
      <OperationDateCard
        from={getDay(date, 1)}
        to={getDay(date, 1)}
        title="J+1"
        workshopId={workshopId}
        showPro={showPro}
      />
      <OperationDateCard
        from={getDay(date, 2)}
        to={getDay(date, 2)}
        title="J+2"
        workshopId={workshopId}
        showPro={showPro}
      />
      <OperationDateCard
        from={getDay(date, 3)}
        to={getDay(date, 3)}
        title="J+3"
        workshopId={workshopId}
        showPro={showPro}
      />
      <OperationDateCard
        from={getDay(date, 4)}
        to={getDay(date, 4)}
        title="J+4"
        workshopId={workshopId}
        showPro={showPro}
      />
      <OperationDateCard
        from={getDay(date, 5)}
        to={getDay(date, 5)}
        title="J+5"
        workshopId={workshopId}
        showPro={showPro}
      />
      <OperationDateCard
        from={getDay(date, 6)}
        to={getDay(date, 6)}
        title="J+6"
        workshopId={workshopId}
        showPro={showPro}
      />
      <OperationDateCard
        from={getDay(date, 7)}
        to={getDay(date, 7)}
        title="J+7"
        workshopId={workshopId}
        showPro={showPro}
      />
      <OperationDateCard
        from={getDay(date, -365)}
        to={getDay(date, -2)}
        title="Retards"
        workshopId={workshopId}
        showPro={showPro}
      />
    </>
  );
}
