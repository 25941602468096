import Input from "../../../../components/inputs/Input/Input";
import { ICustomer } from "../../../../types/customer.type";
import styles from "./referral.module.scss";

interface ReferralProps {
  customer: ICustomer;
  onBalanceChange: (balance: number) => void;
}

export default function Referral({ customer, onBalanceChange }: ReferralProps) {
  return (
    <div>
      <label>Portefeuille</label>
      <Input
        className={styles["price-input"]}
        name={"price"}
        onChange={handleOnChange}
        value={customer.balance}
        type="number"
        inputMode="numeric"
      />
      <label>Code de parrainage</label>
      <p className={styles["referral-item"]}>{customer.referralCode}</p>
      <label>Nombres de parrainage</label>
      <p className={styles["referral-item"]}>{customer.referralCount}</p>
    </div>
  );

  function handleOnChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    const regex = /^[0-9]*[.,]?[0-9]*$/;

    if (!regex.test(target.value.toString())) {
      return;
    }
    target.value.replace(",", ".");
    const inputValue =
      target.value === ""
        ? 0
        : Math.floor(parseFloat(target.value as string) * 100);
    onBalanceChange(inputValue);
  }
}
