import { Delete, Edit } from "@mui/icons-material";
import { Link } from "react-router-dom";
import translate from "../../../utils/translation";
import { IUserData } from "../../../types/user.types";
import style from "./item.module.scss";

interface ItemProps {
  data: IUserData;
  onDelete: (id: string) => void;
}

export default function Item({ data, onDelete }: ItemProps) {
  function handleDelete() {
    if (
      window.confirm(
        "Êtes-vous sûre de vouloir supprimer définitivement cet utilisateur ?"
      )
    ) {
      onDelete(data._id);
    }
  }

  return (
    <div className={style["item"]}>
      <div className={style["username"]}>{data.username}</div>
      <div className={style["roles"]}>
        {data.roles.map((value: string, key: number) => {
          return (
            <div key={key}>
              <span>{translate(value)}</span>
              {key !== data.roles.length - 1 && <span>,</span>}
            </div>
          );
        })}
      </div>
      <div className={style["tools"]}>
        <div className={style["icon"]} title={"modifier"}>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            className={style["icon"]}
            to={`./edit/${data._id}`}
          >
            <Edit />
          </Link>
        </div>
        <div
          className={style["icon"]}
          onClick={handleDelete}
          title={"supprimer"}
        >
          <Delete />
        </div>
      </div>
    </div>
  );
}
