import clsx from "clsx";
import Price from "../../../../../../components/Price/Price";
import styles from "./quoteSumUp.module.scss";

interface QuoteSumUpProps {
  title: string;
  value: number;
  className?: string;
}

export default function QuoteSumUp({
  title,
  value,
  className,
}: QuoteSumUpProps) {
  return (
    <div className={clsx(styles["product-description"], className)}>
      <p className={styles["title"]}>{title}</p>
      <Price price={value} className={styles["price"]} />
    </div>
  );
}
