import { useQuery } from "react-query";
import styles from "./estimateTab.module.scss";
import { fetchEstimate } from "../../../../../requests/estimate";
import Create from "../../../../estimates/create/create";
import PictureLoadingSpinner from "../../../../../components/loadingSpinner/pictureloadingSpinner";
import DataError from "../../../../../components/errors/DataError/DataError";
import OverviewSection from "../OverviewSection/OverviewSection";

interface EstimateTabProps {
  estimateId: string;
}

export default function EstimateTab({ estimateId }: EstimateTabProps) {
  const { data, isLoading } = useQuery(
    ["estimate", estimateId],
    () => fetchEstimate({ params: { id: estimateId } }),
    { refetchOnWindowFocus: false, enabled: Boolean(estimateId) }
  );

  if (!estimateId) {
    return <></>;
  }

  if (isLoading) {
    return (
      <div className={styles["loading-spinner-container"]}>
        <PictureLoadingSpinner />
      </div>
    );
  }

  if (!data) {
    return <DataError error="estimate" />;
  }

  return (
    <OverviewSection className={styles["estimate-section"]}>
      <Create data={data} />
    </OverviewSection>
  );
}
