import React, { useEffect, useState } from "react";
import { IDiscount } from "../../../types/accounting.types";
import style from "./create.module.scss";
import { IValidationError } from "../../../types";
import { addDiscount } from "../../../requests/discount";
import { useNavigate } from "react-router-dom";
import translate from "../../../utils/translation";
import Input from "../../../components/inputs/Input/Input";

const INITIALE_DISCOUNT: IDiscount = {
  code: "",
  value: 0,
  isRate: true,
  expireAt: new Date(),
  baseQuantity: 0,
  quantity: 0,
};

export default function Create() {
  const [formValues, setFormValues] = useState<IDiscount>(INITIALE_DISCOUNT);
  const [validationError, setValidationError] = useState<
    IValidationError[] | string[]
  >([]);
  const navigate = useNavigate();

  useEffect(() => {
    setFormValues({
      ...formValues,
      quantity: formValues.baseQuantity,
    });
  }, [formValues.baseQuantity]);

  function handleChange({
    target,
  }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = target;
    if (name === "value") {
      if (parseInt(value)) {
        setFormValues({ ...formValues, [name]: parseInt(value) * 100 });
      }
      return;
    }
    if (name === "isRate") {
      setFormValues({ ...formValues, [name]: !formValues.isRate });
      return;
    }
    if (name === "expireAt") {
      setFormValues({ ...formValues, [name]: new Date(value) });
      return;
    }
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }

  async function handleSubmit(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();
    try {
      const response = await addDiscount(formValues);
      if ((response as IValidationError[]).length > 0) {
        setValidationError(response as IValidationError[]);
        return;
      }
      navigate("../");
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={style["container"]}>
      <div className={style["title"]}>Ajouter un code promo</div>
      <form>
        <div className={style["inputcontainer"]}>
          <label className={isRequired("code")}>
            Code{" "}
            <span className={style["error-message"]}>
              {IErrorMessage("code")}
            </span>
          </label>
          <input
            onChange={handleChange}
            name="code"
            placeholder="Nouveau code"
            // aria-label="Service description"
            type="text"
            value={formValues.code}
            className={isRequired("description")}
            onFocus={handleStartCorrection}
          />
        </div>
        <div className={style["inputcontainer"]}>
          <label className={isRequired("value")}>
            Valeur{" "}
            <span className={style["error-message"]}>
              {IErrorMessage("value")}
            </span>
          </label>
          <Input
            onChange={handleChange}
            name="value"
            placeholder="0"
            type="number"
            min={0}
            value={formValues.value}
            className={isRequired("value")}
            onFocus={handleStartCorrection}
          />
        </div>
        <div className={style["inputcontainer"]}>
          <div className={style["is-rate-container"]}>
            <label
              htmlFor={"isRate-percentage"}
              className={isRequired("isRate")}
            >
              Pourcentage{" "}
              <span className={style["error-message"]}>
                {IErrorMessage("isRate")}
              </span>
            </label>
            <div className={style["checkbox"]}>
              <input
                id={"isRate-percentage"}
                onChange={handleChange}
                name="isRate"
                placeholder="nouveau_service_unique"
                type="radio"
                checked={formValues.isRate}
                className={`${style["checkbox"]} ${isRequired("isRate")}`}
                onFocus={handleStartCorrection}
              />
            </div>
          </div>
          <div className={style["is-rate-container"]}>
            <label htmlFor={"isRate-value"} className={isRequired("isRate")}>
              Valeur{" "}
              <span className={style["error-message"]}>
                {IErrorMessage("isRate")}
              </span>
            </label>
            <div className={style["checkbox"]}>
              <input
                id={"isRate-value"}
                onChange={handleChange}
                name="isRate"
                placeholder="nouveau_service_unique"
                type="radio"
                checked={!formValues.isRate}
                className={`${style["checkbox"]} ${isRequired("isRate")}`}
                onFocus={handleStartCorrection}
              />
            </div>
          </div>
        </div>
        <div className={style["inputcontainer"]}>
          <label className={isRequired("baseQuantity")}>
            Quantité de base{" "}
            <span className={style["error-message"]}>
              {IErrorMessage("baseQuantity")}
            </span>
          </label>
          <input
            onChange={handleChange}
            name="baseQuantity"
            type="number"
            min={0}
            value={formValues.baseQuantity}
            className={isRequired("baseQuantity")}
            onFocus={handleStartCorrection}
          />
        </div>
        {/* <div className={style["inputcontainer"]}>
          <label className={isRequired("quantity")}>
            Quantité{" "}
            <span className={style["error-message"]}>
              {IErrorMessage("quantity")}
            </span>
          </label>
          <input
            onChange={handleChange}
            name="quantity"
            type="number"
            min={0}
            value={formValues.quantity}
            className={isRequired("quantity")}
            onFocus={handleStartCorrection}
          />
        </div> */}
        <div className={style["inputcontainer"]}>
          <label className={isRequired("expireAt")}>
            Date d'expiration{" "}
            <span className={style["error-message"]}>
              {IErrorMessage("expireAt")}
            </span>
          </label>
          <input
            onChange={handleChange}
            name="expireAt"
            type="date"
            min={new Date().toISOString().substring(0, 10)}
            value={formValues?.expireAt?.toISOString().substring(0, 10)}
            className={isRequired("expireAt")}
            onFocus={handleStartCorrection}
          />
        </div>
        <button
          className={`button-s ${style["validationbutton"]}`}
          type="submit"
          onClick={handleSubmit}
        >
          {"Créer Nouveau"}
        </button>
      </form>
    </div>
  );

  function isRequired(name: string): string {
    if (
      (validationError as IValidationError[]).find(
        (value: IValidationError) => name === value.field
      )
    ) {
      return style["validation-error"];
    }
    return "";
  }

  function IErrorMessage(name: string): string {
    const found = (validationError as IValidationError[]).find(
      (value: IValidationError) => name === value.field
    );
    if (found) {
      return "*" + translate(found.errorType);
    }
    return "";
  }

  function handleStartCorrection({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    const newValidation = (validationError as IValidationError[]).filter(
      (value: IValidationError) => value.field !== target.name
    );
    setValidationError([...newValidation]);
  }
}
