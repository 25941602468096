import clsx from "clsx";
import SectionFormTemplate from "../../../../../../components/forms/sectionFormTemplate/SectionFormTemplate";
import Input from "../../../../../../components/inputs/Input/Input";
import { OperationForm } from "../../../../../../types/accounting.types";
import styles from "./priceSection.module.scss";

interface PriceSectionProps {
  operationForm: OperationForm;
  onChange: (name: string, value: string | boolean | number) => void;
}

export default function PriceSection({
  operationForm,
  onChange,
}: PriceSectionProps) {
  return (
    <SectionFormTemplate title={"Prix"}>
      <div className={styles["list"]}>
        <table className={styles["price-table"]}>
          <thead className={styles["table-header"]}>
            <tr className={styles["table-header-row"]}>
              <th className={styles["table-header-cell"]}></th>
              <th className={styles["table-header-cell"]}>Prix TTC</th>
              <th className={styles["table-header-cell"]}>Prix HT</th>
            </tr>
          </thead>
          <tbody>
            <tr className={styles["table-header-row"]}>
              <td
                className={clsx(
                  styles["table-header-cell"],
                  styles["row-title"]
                )}
              >
                Standard
              </td>
              <td className={styles["table-header-cell"]}>
                <Input
                  className={styles["price-input"]}
                  name="price"
                  type="number"
                  inputMode="numeric"
                  value={!operationForm.price ? "" : operationForm.price}
                  onChange={({ target }) => onChange("price", target.value)}
                />
              </td>
              <td className={styles["table-header-cell"]}>
                <Input
                  className={styles["price-input"]}
                  name="price"
                  type="number"
                  inputMode="numeric"
                  value={operationForm.priceNoTax}
                  onChange={({ target }) =>
                    onChange("priceNoTax", target.value)
                  }
                />
              </td>
            </tr>
            <tr className={styles["table-header-row"]}>
              <td
                className={clsx(
                  styles["table-header-cell"],
                  styles["row-title"]
                )}
              >
                B2B
              </td>
              <td className={styles["table-header-cell"]}>
                <Input
                  className={styles["price-input"]}
                  name="price"
                  type="number"
                  inputMode="numeric"
                  value={operationForm.priceProBase}
                  onChange={({ target }) =>
                    onChange("priceProBase", target.value)
                  }
                />
              </td>
              <td className={styles["table-header-cell"]}>
                <Input
                  className={styles["price-input"]}
                  name="price"
                  type="number"
                  inputMode="numeric"
                  value={operationForm.priceProBaseNoTax}
                  onChange={({ target }) =>
                    onChange("priceProBaseNoTax", target.value)
                  }
                />
              </td>
            </tr>
            <tr className={styles["table-header-row"]}>
              <td
                className={clsx(
                  styles["table-header-cell"],
                  styles["row-title"]
                )}
              >
                B2B Luxe
              </td>
              <td className={styles["table-header-cell"]}>
                <Input
                  className={styles["price-input"]}
                  name="price"
                  type="number"
                  inputMode="numeric"
                  value={operationForm.priceProLuxe}
                  onChange={({ target }) =>
                    onChange("priceProLuxe", target.value)
                  }
                />
              </td>
              <td className={styles["table-header-cell"]}>
                <Input
                  className={styles["price-input"]}
                  name="price"
                  type="number"
                  inputMode="numeric"
                  value={operationForm.priceProLuxeNoTax}
                  onChange={({ target }) =>
                    onChange("priceProLuxeNoTax", target.value)
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </SectionFormTemplate>
  );
}

// interface CompProps {
//   operationForm: OperationForm;
// }

// function Comp({ operationForm }: CompProps) {
//   return (
//     <div className={styles["input-line"]}>
//       <Input
//         label="Prix B2B Luxe TTC"
//         name="price"
//         type="number"
//         value={operationForm.priceProLuxe}
//         onChange={() => onChange("priceProLuxe", operationForm["priceProLuxe"])}
//       />
//       <Input
//         label="Prix B2B Luxe HT"
//         name="price"
//         type="number"
//         value={operationForm.priceProLuxeNoTax}
//         onChange={() =>
//           onChange("priceProLuxeNoTax", operationForm["priceProLuxeNoTax"])
//         }
//       />
//     </div>
//   );
// }
