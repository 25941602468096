import { IShipping, IShippingPoint } from "../../../../types/shipping.types";
import style from "./externalShippingModal.module.scss";
import {
  CancelScheduleSend,
  Done,
  Download,
  Edit,
  Launch,
  LocationOn,
  ModeEdit,
  Phone,
} from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import { Link } from "react-router-dom";
import {
  cancelShippingLabel,
  getShippingLabel,
  reserveShippingEsdLabel,
  reserveShippingLabel,
  updateShippingState,
} from "../../../../requests/shipping";
import { useState } from "react";
import translate from "../../../../utils/translation";
import { PRINTMODE, STATE_OPTIONS } from "../../../../utils/shipping.init";
import { stateColor } from "./shippingModal";
import { ProtectedComponent } from "../../../../components/protectedComponent/protectedComponent";
import { IProduct } from "../../../../types/logistic.types";
import { useQuery } from "react-query";
import { fetchLogisticFromTrackerId } from "../../../../requests/tracker";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../../../../utils/toast.options";
import LoadingSpinner from "../../../../components/loadingSpinner/loadingSpinner";
import ShippingProductState from "./shippingProductState";

export default function ExternalShippingItem({
  value,
  refetch,
}: {
  value: IShipping;
  refetch: () => void;
}) {
  const [editState, setEditState] = useState<boolean>(false);
  const [state, setState] = useState<string>(value?.state || "PENDING");
  const [printMode, setPrintMode] = useState<string>("ZPL");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isFetching, error, data } = useQuery(
    ["logistic-from-trackerId", value.trackId],
    () => fetchLogisticFromTrackerId({ params: { id: value.trackId } }),
    {
      refetchOnWindowFocus: false,
      enabled: value.trackId !== undefined,
    }
  );

  const NEED_TO_GENERATE_LABEL =
    value.externalShipping?.pickUpId &&
    !value.externalShipping?.reservationNumber &&
    !value.externalShipping?.externalId;
  const ACTION_REQUIRED =
    !value.externalShipping?.pickUpId &&
    (value.externalShipping?.reservationNumber ||
      value.externalShipping?.externalId);
  const NEED_EDIT =
    !value.externalShipping?.pickUpId &&
    !value.externalShipping?.reservationNumber &&
    !value.externalShipping?.externalId &&
    value.externalShipping;

  return (
    <div className={style["chronopost-item-container"]}>
      {value.id && value.recipient && (
        <div className={style["chronopost-item"]}>
          <div className={style["left"]}>
            <div className={style["info-title"]}>
              <div className={style["info-title-left"]}>
                {value.contact && value.contact.displayname}
                <Link
                  className={style["access-button"]}
                  to={`/overview/${value?.trackId}`}
                >
                  <Launch className={style["access-button-icon"]} />
                </Link>
              </div>
            </div>
            {value?.recipient && (
              <ExternalContactInfo address={value.recipient} />
            )}
            <div className={style["state"]}>
              {editState ? (
                <select
                  name="field"
                  className={style["select-state"]}
                  onChange={({ target }) => {
                    setState(target.value);
                  }}
                >
                  <option>{"-- choisissez un état --"}</option>
                  {STATE_OPTIONS.map((value: string, index: number) => {
                    return (
                      <option key={index} value={value}>
                        {translate(value)}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <div className={style["display-state"]}>
                  {`Status de la ${translate(
                    value?.direction || ""
                  ).toLocaleLowerCase()} : `}
                  <div className={stateColor(value.state || "indisponible")}>
                    {translate(value?.state || "")}
                  </div>
                </div>
              )}
              <ProtectedComponent roles={["ROLE_ADMIN", "ROLE_LOGISTICS"]}>
                {editState ? (
                  <div className={style["done-button-container"]}>
                    <div
                      className={style["done-button"]}
                      onClick={() => value.id && updateState(state, value.id)}
                    >
                      <Done />
                    </div>
                  </div>
                ) : (
                  <div
                    className={style["edit-button"]}
                    onClick={changeEditState}
                  >
                    <ModeEdit />
                  </div>
                )}
              </ProtectedComponent>
            </div>
          </div>
          {value.externalShipping ? (
            <>
              {isLoading ? (
                <div className={style["right"]}>
                  <LoadingSpinner color="gold" />
                </div>
              ) : (
                <div className={style["right"]}>
                  {NEED_TO_GENERATE_LABEL ? (
                    <button
                      className={style["download-button"]}
                      onClick={() =>
                        value.shippingDestinationType === "DIRECT"
                          ? value.id &&
                            handleReserveShippingEsdLabel(value.id.toString())
                          : value.id &&
                            handleReserveShippingLabel(value.id.toString())
                      }
                    >
                      Génerer un bon CHRONOPOST
                      <SendIcon />
                    </button>
                  ) : (
                    <>
                      {ACTION_REQUIRED ? (
                        <div className={style["required-action"]}>
                          Données erronées veuillez annuler le Chronopost puis
                          modifier l'adresse de livraison avant de générer un
                          nouveau bon 😉
                          <div
                            className={style["cancel-button"]}
                            onClick={() => {
                              if (value.id) {
                                handleCancelShippingLabel(value.id);
                              }
                            }}
                          >
                            <CancelScheduleSend />
                          </div>
                        </div>
                      ) : (
                        <>
                          {NEED_EDIT ? (
                            <div className={style["required-action"]}>
                              <span>
                                Veuillez sélectionner l'adresse de livraison
                                (point relais)
                              </span>
                              <Link to={`./edit/${value.id}`}>
                                <Edit />
                              </Link>
                            </div>
                          ) : (
                            <>
                              <div className={style["external-id"]}>
                                <Link
                                  onClick={() => window.scrollTo(0, 0)}
                                  to={`https://www.chronopost.fr/tracking-no-cms/suivi-page?listeNumerosLT=${value.externalShipping.externalId}&langue=fr`}
                                >
                                  {value.externalShipping.externalId}
                                </Link>
                              </div>
                              <div className={style["download-container"]}>
                                <select
                                  onChange={({ target }) =>
                                    setPrintMode(target.value)
                                  }
                                  className={style["select-print-mode"]}
                                >
                                  {PRINTMODE.map(
                                    (value: string[], index: number) => {
                                      return (
                                        <option value={value[1]} key={index}>
                                          {value[0]}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                                {value.externalShipping &&
                                  value.shippingMethod && (
                                    <>
                                      <div
                                        className={style["download-button"]}
                                        onClick={() =>
                                          value.externalShipping &&
                                          handleGetShippingLabel(
                                            value.shippingMethod
                                              ?.shippingService as string,
                                            value.externalShipping
                                              .reservationNumber,
                                            printMode
                                          )
                                        }
                                      >
                                        <Download />
                                      </div>
                                      <div
                                        className={style["cancel-button"]}
                                        onClick={() => {
                                          if (value.id) {
                                            handleCancelShippingLabel(value.id);
                                          }
                                        }}
                                      >
                                        <CancelScheduleSend />
                                      </div>
                                    </>
                                  )}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          ) : (
            <div className={style["allday-right"]}>
              {`error "externalShipping" missing`}
            </div>
          )}
        </div>
      )}
      {data && (
        <div className={style["workshop-id-list"]}>
          {data.productsList &&
            data.productsList.length &&
            data.productsList.map((product: IProduct, index: number) => {
              return (
                <ShippingProductState
                  logisticId={data.id}
                  product={product}
                  index={index}
                  key={index}
                />
              );
            })}
        </div>
      )}
    </div>
  );

  async function updateState(state: string, id: string) {
    try {
      await updateShippingState(id, state);
      refetch();
      setEditState(!editState);
    } catch (error: any) {
      console.error(error);
    }
  }

  async function handleReserveShippingLabel(id?: string) {
    if (!id) {
      return;
    }
    try {
      setIsLoading(true);
      const data = await reserveShippingLabel(id);
      setIsLoading(false);
    } catch (error: any) {
      throw new Error(error.message);
    } finally {
      refetch();
    }
  }

  async function handleCancelShippingLabel(id: string) {
    try {
      const data = await cancelShippingLabel(id);
    } catch (error: any) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
      throw new Error(error.message);
    } finally {
      refetch();
    }
  }

  async function handleReserveShippingEsdLabel(id: string | undefined) {
    if (!id) {
      return;
    }
    try {
      setIsLoading(true);
      const data = await reserveShippingEsdLabel(id, value);
      setIsLoading(false);
      // value.externalShipping.reservationNumber = data.reservationNumber;
      // value.externalShipping.externalId = data.externalId;
      // await submitUpdateForm(event, false);
    } catch (error: any) {
      throw new Error(error.message);
    } finally {
      refetch();
    }
  }

  async function handleGetShippingLabel(
    shippingService: string,
    reservationNumber: string,
    mode: string
  ) {
    try {
      const response: any = await getShippingLabel(
        shippingService,
        reservationNumber,
        mode
      );

      const uint8Array = new Uint8Array(response.data);
      const blob = new Blob([uint8Array], {
        type: mode === "ZPL" ? "x-application/zpl" : "application/pdf",
      });

      const downloadLink = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = downloadLink;
      link.download = `chronopost-${reservationNumber}.${
        mode === "ZPL" ? "zpl" : "pdf"
      }`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  function changeEditState() {
    setEditState(!editState);
  }
}

interface ExternalContactInfoProps {
  address: IShippingPoint;
}

function ExternalContactInfo({ address }: ExternalContactInfoProps) {
  return (
    <>
      <div>
        <div className={style["shipping-info"]}>
          <div className={style["addresse"]}>
            <div>
              {address.address1}
              {address.address2}
            </div>
            {address.zipCode} {address.city} {address.country}
            <div className={style["address-comment"]}>{address.comments}</div>
          </div>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            className={style["address-icon"]}
            to={`http://maps.google.com/?q=${address.address1} ${address.address2} ${address.zipCode} ${address.city}`}
          >
            <LocationOn />
          </Link>
        </div>
      </div>
      <div className={style["shipping-info"]}>
        <div>{address.phone}</div>
        <Link
          onClick={() => window.scrollTo(0, 0)}
          className={style["address-icon"]}
          to={`tel:${address.phone}`}
        >
          <Phone />
        </Link>
      </div>
    </>
  );
}
