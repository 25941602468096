import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../errorPage/error-page";
import Estimates from ".";
import Create from "./create/create";
import { authloader } from "../../routes";
import Read from "./read/read";
import { loader as readLoader } from "./read/read";
import Edit from "./edit/edit";

export const route: RouteObject = {
  path: "/estimate",
  element: <Outlet />,
  loader: authloader,
  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <Estimates />,
      loader: authloader,
    },
    {
      path: "create",
      element: <Create />,
      loader: authloader,
    },
    {
      path: "edit/:id",
      element: <Edit />,
      loader: readLoader,
    },
    // {
    //   path: "read/:id",
    //   element: <Read />,
    //   loader: readLoader,
    // },
  ],
};
