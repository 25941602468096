import { useEffect, useState } from "react";
import { OperationForm as OperationFormType } from "../../../../../types/accounting.types";
import { OPERATION_INIT } from "../../../../../utils/operation.init";
import Button from "../../../../../components/Button/Button";
import InformationSection from "./InformationSection/InformationSection";
import PriceSection from "./PriceSection/PriceSection";
import RefashionSection from "./RefashionSection/RefashionSection";
import DescriptionSection from "./DescriptionSection/DescriptionSection";
import { useMutation } from "react-query";
import {
  checkIfSlugExist,
  postOperation,
  updateOperation,
} from "../../../../../requests/operation";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../../../utils/toast.options";
import slugify from "slugify";
import translate from "../../../../../utils/translation";
import { useDebounce } from "../../../../../hooks/useDebounce";

interface OperationFormProps {
  operation?: OperationFormType;
  operationGroups: string[];
  operationTypes: string[];
  productGroups: string[];
}

export default function OperationForm({
  operation,
  operationGroups,
  operationTypes,
  productGroups,
}: OperationFormProps) {
  const [formValues, setFormValues] = useState<OperationFormType>(
    operation || OPERATION_INIT
  );

  const addMutation = useMutation(postOperation, {
    onSuccess: () => {
      toast.success("Opération ajoutée avec succès.", TOAST_SUCCESS_OPTIONS);
    },
    onError: async (error: any) => {
      toast.error(`Une erreur est survenue`, TOAST_ERROR_OPTIONS);
    },
  });

  const updateMutation = useMutation(updateOperation, {
    onSuccess: () => {
      toast.success("Opération mis à jour avec succès.", TOAST_SUCCESS_OPTIONS);
    },
    onError: async (error: any) => {
      toast.error(`Une erreur est survenue`, TOAST_ERROR_OPTIONS);
    },
  });

  useEffect(() => {
    const basePrice = formValues.price + (formValues?.refashionDiscount || 0);
    const basePriceNoTax = Math.floor(basePrice / 1.2);
    setFormValues((prev) => ({ ...prev, basePrice: basePrice }));
    setFormValues((prev) => ({ ...prev, basePriceNoTax: basePriceNoTax }));
  }, [formValues.refashionDiscount]);

  useEffect(() => {
    if (operation) {
      return;
    }
    const slug = slugify(
      [formValues.name, translate(formValues.operationGroup)].join(" "),
      "-"
    ).toLocaleLowerCase();
    setFormValues((prev) => ({ ...prev, slug: !!formValues.name ? slug : "" }));
  }, [formValues.name, formValues.operationGroup]);

  return (
    <form onSubmit={handleSubmit}>
      <InformationSection
        operationForm={formValues}
        operationGroups={operationGroups}
        operationTypes={operationTypes}
        productGroups={productGroups}
        onChange={handleOnChange}
      />
      <DescriptionSection
        description={formValues.description}
        onChange={handleOnChange}
      />
      <RefashionSection
        operationForm={formValues}
        onChange={handleOnChange}
        onReset={handleRemoveRefashion}
      />
      <PriceSection operationForm={formValues} onChange={handleOnChange} />
      <Button type="submit">{operation ? "Enregistrer" : "Créer"}</Button>
    </form>
  );

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (operation) {
      updateMutation.mutate(formValues);
    } else {
      addMutation.mutate(formValues);
    }
  }

  function handleOnChange(name: string, value: string | boolean | number) {
    if (name === "refashionDiscount") {
      setFormValues((prev) => ({
        ...prev,
        refashionDiscount: parseInt((value as string).split(" - ")[1]) * 100,
        refashionOperation: (value as string).split(" - ")[0],
      }));
      return;
    }
    if (name.toLowerCase().includes("price")) {
      const regex = /^[0-9]*[.,]?[0-9]*$/;

      if (!regex.test(value.toString())) {
        return;
      }
      (value as string).replace(",", ".");
      const inputValue =
        value === "" ? 0 : Math.floor(parseFloat(value as string) * 100);
      setFormValues((prev) => ({ ...prev, [name]: inputValue }));
      if (name.includes("Tax")) {
        setFormValues((prev) => ({
          ...prev,
          [name.split("NoTax")[0]]: Math.floor(inputValue * 1.2),
        }));
      } else {
        setFormValues((prev) => ({
          ...prev,
          [`${name}NoTax`]: Math.floor(inputValue / 1.2),
        }));
      }
      if (["price", "priceNoTax"].includes(name)) {
        setFormValues((prev) => ({
          ...prev,
          basePrice: prev.price + (formValues.refashionDiscount || 0),
          basePriceNoTax: prev.priceNoTax + (formValues.refashionDiscount || 0),
        }));
      }
      return;
    }
    setFormValues((prev) => ({ ...prev, [name]: value }));
  }

  function handleRemoveRefashion() {
    const updatedForm = { ...formValues };
    delete updatedForm.refashionOperation;
    delete updatedForm.refashionDiscount;
    setFormValues(updatedForm);
  }
}
