import { useDrop } from "react-dnd";
import { IProduct } from "../../../../../../types/logistic.types";
import style from "./droppableList.module.scss";
import translate from "../../../../../../utils/translation";
import DraggableProduct from "../DraggableProduct/DraggableProduct";

interface IDroppableListProps {
  className?: string;
  productsList: IProduct[];
  list: IProduct[];
  title: string;
  moveProduct: (item: { product: IProduct }, listName: string) => void;
}

export function DroppableList({
  className,
  productsList,
  list,
  title,
  moveProduct,
}: IDroppableListProps) {
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: "Product",
      drop: (item: { product: IProduct }) => moveProduct(item, title),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [productsList]
  );

  return (
    <div
      className={`${style["validated-section"]} ${className}`}
      style={{ backgroundColor: isOver ? "#f0ddc8" : "" }}
    >
      <div className={style["validated-section-container"]}>
        <div className={style["section-title"]}>
          <div className={style["title"]}>
            {title !== "PRODUCT_CONTROLLED" ? translate(title) : "Non-faisable"}
          </div>
        </div>
        <div className={style["validated-product-list"]} ref={drop}>
          {(list as IProduct[]).map((product: IProduct) => {
            return <DraggableProduct product={product} key={product.id} />;
          })}
        </div>
      </div>
    </div>
  );
}
