import { useQuery } from "react-query";
import style from "./cobbler.module.scss";
import { getProductList } from "../../../../requests/product";
import { useState } from "react";
import { queryObject } from "../../../../types/query.types";
import ProductCard from "../productCard/ProductCard";
import SearchByWorkshopId from "../../../../components/searchByWorkshopId/searchByWorkshopId";
import PagingComponent from "../../../../components/pagingComponent/pagingComponent";
import OperationTypeCard from "./OperationTypeCard/OperationTypeCard";
import Button from "../../../../components/Button/Button";
import LoadingSpinner from "../../../../components/loadingSpinner/loadingSpinner";
import { useRefetchOnModalClose } from "../../../../hooks/useRefetchOnModalClose";
import { OperationGroups, DateGroups } from "./operationGroups/operationGroups";

const INITIAL_QUERY: queryObject = {
  limit: 50,
  offset: 0,
  sort: "",
  operationGroup: "",
  operationType: "SHOES_AUTRE",
  field: "",
  value: "",
};

export default function Cobbler() {
  const [query, setQuery] = useState<queryObject>(INITIAL_QUERY);
  const [showAll, setShowAll] = useState<boolean>(true);
  const [showPro, setShowPro] = useState<boolean>(false);

  const { data, isLoading, refetch } = useQuery(
    ["cobbler-product", query],
    () => getProductList(formatedQuery()),
    {
      keepPreviousData: true,
      select: (data) => {
        return {
          productsList: (data?.productsList || []).filter(
            (p) => p.operationGroup !== "SHOES_CLIMBING"
          ),
          totalCount: data?.totalCount || 0,
        };
      },
    }
  );
  useRefetchOnModalClose(refetch);

  return (
    <div className={style["cobbler"]}>
      <h1>Cordonnerie</h1>
      <div className={style["filters-section"]}>
        <div className={style["set-mode"]}>
          <Button
            className={`${style["select-mode-button"]} ${
              showAll ? style["selected"] : ""
            }`}
            onClick={() => setShowAll(true)}
          >
            Par Date
          </Button>
          <Button
            className={`${style["select-mode-button"]} ${
              !showAll ? style["selected"] : ""
            }`}
            onClick={() => setShowAll(false)}
          >
            Par Operation
          </Button>
        </div>
        <SearchByWorkshopId
          workshopId={query.workshopId || ""}
          setWorkshopId={(workshopId) =>
            setQuery((prev) => ({ ...prev, workshopId }))
          }
        />
      </div>
      <div className={style["show-pro-container"]}>
        <input
          id="show-pro-checkbox"
          type="checkbox"
          checked={showPro}
          onChange={() => setShowPro((prev) => !prev)}
        />
        <label>Afficher pro</label>
      </div>
      {showAll ? (
        <DateGroups workshopId={query.workshopId} showPro={showPro} />
      ) : (
        <OperationGroups workshopId={query.workshopId} showPro={showPro} />
      )}
    </div>
  );

  function formatedQuery() {
    let formatedQuery: string = `?limit=${query.limit}&offset=${
      query.offset * query.limit
    }&productGroup=SHOES&status=WORKSHOP_REPAIR;WORKSHOP_CARE;EXTERNAL_JOB`;

    if (query.workshopId) {
      formatedQuery += `&workshopId=${query.workshopId}`;
    }

    return formatedQuery;
  }
}
