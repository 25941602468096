import clsx from "clsx";
import styles from "./operationItem.module.scss";
import CenterCell from "../../../../CenterCell/CenterCell";
import Price from "../../../../Price/Price";
import { OperationForm } from "../../../../../types/accounting.types";

interface OperationItemProps {
  isChecked: boolean;
  operation: OperationForm;
  proCategory: string;
  onOperationSelect: () => void;
}

export default function OperationItem({
  isChecked,
  operation,
  proCategory,
  onOperationSelect,
}: OperationItemProps) {
  return (
    <tr className={styles["item"]}>
      <td className={styles["item-cell"]} onClick={onOperationSelect}>
        <input
          className={styles["checkbox"]}
          type="checkbox"
          checked={isChecked}
          onChange={() => {}}
        />
      </td>
      <td className={styles["item-cell"]} onClick={onOperationSelect}>
        {operation.name}
      </td>
      <td
        className={clsx(
          styles["item-cell"],
          styles["price-cell"],
          proCategory === "base" && styles["selected"]
        )}
      >
        <CenterCell>
          <Price price={operation.priceProBase} />
        </CenterCell>
      </td>
      <td
        className={clsx(
          styles["item-cell"],
          styles["price-cell"],
          proCategory === "base" && styles["selected"]
        )}
      >
        <CenterCell>
          <Price price={operation.priceProBaseNoTax} />
        </CenterCell>
      </td>
      <td
        className={clsx(
          styles["item-cell"],
          styles["price-cell"],
          proCategory === "luxe" && styles["selected"]
        )}
      >
        <CenterCell>
          <Price price={operation.priceProLuxe} />
        </CenterCell>
      </td>
      <td
        className={clsx(
          styles["item-cell"],
          styles["price-cell"],
          proCategory === "luxe" && styles["selected"]
        )}
      >
        <CenterCell>
          <Price price={operation.priceProLuxeNoTax} />
        </CenterCell>
      </td>
    </tr>
  );
}
