import HANDBAG_DEFAULT from "../../assets/maro.webp";
import SNEAKERS_DEFAULT from "../../assets/sneakers.webp";
import WOMAN_SHOES_DEFAULT from "../../assets/article-femme.webp";
import MAN_SHOES_DEFAULT from "../../assets/man_shoes.webp";
import CLIMBING_SHOES_DEFAULT from "../../assets/climbing_shoes.webp";

interface OperationGroupLogoProps {
  className?: string;
  operationGroup: string;
}

export default function OperationGroupLogo({
  className,
  operationGroup,
}: OperationGroupLogoProps) {
  return (
    <img
      draggable={false}
      className={className}
      src={getOperationGroupLogo(operationGroup)}
    />
  );

  function getOperationGroupLogo(operationGroup: string) {
    switch (operationGroup) {
      case "SHOES_MAN":
        return MAN_SHOES_DEFAULT;
      case "SHOES_WOMAN":
        return WOMAN_SHOES_DEFAULT;
      case "SHOES_SNEAKERS":
        return SNEAKERS_DEFAULT;
      case "SHOES_CLIMBING":
        return CLIMBING_SHOES_DEFAULT;
      case "LEATHER_SMALL":
        return HANDBAG_DEFAULT;
      case "LEATHER_LARGE":
        return HANDBAG_DEFAULT;
      default:
        return "";
    }
  }
}
