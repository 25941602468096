import LicenseLabel from "../../../../components/licenseLabel/licenseLabel";
import WorkshopId from "../../../../components/workshopId/workshopId";
import translate from "../../../../utils/translation";
import { IShop, IShopOperation } from "../../../../types/shop.types";
import style from "./shopValidation.module.scss";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function ShopValidation({
  shop,
  setShop,
}: {
  shop: IShop;
  setShop: (shop: IShop) => void;
}) {
  return (
    <div className={style["shop-validation"]}>
      <h1>{shop?.name}</h1>
      <div className={style["shop-validation-header"]}>
        <div>
          <div className={style["organization"]}>
            {shop?.contact?.organization}
          </div>
          <label>Contact</label>
          <div>{shop?.contact?.displayname}</div>
          <label>Télephone</label>
          <div>{shop?.contact?.phone}</div>
          <label>Email</label>
          <div>{shop?.contact?.email}</div>
          <label>Adresse</label>
          <div>
            {shop?.contact?.address1} {shop?.contact?.address2}{" "}
            {shop?.contact?.zipCode} {shop?.contact?.city}
          </div>
        </div>
        <div className={style["shop-license"]}>
          <LicenseLabel
            className={style["license-rank"]}
            rank={shop.licenseRank as string}
          />
          <WorkshopId size="xx-small" workshopId={shop?.workshopPrefixId} />
        </div>
      </div>
      <label>Méthode de livraison</label>

      <div>{shop?.shippingMethod?.name}</div>
      <label>Liste des opérations</label>
      <div>
        {(shop?.pricing?.operationsList || [])
          .sort((a, b) =>
            translate(a?.operationGroup || "").localeCompare(
              translate(b?.operationGroup || "")
            )
          )
          .map((op: IShopOperation, index: number) => {
            return (
              <div className={style["operation-item"]} key={index}>
                <div
                  className={`${op.publicDisplay ? "" : style["non-visible"]}`}
                >
                  {[
                    translate(op?.operationGroup),
                    op?.description,
                    (op?.professionalDiscount || 0) / 1000 + " %",
                  ].join(" - ")}
                </div>
                <div
                  className={style["visibility"]}
                  onClick={() => handleSetOperationVisibility(index)}
                >
                  {op.publicDisplay ? <VisibilityOff /> : <Visibility />}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );

  function handleSetOperationVisibility(operationIndex: number) {
    setShop({
      ...shop,
      pricing: {
        ...shop.pricing,
        operationsList: shop.pricing.operationsList.map((op, index) => {
          return index === operationIndex
            ? { ...op, publicDisplay: !op.publicDisplay }
            : op;
        }),
      },
    });
  }
}
