import styles from "./timelineItem.module.scss";
import {
  Check,
  CheckCircle,
  CheckCircleOutline,
  Delete,
  DeleteOutline,
  Launch,
  RadioButtonChecked,
} from "@mui/icons-material";
import clsx from "clsx";
import { useState } from "react";
import { Note } from "../../../types/tracker.types";
import { ProtectedComponent } from "../../protectedComponent/protectedComponent";

interface TimelineItemProps {
  note: Note;
  onRemove: () => void;
  rank?: "first" | "last" | "middle";
  canBeDeleted: boolean;
}

export default function TimelineItem({
  note,
  onRemove,
  rank = "middle",
  canBeDeleted,
}: TimelineItemProps) {
  return (
    <>
      <li className={clsx(styles["timeline-item"], styles[rank])}>
        <div className={styles["timeline-item-left"]}>
          <RadioButtonChecked className={styles["radio-icon"]} />
          {rank !== "first" && <div className={styles["line"]}></div>}
        </div>
        <div className={styles["timeline-item-right"]}>
          <div className={styles["timeline-item-right-top"]}>
            <p className={styles["timeline-date"]}>
              {new Date(note.timeStamp).toLocaleDateString("fr-FR", {
                day: "2-digit",
                month: "short",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              })}
              {" - "}
              {note.user}
            </p>
            <p className={styles["message"]}>{note.body}</p>
          </div>
          {canBeDeleted && (
            <ProtectedComponent roles={["ROLE_ADMIN"]}>
              <p className={styles["delete"]} onClick={onRemove}>
                <DeleteOutline className={styles["delete-icon"]} />
              </p>
            </ProtectedComponent>
          )}
        </div>
      </li>
    </>
  );
}
