import { useQuery } from "react-query";
import { fetchCustomer } from "../../requests/customer";
import LoadingSpinner from "../loadingSpinner/loadingSpinner";
import styles from "./customerLink.module.scss";
import { Link } from "react-router-dom";
import { Launch } from "@mui/icons-material";
import PictureLoadingSpinner from "../loadingSpinner/pictureloadingSpinner";

interface CustomerLinkProps {
  id: string;
}

export default function CustomerLink({ id }: CustomerLinkProps) {
  const { data: customer, isLoading } = useQuery(
    ["customer", id],
    () => fetchCustomer(id),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return <PictureLoadingSpinner />;
  }

  if (!customer) {
    return <>customer is missing !</>;
  }

  return (
    <Link to={`/customers/edit/${id}`} className={styles["customer-link"]}>
      <p>{[customer.givenname, customer.familyname].join(" ")}</p>
      <Launch className={styles["icon"]} />
    </Link>
  );
}
