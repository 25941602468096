import { useQuery } from "react-query";
import style from "./index.module.scss";
import {
  deleteCrossSellItem,
  getCrossSellsList,
} from "../../requests/crossSelling";
import { Link } from "react-router-dom";
import { ProtectedComponent } from "../../components/protectedComponent/protectedComponent";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import Item from "./item/item";
import { ICrossSell } from "../../types/accounting.types";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "../../utils/toast.options";
import { useModalComponent } from "../../context/modalComponent.context";
import { Replay } from "@mui/icons-material";
import { queryObject } from "../../types/query.types";
import useSaveQueryStorage from "../../hooks/useSaveQueryStorage";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import Button from "../../components/Button/Button";
import translate from "../../utils/translation";
import RadioItem from "../../components/RadioItem/RadioItem";
import ColorPicker from "../../components/ColorPicker/ColorPicker";

export const INITIAL_QUERY: queryObject = {
  value: "",
  direction: "",
  limit: 10,
  offset: 0,
  sort: "category",
  state: "Tous",
  color: "",
  category: "Tous",
};

const CATEGORY_LIST = ["Brosses", "Cirage", "Crème surfine", "Divers", "Tous"];

export default function CrossSell() {
  const [query, setQuery] = useSaveQueryStorage(INITIAL_QUERY, "cross-sell");
  const { data, isLoading, error, refetch } = useQuery(
    ["cross-selling-list", query],
    () => getCrossSellsList(formatedQuery()),
    { keepPreviousData: true }
  );
  const { setModalIsOpen } = useModalComponent();

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <div className={style["cross-selling"]}>
      <div className={style["top"]}>
        <span className={style["title"]}>Cross-selling</span>
        <ProtectedComponent roles={["ROLE_ADMIN"]}>
          <Link onClick={() => window.scrollTo(0, 0)} to={"./create"}>
            <Button>+ Ajouter nouveau</Button>
          </Link>
        </ProtectedComponent>
      </div>
      <div className={style["filters-container"]}>
        <div className={style["filters-container-left"]}>
          <div className={style["category-filter-container"]}>
            {/* <label>Catégorie</label> */}
            <div className={style["category-list"]}>
              {CATEGORY_LIST.map((category: string, index: number) => (
                <RadioItem
                  className={style["category-item"]}
                  checked={category === query.category}
                  setChecked={() => handleFilterByCategory(category)}
                  key={index}
                  title={translate(category)}
                />
              ))}
            </div>
          </div>
          <div className={style["color-picker-container"]}>
            <ColorPicker
              color={query.color || ""}
              handleColorChange={(value) =>
                setQuery((prev: any) => ({ ...prev, color: value }))
              }
            />
          </div>
        </div>
        <div className={style["reset-filters-container"]}>
          {query !== INITIAL_QUERY && (
            <div
              className={style["reset-filters"]}
              onClick={() => setQuery(INITIAL_QUERY)}
              title="réinitialiser"
            >
              <Replay />
            </div>
          )}
        </div>
      </div>
      <div className={style["cross-selling-list-container"]}>
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
        <div className={style["headers"]}>
          <div className={style["header-element"]}>Description</div>
          <div className={style["header-element"]}>Groupe d'articles</div>
          <div className={style["header-element"]}>Catégorie</div>
          <div className={style["header-element"]}>Prix (TTC)</div>
          <div className={style["header-element"]}>Quantité</div>
          <ProtectedComponent roles={["ROLE_ADMIN"]}>
            <div className={style["header-element"]}>Actions</div>
          </ProtectedComponent>
        </div>
        {data && data.crossSells && data.crossSells.length > 0 ? (
          <>
            {data.crossSells.map((item: ICrossSell, index: number) => {
              return (
                item && (
                  <Item
                    data={item}
                    handleDelete={handleDelete}
                    key={index}
                    handleCloseModal={handleCloseModal}
                    zIndex={data.crossSells.length - index}
                  />
                )
              );
            })}
          </>
        ) : (
          <div className={style["error-message"]}>
            <>
              {query === INITIAL_QUERY
                ? "Aucun article trouvé 😢"
                : "Aucun article n'a été trouvé avec ce filtre 😢"}
            </>
          </div>
        )}
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
      </div>
    </div>
  );

  async function handleDelete(slug: string | undefined) {
    if (!slug) {
      return;
    }

    if (window.confirm("Voulez-vous supprimer cet article ?")) {
      try {
        const response = await deleteCrossSellItem(slug);
        await refetch();
        toast.warning("Article supprimer", TOAST_OPTIONS);
      } catch (error: any) {
        console.error(error);
      }
    }
  }

  async function handleCloseModal() {
    try {
      setModalIsOpen(false);
      await refetch();
    } catch (error: any) {
      console.error(error);
    }
  }

  function formatedQuery() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery = formatedQuery + `?limit=${query.limit}`;
    }

    formatedQuery = formatedQuery + `&offset=${query.offset * query.limit}`;

    if (query.field && query.value) {
      formatedQuery = formatedQuery + `&${query.field}=${query.value}`;
    }

    if (query.sort) {
      formatedQuery = formatedQuery + `&sort=${query.sort}`;
    }

    // if (query.sort) {
    //   formatedQuery = formatedQuery + query.sort;
    // }

    if (query.category && query.category !== "Tous") {
      formatedQuery = formatedQuery + `&category=${query.category}`;
    }

    if (query.color) {
      formatedQuery = formatedQuery + `&color=${query.color}`;
    }

    return formatedQuery;
  }

  function handleFilterByCategory(category: string) {
    setQuery((prev: any) => ({ ...prev, category: category }));
  }

  function handleColorChange({
    name,
    value,
  }: {
    name: string;
    value: string | number;
  }) {
    setQuery((prev: any) => ({ ...prev, color: value as string }));
  }
}
