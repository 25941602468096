import { useParams } from "react-router-dom";
import styles from "./edit.module.scss";
import { useQuery } from "react-query";
import { getReferral } from "../../../requests/referrals";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import ReferralForm from "../../../components/forms/ReferralForm/ReferralForm";

export default function Edit() {
  const { id } = useParams();
  const { data, isLoading } = useQuery(
    ["referral-edit", id],
    () => getReferral(id),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(id),
    }
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!data) {
    return <>data is missing !</>;
  }

  const { referral } = data;

  return (
    <div className={styles["container"]}>
      <div className={styles["title"]}>Modifier un parrainage</div>
      <ReferralForm referral={referral} />
    </div>
  );
}
