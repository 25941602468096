import { useState } from "react";
import style from "./downloadLabel.module.scss";
import { PRINTMODE } from "../../../../../../../utils/shipping.init";
import { isDesktop } from "react-device-detect";
import {
  getShippingLabelMutation,
  reserveShippingLabelMutation,
} from "../../../../../../../requests/shipping";
import { IShipping } from "../../../../../../../types/shipping.types";
import Button from "../../../../../../../components/Button/Button";
import { toast } from "react-toastify";
import { STD_ERROR_MESSAGE } from "../../../../../../../utils/constants";
import { TOAST_ERROR_OPTIONS } from "../../../../../../../utils/toast.options";
import useClickOutside from "../../../../../../../hooks/useClickOutSide";
import { useMutation } from "react-query";
import axios from "axios";

interface DownloadLabelProps {
  className?: string;
  refetch: () => void;
  shipping: IShipping;
}

export default function DownloadLabelButton({
  className,
  shipping,
  refetch,
}: DownloadLabelProps) {
  const [expand, setExpand] = useState<boolean>(false);
  const ref = useClickOutside<HTMLDivElement>(() => setExpand(false));

  const getShippingLabel = useMutation(getShippingLabelMutation, {
    onSuccess: (response) => {
      handleResponse({
        response: response.data,
        mode: response.mode,
        reservationNumber: response.reservationNumber,
      });
    },
    onError: () => {
      toast.error(STD_ERROR_MESSAGE);
    },
  });

  const reserveShippingLabel = useMutation(reserveShippingLabelMutation, {
    onSuccess: (response) => {
      refetch();
      getShippingLabel.mutate({
        shippingService: response.shippingService,
        mode: response.mode,
        reservationNumber: response.data.reservationNumber,
      });
    },
    onError: () => {
      toast.error(STD_ERROR_MESSAGE);
    },
  });

  return (
    <div
      className={`${style["download-container"]} ${className || ""}`}
      ref={ref}
    >
      <Button
        onClick={() => setExpand(!expand)}
        isLoading={getShippingLabel.isLoading || reserveShippingLabel.isLoading}
      >
        Télécharger bon chronopost
      </Button>
      {expand && (
        <div className={style["print-mode-list-container"]}>
          <div className={style["print-mode-list"]}>
            {PRINTMODE.map((mode, index) => (
              <div
                className={`${style["mode-item"]} ${
                  isDesktop ? style["hover"] : style["active"]
                }`}
                key={index}
                onClick={() => handleGetShippingLabel(mode[1])}
              >
                {mode[0]}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );

  async function handleGetShippingLabel(mode: string) {
    if (shipping?.externalShipping?.reservationNumber) {
      getShippingLabel.mutate({
        shippingService: shipping.shippingMethod?.shippingService as string,
        mode: mode,
        reservationNumber: shipping?.externalShipping?.reservationNumber,
      });
    } else {
      reserveShippingLabel.mutate({
        id: shipping.id!,
        shippingService: shipping.shippingMethod?.shippingService as string,
        mode: mode,
      });
    }
  }

  function handleResponse({
    response,
    mode,
    reservationNumber,
  }: {
    response: any;
    mode: string;
    reservationNumber?: string;
  }) {
    const uint8Array = new Uint8Array(response.data);
    const blob = new Blob([uint8Array], {
      type: mode === "ZPL" ? "x-application/zpl" : "application/pdf",
    });

    if (mode === "ZPL") {
      let form = new FormData();
      form.append("file", blob);
      axios
        .post("http://192.168.1.8:3000/print", form)
        .catch((error) => console.warn(error))
        .finally(() => setExpand(false));
    } else {
      const downloadLink = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadLink;
      link.download = `chronopost-${reservationNumber}.${
        mode === "ZPL" ? "zpl" : "pdf"
      }`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setExpand(false);
    }
  }
}
