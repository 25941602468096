import style from "./selectDirection.module.scss";
import Select from "../../../../components/Select/Select";
import translate from "../../../../utils/translation";
import getStatusColor from "../../../../utils/translation/statusColor";

interface SelectDirectionProps {
  direction: string | null;
  setDirection: (value: string) => void;
  isEdit: boolean;
  shippingLabel: string | null;
}

export default function SelectDirection({
  direction,
  setDirection,
  isEdit,
  shippingLabel,
}: SelectDirectionProps) {
  if (isEdit && direction) {
    return (
      <div className={style["direction"]}>
        <div
          className={style["direction-info"]}
          style={{ backgroundColor: getStatusColor(direction) }}
        >
          {translate(direction)}
        </div>
      </div>
    );
  }
  return (
    <div className={style["direction"]}>
      <Select
        className={style["select-shipping-method"]}
        optionsList={["INCOMING", "OUTGOING"]}
        placeholder="Direction..."
        setValue={handleChangeDirection}
        value={direction || ""}
      />
    </div>
  );

  function handleChangeDirection(value: string) {
    if (shippingLabel) {
      return window.alert(
        'Vous ne pouvez pas faire de modifications. Vous devez "Annuler" le bon chronopost actuel avant de pouvoir faire des modifications.'
      );
    }
    setDirection(value);
  }
}
