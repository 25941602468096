import { ChangeEvent, useRef, useState } from "react";
import styles from "./importXlsxModal.module.scss";
import Button from "../../Button/Button";
import {
  exportOperationsTemplate,
  uploadOperations,
} from "../../../requests/operation";
import { toast } from "react-toastify";
import { STD_ERROR_MESSAGE } from "../../../utils/constants";
import { TOAST_ERROR_OPTIONS } from "../../../utils/toast.options";

interface UploadOperationsResponse {
  numberOfOperationInSheet: number;
  numberOfOperationUploaded: number;
  numberOfOperationUpdated: number;
  numberOfErrors: number;
}

export default function ImportXlsxModal() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadResult, setUploadResult] =
    useState<UploadOperationsResponse | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const ref = useRef<HTMLInputElement | null>(null);

  return (
    <div className={styles["import-xlsx-modal"]}>
      <div className={styles["modal-header"]}>
        <div className={styles["import-section"]}>
          <Button className={styles["button"]} onClick={handleOpenImportFile}>
            Importer un fichier
          </Button>
          <p className={styles["file-name"]}>{file ? file.name : null}</p>
        </div>
        <input
          ref={ref}
          className={styles["file-input"]}
          type="file"
          accept=".xls,.xlsx"
          onChange={handleFileChange}
        />
        <Button
          className={styles["button"]}
          onClick={handleExportOperationsTemplate}
        >
          Modèle fichier excel
        </Button>
        <Button
          className={styles["button"]}
          disabled={!file}
          isLoading={isLoading}
          onClick={handleUploadFile}
        >
          Valider
        </Button>
      </div>
      <div className={styles["results-container"]}>
        {!!uploadResult && (
          <div className={styles["results"]}>
            <p>Total des opérations: {uploadResult.numberOfOperationInSheet}</p>
            <p>
              Opérations mises à jour: {uploadResult.numberOfOperationUpdated}
            </p>
            <p>Opérations ajoutées: {uploadResult.numberOfOperationUploaded}</p>
            <p>Erreurs: {uploadResult.numberOfErrors}</p>
          </div>
        )}
      </div>
    </div>
  );

  function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  }

  function handleOpenImportFile() {
    ref.current?.click();
  }

  async function handleUploadFile() {
    if (!file) {
      return;
    }
    setUploadResult(null);
    try {
      setIsLoading(true);
      const response = await uploadOperations(file);
      setUploadResult(response);
    } catch (error: any) {
      toast.error(STD_ERROR_MESSAGE, TOAST_ERROR_OPTIONS);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleExportOperationsTemplate() {
    try {
      const response = await exportOperationsTemplate();
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "operation-import-template.xlsx";
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error: any) {
      toast.error(STD_ERROR_MESSAGE, TOAST_ERROR_OPTIONS);
    }
  }
}
//exportOperationsTemplate
