import { useMutation, useQuery } from "react-query";
import style from "./climbing.module.scss";
import {
  getCartsList,
  updateCart,
} from "../../../../requests/professionalCarts";
import { useState } from "react";
import { IShop } from "../../../../types/shop.types";
import SelectAndSearchShop from "../../../../components/SelectAndSearchShop/SelectAndSearchShop";
import { queryObject } from "../../../../types/query.types";
import SearchByWorkshopId from "../../../../components/searchByWorkshopId/searchByWorkshopId";
import ClimbingItemCard from "./climbingItemCard/ClimbingItemCard";
import {
  IOperation,
  IProCart,
  IProduct,
} from "../../../../types/proCart.types";
import { getOperationsListPro } from "../../../../requests/operationPro";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../../utils/toast.options";

export const INITIAL_QUERY: queryObject = {
  field: "",
  value: "",
  service: "",
  direction: "",
  status: "",
  limit: 10,
  offset: 0,
  sort: "",
  workshopId: "",
};

function Cart() {
  return <div></div>;
}

export default function Climbing() {
  const [shop, setShop] = useState<IShop | null>(null);
  const [query, setQuery] = useState<Partial<queryObject>>(INITIAL_QUERY);
  const { data, isLoading, refetch } = useQuery(
    ["climbing-cart", query, shop],
    () => getCartsList(formatedQuery())
  );
  const { data: operationProData } = useQuery(["climbing-operation"], () =>
    getOperationsListPro("operationGroup=SHOES_CLIMBING")
  );
  const cartMutation = useMutation(updateCart, {
    onSuccess: () => {
      toast.success("Opération changée avec succès.", TOAST_SUCCESS_OPTIONS);
      refetch();
    },
    onError: () => {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    },
  });

  return (
    <div className={style["climbing"]}>
      {/* <h2>Chaussons d'escalades</h2> */}
      {/* <div className={style["climbing-header"]}> */}
      {/* <SelectAndSearchShop shop={shop} setShop={setShop} /> */}
      {/* <SearchByWorkshopId
          workshopId={query.workshopId || ""}
          setWorkshopId={handleSetWorkshopIdQuery}
        /> */}
      {/* </div> */}
      <div className={style["climbing-list"]}>
        {data &&
          operationProData &&
          data?.cartsList.splice(0, 20).map((cart) => {
            return cart.productsList.map((product: IProduct, index: number) => {
              return (
                <ClimbingItemCard
                  key={index}
                  product={product}
                  operationsList={operationProData.operations as IOperation[]}
                  updateCartOperation={(operation) =>
                    updateCartOperation(cart, index, operation)
                  }
                />
              );
            });
          })}
        {data?.cartsList.length === 0 && (
          <div className={style["no-content"]}>{"aucun article trouvé"}</div>
        )}
      </div>
    </div>
  );

  function updateCartOperation(
    cart: IProCart,
    productIndex: number,
    newOperation: IOperation
  ) {
    const finalPrice =
      newOperation.price -
      (newOperation.price * (newOperation.professionalDiscount || 0)) / 100;
    const newCart: IProCart = {
      ...cart,
      productsList: cart.productsList.map((product, index) => {
        return index === productIndex
          ? {
              ...product,
              operationsList: [
                {
                  ...newOperation,
                  finalPrice,
                  finalPriceNoTax: finalPrice / 1.2,
                  shopPrice: finalPrice,
                  shopPriceNoTax: finalPrice / 1.2,
                },
              ],
            }
          : product;
      }),
    };
    cartMutation.mutate(newCart);
  }

  function formatedQuery() {
    let formatedQuery: string =
      "?state=CART_IN_REPAIR&productsList.operationGroup=SHOES_CLIMBING&sort_field=productsList.workshopId&sort_direction=asc";

    if (query.workshopId) {
      formatedQuery += `&productsList.workshopId=${query.workshopId}`;
    }

    if (shop && shop.id) {
      formatedQuery += `&shopId=${shop.id}`;
    }

    return formatedQuery;
  }

  function handleSetWorkshopIdQuery(value: string) {
    setQuery((prev) => ({ ...prev, workshopId: value }));
  }
}
