import style from "./productCard.module.scss";
import { IContact, IProduct } from "../../../../types/logistic.types";
import translate from "../../../../utils/translation";
import WorkshopId from "../../../../components/workshopId/workshopId";
import { PRODUCT_STATE } from "../../../../utils/logistic.init";
import { updateProduct } from "../../../../requests/product";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../../utils/toast.options";
import { useState } from "react";
import StateSelect from "../../../../components/StateSelect/StateSelect";

interface ProductCardProps {
  contact: IContact;
  product: IProduct;
  refetch: () => void;
  zIndex: number;
}

export default function ProductCard({
  contact,
  product,
  refetch,
  zIndex,
}: ProductCardProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <div
      className={style["product-card"]}
      style={{ position: "relative", zIndex: `${zIndex}` }}
    >
      <div className={style["product-card-left"]}>
        <WorkshopId
          className={style["workshop-id"]}
          size="small"
          workshopId={product.workshopId}
        />
        <StateSelect
          state={product.status || "unknown"}
          onStateChange={handleChangeProductState}
          className={style["state"]}
          list={PRODUCT_STATE}
        />
      </div>
      <div className={style["product-card-right"]}>
        <div className={style["product-group-container"]}>
          {translate(product.productGroup)}
          {" - "}
          {translate(product.operationGroup)}
        </div>
        <div className={style["product-card-right-bottom"]}>
          <div className={style["brand-container"]}>
            <label>Marque</label>
            <span className={style["brand"]}>{product.brand}</span>
          </div>
          <div className={style["contact-container"]}>
            <label>Contact</label>
            <span className={style["contact"]}>
              {!!contact.organization && <span>{contact.organization}</span>}
              <span>{contact.displayname}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  async function handleChangeProductState(newState: string) {
    setIsLoading(true);
    try {
      await updateProduct({ ...product, status: newState });
      refetch();
      toast.success("Etat mis à jour avec succès.", TOAST_SUCCESS_OPTIONS);
    } catch (error) {
      toast.error("Un problème est survenu", TOAST_ERROR_OPTIONS);
    } finally {
      setIsLoading(false);
    }
  }
}
