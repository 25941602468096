import { ITracker } from "../../../../../../types/tracker.types";
import styles from "./overviewAction.module.scss";
import { TRACKING_STATE } from "../../../../../../utils/tracking.init";
import StateSelect from "../../../../../../components/StateSelect/StateSelect";
import OverviewActionDispatch from "./OverviewActionDispatch/OverviewActionDispatch";
import MoreAction from "../../../../../../components/MoreAction/MoreAction";
import { IMoreToolsOptions } from "../../../../../../components/MoreTools/MoreTools";
import { OverviewTabs } from "../../../../../../types/overview.types";

interface OverviewActionProps {
  tracker: ITracker;
  onClosePaymentModal: () => void;
  onTrackerStateChange: (state: string) => void;
  onChangeTab: (tab: OverviewTabs) => void;
}

export default function OverviewAction({
  tracker,
  onClosePaymentModal,
  onTrackerStateChange,
  onChangeTab,
}: OverviewActionProps) {
  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      name: "Annuler la commande",
      onClick: handleOnCancelOrder,
    },
  ];

  function handleOnCancelOrder() {}

  return (
    <div className={styles["overview-action"]}>
      <div className={styles["overview-action-container"]}>
        <StateSelect
          state={tracker.state}
          onStateChange={onTrackerStateChange}
          className={styles["select-state"]}
          list={TRACKING_STATE}
          isTracker
        />
        <OverviewActionDispatch
          tracker={tracker}
          onClosePaymentModal={onClosePaymentModal}
          onChangeTab={onChangeTab}
        />
        <MoreAction options={MORE_TOOLS_OPTIONS} />
      </div>
    </div>
  );
}
