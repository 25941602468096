import { toast } from "react-toastify";
import schedulerDataFormatter, {
  ISchedulerData,
  schedulerMonthDataFormatter,
} from "../utils/formatters/schedulerData.formatter";
import { IValidationError } from "../types";
import {
  IEsdInfos,
  IExternalShipping,
  IShipping,
  IShippingPoint,
  IPickUpLocationInfo,
  IChronopostLabel,
} from "../types/shipping.types";
import { TOAST_ERROR_OPTIONS } from "../utils/toast.options";
import { AnyARecord } from "dns";

export async function addShipping(
  // request: Request,
  shipping: IShipping
): Promise<string | IValidationError[] | null> {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/shipping`, {
      headers: new Headers({ "Content-Type": "application/json" }),
      method: "POST",
      credentials: "include",
      body: JSON.stringify(shipping),
    });
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }

    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchShippingList(
  // request: Request,
  query: string
): Promise<{ totalCount: number; shippingsList: IShipping[] } | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping${query}`, //&period.start=02-05-2023
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchCalendarData(
  // request: Request,
  query: string,
  view: string
): Promise<ISchedulerData[] | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping?${query}`,
      {
        credentials: "include",
        // signal: request?.signal,
      }
    );
    const data = await response.json();
    const schedulerData =
      view === "month"
        ? schedulerMonthDataFormatter(data.shippingsList || [])
        : schedulerDataFormatter(data.shippingsList || []);
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return schedulerData;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchShipping(
  // request: Request,
  params: any
): Promise<IShipping | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping/${params.id}`,
      {
        credentials: "include",
        // signal: request?.signal,
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getShipping(id: string): Promise<IShipping | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping/${id}`,
      {
        credentials: "include",
        // signal: request?.signal,
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateShipping(
  shipping: IShipping,
  updateQuote: boolean = false
): Promise<string | IValidationError[] | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping/updateShipping/${shipping.id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ shipping, updateQuote }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateShippingState(
  // request: Request,
  id: string,
  state: string
): Promise<{ id: string } | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping/updateState/${id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "PUT",
        credentials: "include",
        // signal: request?.signal,
        body: JSON.stringify({ state: state }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateShippingStateMutation({
  id,
  state,
}: {
  id: string;
  state: string;
}): Promise<{ id: string } | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping/updateState/${id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "PUT",
        credentials: "include",
        // signal: request?.signal,
        body: JSON.stringify({ state: state }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getPickUpLocation(
  shipping: IShippingPoint,
  date: Date,
  shippingService?: string
): Promise<IPickUpLocationInfo[]> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping/getPickUpLocation`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          shipping: shipping,
          date: date,
          shippingService: shippingService || "CHRONOPOST-2SHOP",
        }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.pickupLocationList;
  } catch (error: any) {
    toast.error(JSON.stringify(error.json().body), TOAST_ERROR_OPTIONS);
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getAvailableShippingTimeSlots(
  date: string
): Promise<number[] | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping/getAvailableShippingTimeSlots?date=${date}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function reserveShippingLabel(
  // request: Request,
  id: string
): Promise<IExternalShipping | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping/reserveShippingLabel/${id}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    toast.error("Un probleme est survenu.", TOAST_ERROR_OPTIONS);
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function reserveShippingLabelMutation({
  id,
  shippingService,
  mode,
}: {
  id: string;
  shippingService: string;
  mode: string;
}): Promise<any> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping/reserveShippingLabel/${id}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return { data, shippingService, mode };
  } catch (error: any) {
    toast.error("Un probleme est survenu.", TOAST_ERROR_OPTIONS);
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function reserveShippingEsdLabel(
  // request: Request,
  id: string,
  shipping: IShipping
): Promise<IExternalShipping | null> {
  if (!shipping.period || !shipping.sender) {
    return null;
  }
  const esdInfo: IEsdInfos = {
    retrievalDateTime: new Date(shipping.period.start).toISOString(),
    closingDateTime: new Date(shipping.period.end).toISOString(),
    shipperBuildingFloor: shipping.sender.comments || "",
    shipperCarriesCode: shipping.sender.comments || "",
    shipperServiceDirection: "INCOMING",
    specificInstructions: shipping.sender.comments || "",
    refEsdClient: "",
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping/reserveShippingEsdLabel/${id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        // signal: request?.signal,
        body: JSON.stringify(esdInfo),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    toast.error("Un probleme est survenu.", TOAST_ERROR_OPTIONS);
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function cancelShippingLabel(
  // request: Request,
  id: string
): Promise<string | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping/cancelShippingLabel/${id}`,
      {
        credentials: "include",
        // signal: request?.signal,
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    toast.error(
      "Un problème est survenu lors de l'annulation du bon Chronppost",
      TOAST_ERROR_OPTIONS
    );
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getShippingLabel(
  // request: Request,
  shippingService: string,
  reservationNumber: string,
  mode: string
): Promise<Buffer | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping/getShippingLabel`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        // signal: request?.signal,
        body: JSON.stringify({
          shippingService: shippingService,
          reservationNumber: reservationNumber,
          mode: mode,
        }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    // toast.error("Un problème est survenue.", TOAST_ERROR_OPTIONS);
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getShippingLabelMutation({
  shippingService,
  reservationNumber,
  mode,
}: {
  shippingService: string;
  reservationNumber: string;
  mode: string;
}): Promise<any> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping/getShippingLabel`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        // signal: request?.signal,
        body: JSON.stringify({
          shippingService: shippingService,
          reservationNumber: reservationNumber,
          mode: mode,
        }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return { data, mode, reservationNumber };
  } catch (error: any) {
    // toast.error("Un problème est survenue.", TOAST_ERROR_OPTIONS);
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function reserveChronopostLabel(chronopost: IChronopostLabel) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping/reserve-chronopost-label`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        body: JSON.stringify(chronopost),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    toast.error("Un problème est survenue.", TOAST_ERROR_OPTIONS);
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function cancelChronopostLabel(skybillNumber: string) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping/cancel-chronopost-label/${skybillNumber}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    toast.error("Un problème est survenue.", TOAST_ERROR_OPTIONS);
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function createShippingAndChronopostLabel(
  shipping: IShipping
): Promise<{ shipping: IShipping }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping/create-shipping-and-chronopost`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        body: JSON.stringify(shipping),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    toast.error("Un problème est survenue.", TOAST_ERROR_OPTIONS);
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
