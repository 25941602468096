import clsx from "clsx";
import Image from "../../../../../image/Image";
import styles from "./productImageList.module.scss";

interface ProductImageListProps {
  imagesList: string[];
  className?: string;
}

export default function ProductImageList({
  imagesList,
  className,
}: ProductImageListProps) {
  const smallImageList = imagesList.filter((_, i) => i != 0 && i <= 3);
  const otherImageListNumber = imagesList.filter((_, i) => i > 3).length;

  if (imagesList.length === 0) {
    return <></>;
  }

  return (
    <div className={clsx(styles["picture-container"], className)}>
      <Image
        className={styles["main-picture"]}
        src={imagesList[0]}
        index={0}
        pictureList={imagesList}
        showTools={false}
        clickable
      />
      <div className={styles["other-picture-list"]}>
        {smallImageList.map((images, index) => (
          <Image
            className={styles["other-picture"]}
            src={images}
            index={index}
            pictureList={imagesList}
          />
        ))}
        {!!otherImageListNumber && (
          <div
            className={styles["more-picture"]}
          >{`+ ${otherImageListNumber}`}</div>
        )}
      </div>
    </div>
  );
}
