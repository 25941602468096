import { Referral } from "../types/referral.types";

export const REFERRAL_STATE = [
  "DRAFT",
  "PENDING",
  "TO_BE_CLAIMED",
  "COMPLETED",
  "CANCELED",
];

export const REFERRAL_DEFAULT: Referral = {
  referralSource: "",
  referredEmail: "",
  state: "DRAFT",
};
