import { useQuery } from "react-query";
import style from "./logisticModal.module.scss";
import { fetchLogistic } from "../../../requests/logistic";
import { useState } from "react";
import LoadingSpinner from "../../loadingSpinner/loadingSpinner";
import LogisticReadSection from "./logisticReadSection/logisticReadSection";
import LogisticActionSection from "./logisticActionSection/logisticActionSection";
import LogisticModalHeader from "./logisticModalHeader/LogisticModalHeader";

interface LogisticModalProps {
  dueDate?: Date;
  id: string;
  requiredActions: boolean;
  onToolSelect?: () => void;
  productIndex?: number;
}

export default function LogisticModal({
  dueDate,
  id,
  requiredActions,
  onToolSelect,
  productIndex,
}: LogisticModalProps) {
  const { data, isLoading } = useQuery(
    ["logistic-modal", id],
    () => fetchLogistic({ params: { id: id } }),
    { refetchOnWindowFocus: false, keepPreviousData: true }
  );
  const [selectedTab, setSelectedTab] = useState<"read" | "action">(
    requiredActions ? "action" : "read"
  );

  if (isLoading) {
    return (
      <div className={style["logistic-modal"]}>
        <LoadingSpinner color="gold" />
      </div>
    );
  }

  if (!data) {
    return <div className={style["logistic-modal"]}>Données indisponibles</div>;
  }

  return (
    <div className={style["logistic-modal"]}>
      <div className={style["logistic-modal-container"]}>
        <LogisticModalHeader
          data={data}
          requiredActions={requiredActions}
          selectedTab={selectedTab}
          onToolSelect={onToolSelect}
          onTabChange={handleChangeTab}
        />
        <LogisticTabDispatch onActionValidated={onToolSelect} />
      </div>
    </div>
  );

  function LogisticTabDispatch({
    onActionValidated,
  }: {
    onActionValidated?: () => void;
  }) {
    if (!data) {
      return <LoadingSpinner color="gold" />;
    }
    switch (selectedTab) {
      case "read":
        return <LogisticReadSection productIndex={productIndex} data={data} />;
      case "action":
        return (
          <LogisticActionSection
            data={data}
            dueDate={dueDate}
            onActionValidated={onActionValidated}
          />
        );
      default:
        return <LogisticReadSection productIndex={productIndex} data={data} />;
    }
  }

  function handleChangeTab(tab: "read" | "action") {
    setSelectedTab(tab);
  }
}
