import clsx from "clsx";
import styles from "./listHeader.module.scss";

interface ListHeaderProps {}

export default function ListHeader({}: ListHeaderProps) {
  return (
    <thead className={styles["list-header"]}>
      <tr className={styles["list-header-row"]}>
        <th className={styles["list-header-cell"]}></th>
        <th className={styles["list-header-cell"]}>Description</th>
        <th className={clsx(styles["list-header-cell"], styles["center-cell"])}>
          Groupe d'articles
        </th>
        <th className={clsx(styles["list-header-cell"], styles["center-cell"])}>
          Type d'opérations
        </th>
        <th className={clsx(styles["list-header-cell"], styles["center-cell"])}>
          Prix HT
        </th>
        <th className={clsx(styles["list-header-cell"], styles["center-cell"])}>
          Prix TTC
        </th>
        <th className={clsx(styles["list-header-cell"], styles["center-cell"])}>
          B2B Base HT
        </th>
        <th className={clsx(styles["list-header-cell"], styles["center-cell"])}>
          B2B Base TTC
        </th>
        <th className={clsx(styles["list-header-cell"], styles["center-cell"])}>
          B2B Luxe HT
        </th>
        <th className={clsx(styles["list-header-cell"], styles["center-cell"])}>
          B2B Luxe TTC
        </th>
        <th className={clsx(styles["list-header-cell"], styles["center-cell"])}>
          Actions
        </th>
      </tr>
    </thead>
  );
}
