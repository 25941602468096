import { IDiscount } from "../../../types/accounting.types";
import style from "./item.module.scss";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import { Delete } from "@mui/icons-material";
import DiscountValue from "../../../components/DiscountValue/DiscountValue";

export default function Item({
  discount,
  onDelete,
}: {
  discount: IDiscount;
  onDelete: (id: string) => void;
}) {
  return (
    <div className={style["item"]}>
      <div className={style["item-element"]}>{discount.code}</div>
      <DiscountValue className={style["item-element"]} discount={discount} />
      <div className={style["item-element"]}>
        {new Date(discount?.expireAt as Date)?.toLocaleDateString("fr-FR") ||
          ""}
      </div>
      <div className={style["item-element"]}>{discount.baseQuantity}</div>
      <div className={style["item-element"]}>{discount.quantity}</div>
      <div className={`${style["item-element"]} ${style["tools"]}`}>
        <ProtectedComponent roles={["ROLE_ADMIN"]}>
          <Delete
            className={`${style["icon"]} ${style["delete-icon"]}`}
            onClick={handleDelete}
          />
        </ProtectedComponent>
      </div>
    </div>
  );

  function handleDelete() {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce CODE PROMO ?")) {
      onDelete(discount.code);
    }
  }
}
