import Widget from "../Widget";
import { LocalShippingOutlined } from "@mui/icons-material";
import style from "./proShipping.module.scss";

export default function ProShipping() {
  return (
    <Widget to={"pro-shipping"}>
      <div className={style["icon-container"]}>
        <LocalShippingOutlined className={style["icon"]} />
      </div>
      <div>Livraison/Collecte PRO</div>
    </Widget>
  );
}
