import { useState } from "react";
import style from "./input.module.scss";
import { ICustomer } from "../../../types/customer.type";
import ContactAutoComplete from "./contactAutoComplete/ContactAutoComplete";
import translate from "../../../utils/translation";

interface InputProps {
  autoCompleteDisable?: boolean;
  dataList?: string[];
  label?: string;
  handleAutoComplete?: (customer: ICustomer | null) => void;
  ref?: React.LegacyRef<HTMLInputElement> | undefined;
}

export default function Input({
  autoCompleteDisable = false,
  dataList,
  label,
  handleAutoComplete,
  ref,
  ...props
}: InputProps & React.InputHTMLAttributes<HTMLInputElement>) {
  const [expand, setExpand] = useState<boolean>(false);

  return (
    <div className={`${style["input-container"]} ${props.className || ""}`}>
      {label && <label htmlFor={props.name}>{label}</label>}
      <input
        autoComplete={handleAutoComplete ? "off" : "on"}
        className={`${style["input"]} ${
          props.disabled ? style["disabled"] : ""
        }`}
        list={`input-datalist-${props.name}`}
        {...props}
        value={handleFormatValue(props)}
        onChange={handleOnChange}
        ref={ref}
      />
      {handleAutoComplete && (
        <ContactAutoComplete
          name={props.name}
          value={props.value}
          expand={expand}
          setExpand={setExpand}
          handleAutoComplete={handleAutoComplete}
          autoCompleteDisable={autoCompleteDisable}
        />
      )}
      {dataList && (
        <datalist id={`input-datalist-${props.name}`}>
          {dataList.map((item: string, index: number) => {
            return <option key={index}>{translate(item)}</option>;
          })}
        </datalist>
      )}
    </div>
  );

  function handleFormatValue(
    props: React.InputHTMLAttributes<HTMLInputElement>
  ) {
    const formatableInputNames = [
      "value",
      "price",
      "amount",
      "freeShippingMinimumAmount",
      "internationalFees",
      "shipping.price",
    ];
    if (!!props.name && formatableInputNames.includes(props.name)) {
      if (typeof props.value === "string") {
        if (!props.value || isNaN(parseFloat(props.value))) {
          return "";
        }
        return parseFloat(props.value) == 0
          ? ""
          : parseFloat(props.value) / 100;
      }
      if (typeof props.value === "number") {
        if (!props.value || isNaN(props.value)) {
          return "";
        }
        return props.value === 0 ? "" : props.value / 100;
      }
    }
    return props.value;
  }

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    props.value !== "" && !autoCompleteDisable
      ? setExpand(true)
      : setExpand(false);
    props.onChange && props.onChange(e);
  }
}
