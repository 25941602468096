import clsx from "clsx";
import CenterCell from "../../../../CenterCell/CenterCell";
import styles from "./tableHeader.module.scss";

export default function TableHeader() {
  return (
    <thead className={styles["list-header"]}>
      <tr className={clsx(styles["list-header-row"], styles["first-header"])}>
        <th className={styles["list-header-cell"]} colSpan={2}></th>
        <th className={styles["list-header-cell"]} colSpan={2}>
          <CenterCell>B2B Standard</CenterCell>
        </th>
        <th className={styles["list-header-cell"]} colSpan={2}>
          <CenterCell>B2B Luxe</CenterCell>
        </th>
      </tr>
      <tr className={styles["list-header-row"]}>
        <th className={styles["list-header-cell"]}></th>
        <th className={styles["list-header-cell"]}>Description</th>
        <th className={styles["list-header-cell"]}>
          <CenterCell>TTc</CenterCell>
        </th>
        <th className={styles["list-header-cell"]}>
          <CenterCell>HT</CenterCell>
        </th>
        <th className={styles["list-header-cell"]}>
          <CenterCell>TTc</CenterCell>
        </th>
        <th className={styles["list-header-cell"]}>
          <CenterCell>HT</CenterCell>
        </th>
      </tr>
    </thead>
  );
}
