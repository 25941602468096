import { IValidationError } from "../types";
import { IEstimate } from "../types/booking.type";

export async function fetchEstimate({
  // request,
  params,
}: {
  // request: Request;
  params: any;
}): Promise<IEstimate> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/estimate/${params.id}`,
      {
        credentials: "include",
        // signal: request?.signal,
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.estimate;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getEstimate(id: string): Promise<IEstimate> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/estimate/${id}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.estimate;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchEstimatesList(
  query?: string
): Promise<{ estimatesList: IEstimate[]; totalCount: number }> {
  let queryUrl = query ? query : "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/estimate` + queryUrl,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function postEstimate(
  estimate: IEstimate,
  addOrUpdateQuote: boolean = false,
  doSendEmail: boolean = false,
  customerId?: string,
  mailBody?: string
): Promise<{ estimate: IEstimate } | IValidationError[] | null> {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/estimate`, {
      headers: new Headers({ "Content-Type": "application/json" }),
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        estimate,
        addOrUpdateQuote,
        doSendEmail,
        customerId,
        mailBody,
      }),
    });
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateEstimate(
  estimate: IEstimate,
  addOrUpdateQuote: boolean = false,
  doSendEmail: boolean = false,
  customerId?: string,
  mailBody?: string
): Promise<{ estimate: IEstimate } | IValidationError[] | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/estimate/${estimate.id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "include",
        method: "PUT",
        body: JSON.stringify({
          estimate,
          addOrUpdateQuote,
          doSendEmail,
          customerId,
          mailBody,
        }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function uploadImages(
  estimateId: string,
  files: File[],
  signal?: AbortSignal
): Promise<void> {
  try {
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append("files", file);
    });
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/estimate/${estimateId}/upload`,
      {
        credentials: "include",
        method: "POST",
        signal: signal,
        body: formData,
      }
    );
    if (response.status >= 400) {
      throw new Response(JSON.stringify(response), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function deleteEstimate(
  id?: string,
  signal?: AbortSignal
): Promise<void> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/estimate/${id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "DELETE",
        signal: signal,
        credentials: "include",
      }
    );

    if (response.status >= 400) {
      throw new Response(JSON.stringify(response), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
