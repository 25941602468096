import { FormEvent, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./create.module.scss";
import { IAddress, ICustomer } from "../../../types/customer.type";
import { Check, Close } from "@mui/icons-material";
import { addCustomer, updateCustomer } from "../../../requests/customer";
import Button from "../../../components/Button/Button";
import ContactForm from "../../../components/forms/ContactForm/ContactForm";
import SectionFormTemplate from "../../../components/forms/sectionFormTemplate/SectionFormTemplate";
import { TOAST_ERROR_OPTIONS } from "../../../utils/toast.options";
import { toast } from "react-toastify";
import { IContact } from "../../../types/logistic.types";
import AddressesForm from "../_components/AddressesForm/AddressesForm";
import Referral from "../_components/Referral/Referral";
import Orders from "../_components/Orders/Orders";

export const INITIAL_CUSTOMER: ICustomer = {
  familyname: "",
  givenname: "",
  phone: "",
  email: "",
  verified: false,
  addresses: [],
};

export const INITIAL_ADDRESS: IAddress = {
  address1: "",
  address2: "",
  zipCode: "",
  countryCode: "FR",
  city: "",
  country: "France",
};

export default function Create({ data }: { data?: ICustomer }) {
  const [formValues, setFormValues] = useState<ICustomer>(
    data || INITIAL_CUSTOMER
  );

  const ref = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      const response = data
        ? await updateCustomer(formValues, data.id)
        : await addCustomer(formValues);

      navigate("../");
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
  }

  return (
    <div className={styles["customer"]}>
      <div ref={ref} className={styles["title"]}>
        {data ? "Modifier un client" : "Nouveau client"}
        {formValues.verified ? (
          <Check className={styles["icon"]} />
        ) : (
          <Close className={styles["icon"]} />
        )}
      </div>
      <form className={styles["form"]} onSubmit={handleSubmit}>
        <SectionFormTemplate title="Informations personnelles">
          <ContactForm
            contact={formatContact()}
            setContact={handleContactChange}
            className={styles["contact-form"]}
            detailedForm={false}
          />
        </SectionFormTemplate>
        <SectionFormTemplate title="Adresses">
          <AddressesForm
            customer={formValues}
            onAddressesChange={handleOnAddressesChange}
          />
        </SectionFormTemplate>
        <SectionFormTemplate
          title={`Commandes (${(formValues?.trackers || []).length})`}
        >
          <Orders
            trackers={formValues?.trackers || []}
            onTrackersChange={handleOnChangeTrackers}
          />
        </SectionFormTemplate>
        <SectionFormTemplate title="Parrainage">
          <Referral
            customer={formValues}
            onBalanceChange={handleOnBalanceChange}
          />
        </SectionFormTemplate>
        <div className={styles["submit-button-container"]}>
          <Button type={"submit"}>{data ? "Mettre à jour" : "Créer"}</Button>
        </div>
      </form>
    </div>
  );

  function handleContactChange(contact: IContact) {
    setFormValues((prev) => ({
      ...prev,
      familyname: contact.familyname,
      givenname: contact.givenname,
      phone: contact.phone,
      email: contact.email,
    }));
  }

  function handleOnAddressesChange(addresses: IAddress[]) {
    setFormValues((prev) => ({ ...prev, addresses }));
  }

  function handleOnBalanceChange(balance: number) {
    setFormValues((prev) => ({ ...prev, balance }));
  }

  function formatContact() {
    return {
      familyname: formValues.familyname,
      givenname: formValues.givenname,
      phone: formValues.phone,
      email: formValues.email,
    };
  }

  function handleOnChangeTrackers(trackers: string[]) {
    setFormValues((prev) => ({ ...prev, trackers }));
  }
}
