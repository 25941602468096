import { SyntheticEvent } from "react";
import { COLOR_LIST } from "./translation/color";
import { IValidationError } from "../types";
import DEFAULT_PICTURE from "../assets/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg";
import { IOperation } from "../types/accounting.types";
import { useLocation } from "react-router-dom";

export function findCountyFromCountryCode(countryCode: string): string {
  let country = "FRANCE";
  switch (countryCode) {
    case "FR":
      country = "FRANCE";
      break;
    case "IT":
      country = "ITALIE";
      break;
    case "BE":
      country = "BELGIQUE";
      break;
    case "CH":
      country = "SUISSE";
      break;
    case "LU":
      country = "LUXEMBOURG";
      break;
    case "MC":
      country = "MONACO";
      break;
    case "AT":
      country = "AUTRICHE";
      break;
    case "DK":
      country = "DANEMARK";
      break;
    default:
      break;
  }
  return country;
}

export function extractStringsByKey(arr: string[], key: string): string[] {
  const result = arr
    .filter((str) => str.startsWith(key))
    .map((str) => str.substring(str.indexOf(".") + 1));

  return result;
}

export function extractBacktickStrings(input: string): string[] {
  const regex = /`([^`]+)`/g;
  const matches = input.match(regex);
  if (!matches) {
    return [];
  }

  const result = matches.map((match) => match.slice(1, -1));
  return result;
}

export function scrollToElement(element: HTMLElement | null, duration: number) {
  if (element) {
    const targetPosition =
      element.getBoundingClientRect().top + window.pageYOffset - 60;
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    let startTime: number | null = null;

    const animation = (currentTime: number) => {
      if (!startTime) startTime = currentTime;
      const elapsedTime = currentTime - startTime;
      const scrollProgress = Math.min(elapsedTime / duration, 1);
      const easing = easeOutQuad(scrollProgress);
      const scrollOffset = startPosition + distance * easing;

      window.scrollTo(0, scrollOffset);

      if (scrollProgress < 1) {
        window.requestAnimationFrame(animation);
      }
    };

    const easeOutQuad = (t: number) => t * (2 - t);

    window.requestAnimationFrame(animation);
  }
}

export function extractKeyFields(
  arr: IValidationError[],
  key: string
): [number, string][] {
  const regex = new RegExp(`${key}\\[(\\d+)\\]\\.?(.*)`);

  const extractedFields: [number, string][] = [];
  arr.forEach((str: IValidationError) => {
    const match = str.field.match(regex);
    if (match && match[1] && match[2]) {
      const index = parseInt(match[1]);
      extractedFields.push([index, match[2]]);
    }
  });

  return extractedFields;
}

export function parseWorkshopId(inputString: string) {
  const trimmedString =
    inputString.length === 8
      ? inputString.slice(2, inputString.length - 1)
      : inputString.slice(2, inputString.length);

  // Use a regular expression to capture the digits in groups
  const match = trimmedString.match(/(\d{1})(\d{1})(\d{3})/);

  if (match && match.length === 4) {
    // Construct the transformed string with the desired format
    const [, digit1, digit2, digits3] = match;
    const transformedString = `${digit1}-${digit2}-${digits3}`;
    return transformedString;
  }

  // Return null if the input doesn't match the expected pattern
  return inputString;
}

export function isValidJSONString(str: string) {
  try {
    JSON.parse(str);
    return true; // The string is valid JSON
  } catch (e) {
    return false; // The string is not valid JSON
  }
}

export function getColorCode(color: string | undefined): string {
  const colorCode = COLOR_LIST.find(
    (value: any) => color === value.name
  )?.color;
  return colorCode || "#FFFFFF";
}

export function handleImageError(
  event: SyntheticEvent<HTMLImageElement, Event>
) {
  const target = event.target as HTMLImageElement;
  target.src = DEFAULT_PICTURE;
}

export function ImageApiPath(fileName: string | undefined) {
  if (!fileName) {
    return "";
  }
  if (fileName.startsWith("https://api.typeform.com")) {
    return fileName;
  }
  return `${process.env.REACT_APP_API_URL}/file/${fileName}`;
}

export function parseValidationError(
  validationError: IValidationError[] | undefined
) {
  if (!validationError) {
    return;
  }
  let errorString: string = "";

  validationError.forEach((ve: IValidationError, index: number) => {
    errorString += ve.field;
    if (index < validationError.length) {
      errorString += " ";
    }
  });

  return errorString;
}

export function sortOperations(a: IOperation, b: IOperation) {
  if (a.canceled && !b.canceled) {
    return 1;
  }
  if (!a.canceled && b.canceled) {
    return -1;
  }
  return 0;
}

export function dateDiffInDays(a: Date, b: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function addTimestampToFilename(filename: string) {
  const now = new Date();
  return (
    now.getFullYear() +
    String(now.getMonth() + 1).padStart(2, "0") +
    String(now.getDate()).padStart(2, "0") +
    "_" +
    String(now.getHours()).padStart(2, "0") +
    String(now.getMinutes()).padStart(2, "0") +
    String(now.getSeconds()).padStart(2, "0") +
    "_" +
    generateRandomString() +
    filename
  );
}

export function generateRandomString(length: number = 4): string {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
}

const navbarPathTitleMap = new Map<string, string[]>([
  ["Vue d'ensemble", ["overview"]],
  ["Accès rapide", ["quick-access"]],
  ["Atelier", ["logistic"]],
  ["Dashboard", ["dashboard"]],
  ["Rapports", ["reports"]],

  //Pro
  ["Licenses", ["licenses"]],
  ["Boutiques", ["shops"]],
  ["Demandes Pro", ["professionalCarts"]],
  ["Factures", ["invoice-pro"]],
  ["Boutiques", ["shops"]],
  [
    "Professionels",
    ["licenses", "shops", "professionalCarts", "invoice-pro", "shops"],
  ],

  //Document
  ["Devis", ["quotes"]],
  ["Paiements", ["payments"]],
  ["Comptes clients", ["customers"]],
  ["Parrainage", ["referral"]],
  ["Documents", ["quotes", "payments", "customers", "referral"]],

  //Gestion
  ["Opérations", ["operation-b2c"]],
  ["Opérations b2b", ["operation-pro"]],
  ["Cross-selling", ["cross-selling"]],
  ["Méthodes de livraisons", ["shipping-method"]],
  ["Codes promos", ["discount"]],
  ["Gestion d'utilisateurs", ["users"]],
  [
    "Gestion",
    [
      "operation",
      "operation-pro",
      "cross-selling",
      "users",
      "discount",
      "shipping-method",
    ],
  ],

  //Demandes
  ["Estimations", ["estimate"]],
  ["Paniers", ["carts"]],
  ["Demandes", ["estimate", "carts"]],

  //Calendrier
  ["Livraison & collecte", ["shipping-calendar"]],
  ["Chronotrace", ["chronotrace"]],
  ["Calendriers", ["shipping-calendar", "chronotrace"]],
]);

export function useGetPath(title: string) {
  const { pathname } = useLocation();
  if (!title) {
    return "";
  }
  if (pathname === "/" && title === "Dashboard") {
    return true;
  }
  const relativePath = navbarPathTitleMap.get(title);
  if (!relativePath) {
    return false;
  }
  return relativePath.some((path) => pathname.includes(path));
}
