import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../errorPage/error-page";
import { authloader } from "../../routes";
import CrossSell from ".";
import Create from "./create/create";
import Edit from "./edit/edit";
import { loader as editLoader } from "./edit/edit";

export const route: RouteObject = {
  path: "/cross-selling",
  element: <Outlet />,
  loader: authloader,
  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <CrossSell />,
      loader: authloader,
    },
    {
      path: "create",
      element: <Create />,
      loader: authloader,
    },
    {
      path: "edit/:slug",
      element: <Edit />,
      loader: editLoader,
    },
  ],
};
