import style from "./productCard.module.scss";
import { Link } from "react-router-dom";
import { IContact, IProduct } from "../../../../types/logistic.types";
import translate from "../../../../utils/translation";
import WorkshopId from "../../../../components/workshopId/workshopId";
import State from "../../../../components/State/State";

interface LinkedProductCardProps {
  contact: IContact;
  product: IProduct;
  to: string;
}

export default function LinkedProductCard({
  contact,
  product,
  to,
}: LinkedProductCardProps) {
  return (
    <Link to={to} className={style["product-card"]}>
      <div className={style["product-card-left"]}>
        <WorkshopId
          className={style["workshop-id"]}
          size="small"
          workshopId={product.workshopId}
        />
        <State state={product.status} className={style["state"]} />
      </div>
      <div className={style["product-card-right"]}>
        <div className={style["product-group-container"]}>
          {translate(product.productGroup)}
          {" - "}
          {translate(product.operationGroup)}
        </div>
        <div className={style["product-card-right-bottom"]}>
          <div className={style["brand-container"]}>
            <label>Marque</label>
            <span className={style["brand"]}>{product.brand}</span>
          </div>
          <div className={style["contact-container"]}>
            <label>Contact</label>
            <span className={style["contact"]}>
              {!!contact.organization && <span>{contact.organization}</span>}
              <span>{contact.displayname}</span>
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
}
