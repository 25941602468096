import { useLoaderData } from "react-router-dom";
import { authloader } from "../../../routes";
import useLogout from "../../../hooks/useLogout";
import { loadCart } from "../../../requests/tracker";
import { ICart } from "../../../types/booking.type";
import Create from "../create/create";
import style from "./generate.module.scss";

export async function loader({ params }: { params: any }) {
  try {
    const cart = await loadCart({ params });
    authloader();
    return cart;
  } catch (error: any) {
    throw error;
  }
}

export default function Generate() {
  const data: { cart: ICart } | null = useLoaderData() as { cart: ICart };

  useLogout(data);
  return (
    <div className={style.editPage}>{data && <Create data={data?.cart} />}</div>
  );
}
