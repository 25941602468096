import { IShop } from "../../../types/shop.types";
import styles from "./pricingModal.module.scss";
import { OperationForm } from "../../../types/accounting.types";
import OperationTable from "./OperationTable/OperationTable";
import { useState } from "react";
import RadioButton from "../../RadioButton/RadioButton";

interface PricingModalProps {
  className?: string;
  shopForm: IShop;
  setShopForm: (value: IShop) => void;
}

export default function PricingModal({
  className,
  shopForm,
  setShopForm,
}: PricingModalProps) {
  const [proCategory, setProCategory] = useState<"base" | "luxe">("base");

  return (
    <div className={`${styles["pricing-modal"]} ${className}`}>
      <div className={styles["pricing-modal-container"]}>
        <div className={styles["title"]}>
          Choisissez les opérations
          <span>{`(${shopForm.pricing.operationsList.length} sélectionnés)`}</span>
        </div>
        <div className={styles["category"]}>
          <RadioButton
            label="Standard"
            id="standard"
            checked={proCategory === "base"}
            onClick={() => setProCategory("base")}
            className={styles["radio-button"]}
          />
          <RadioButton
            label="Luxe"
            id="luxe"
            checked={proCategory === "luxe"}
            onClick={() => setProCategory("luxe")}
            className={styles["radio-button"]}
          />
        </div>
        <OperationTable
          onOperationSelect={handleSelectOperationHybrid}
          operationsList={shopForm.pricing.operationsList}
          proCategory={proCategory}
        />
      </div>
    </div>
  );

  function handleSelectOperationHybrid(
    operation: OperationForm[] | OperationForm,
    operationGroup?: string
  ) {
    if (operationGroup) {
      return setShopForm({
        ...shopForm,
        pricing: {
          operationsList: shopForm.pricing.operationsList.filter(
            (op) => op.operationGroup !== operationGroup
          ),
        },
      });
    }
    if (Array.isArray(operation)) {
      handleSelectOperationsList(operation);
    } else {
      handleSelectOperation(operation as OperationForm);
    }
  }

  function handleSelectOperation(operation: OperationForm) {
    const found = shopForm.pricing.operationsList.find(
      (op) => op.slug === operation.slug
    );
    if (!!found) {
      return setShopForm({
        ...shopForm,
        pricing: {
          operationsList: shopForm.pricing.operationsList.filter(
            (op) => op.slug !== operation.slug
          ),
        },
      });
    }
    setShopForm({
      ...shopForm,
      pricing: {
        operationsList: [
          ...shopForm.pricing.operationsList,
          formatShopOperation(operation as OperationForm, proCategory),
        ],
      },
    });
  }

  function handleSelectOperationsList(operationsList: OperationForm[]) {
    const newOperationsMap = new Map();

    operationsList.forEach((item) => {
      newOperationsMap.set(item.slug, item);
    });

    for (let op of shopForm.pricing.operationsList) {
      if (!newOperationsMap.has(op.slug)) {
        newOperationsMap.set(op.slug, op);
      }
    }

    setShopForm({
      ...shopForm,
      pricing: {
        operationsList: Array.from(newOperationsMap.values()).map((op) =>
          formatShopOperation(op, proCategory)
        ),
      },
    });
  }

  function formatShopOperation(
    operation: OperationForm,
    type: "base" | "luxe"
  ) {
    if (!operation.priceProBase || !operation.priceProLuxe) {
      return operation;
    }
    const price =
      type === "base" ? operation.priceProBase : operation.priceProLuxe;
    const priceNoTax =
      type === "base"
        ? operation.priceProBaseNoTax
        : operation.priceProLuxeNoTax;

    return {
      ...operation,
      description: operation.name,
      shopPrice: price,
      shopPriceNoTax: priceNoTax,
      finalPrice: price,
      finalPriceNoTax: priceNoTax,
    };
  }
}
