import { FormEvent, useState } from "react";
import SelectState from "../../../pages/shipping/create/SelectState/SelectState";
import { CART_STATE, DEFAULT_PRO_CART } from "../../../utils/booking.init";
import style from "./addNewCartProModal.module.scss";
import { IProCart, IProduct } from "../../../types/proCart.types";
import SectionFormTemplate from "../../forms/sectionFormTemplate/SectionFormTemplate";
import ContactForm from "../../forms/ContactForm/ContactForm";
import { IContact } from "../../../types/logistic.types";
import { IContact as IProContact } from "../../../types/proCart.types";
import Button from "../../Button/Button";
import AddProductSection from "./addProductSection/AddProductSection";
import { addProCart } from "../../../requests/professionalCarts";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import { useQuery } from "react-query";
import { getShop } from "../../../requests/shops";

interface AddNewCartProModalProps {
  shopId: string;
  postSubmit: () => void;
}

export default function AddNewCartProModal({
  shopId,
  postSubmit,
}: AddNewCartProModalProps) {
  const [formValues, setFormValues] = useState<IProCart>({
    ...DEFAULT_PRO_CART,
    shopId,
  });

  const { data: shop } = useQuery(["get-shop", shopId], () => getShop(shopId));

  return (
    <div className={style["add-new-cart-pro-modal"]}>
      <div className={style["add-new-cart-pro-modal-top"]}>
        <div className={style["add-new-cart-pro-header"]}>
          <h1>Ajouter nouvelle demande</h1>
          <SelectState
            label="Status de la demande"
            state={formValues.state}
            stateOption={CART_STATE}
            setState={(state) => {
              setFormValues({ ...formValues, state });
            }}
          />
        </div>
        <div>
          <SectionFormTemplate title={"Informations contact"} collapsedDefault>
            <ContactForm
              autoComplete={false}
              contact={formValues.contact}
              setContact={handleContactChange}
            />
          </SectionFormTemplate>
          <SectionFormTemplate title={"Référence interne"} collapsedDefault>
            <input
              value={formValues.customRef}
              onChange={({ target }) =>
                setFormValues((prev) => ({ ...prev, customRef: target.value }))
              }
            />
          </SectionFormTemplate>
          <SectionFormTemplate title={"Commentaire"} collapsedDefault>
            <textarea
              className={style["comment"]}
              value={formValues.comments}
              onChange={({ target }) =>
                setFormValues((prev) => ({ ...prev, comments: target.value }))
              }
            />
          </SectionFormTemplate>
          <hr className={style["separator"]} />
          <AddProductSection
            shopRef={shop?.workshopPrefixId || ""}
            shopId={shopId}
            productsList={formValues.productsList}
            setProductsList={handleSetProductsList}
          />
        </div>
      </div>
      <div className={style["add-new-cart-pro-modal-footer"]}>
        <Button onClick={handleSubmit}>{"Valider"}</Button>
      </div>
    </div>
  );

  function handleContactChange(contact: IContact) {
    setFormValues((prev) => ({ ...prev, contact: contact as IProContact }));
  }

  function handleSetProductsList(productsList: IProduct[]) {
    setFormValues((prev) => ({ ...prev, productsList }));
  }

  function verifyForm() {
    return (
      formValues.productsList &&
      formValues.productsList.length > 0 &&
      formValues.productsList.every(
        (p) => p.workshopId && p.operationsList && p.operationsList.length > 0
      )
    );
  }

  async function handleSubmit() {
    if (!verifyForm()) {
      return toast.error(
        "Veuillez remplir les champs obligatoires et renseigner au moins 1 article et 1 opération.",
        TOAST_ERROR_OPTIONS
      );
    }
    try {
      await addProCart(formValues);
      toast.success("Demande créee avec succès.", TOAST_SUCCESS_OPTIONS);
      postSubmit();
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
  }
}
