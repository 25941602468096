import { useState } from "react";
import style from "./downloadLabelButton.module.scss";
import useClickOutside from "../../../../hooks/useClickOutSide";
import { PRINTMODE } from "../../../../utils/shipping.init";
import { isDesktop } from "react-device-detect";
import ValidateButton from "../../../../components/validateButton/validateButton";
import { getShippingLabel } from "../../../../requests/shipping";
import { IShipping } from "../../../../types/shipping.types";

interface DownloadLabelProps {
  shipping: IShipping;
}

export default function DownloadLabelButton({ shipping }: DownloadLabelProps) {
  const [expand, setExpand] = useState<boolean>(false);
  const ref = useClickOutside<HTMLDivElement>(() => setExpand(false));

  return (
    <div className={style["download-container"]} ref={ref}>
      <ValidateButton
        className={style["generate-label-button"]}
        title="Télécharger"
        handleOnClick={() => setExpand(!expand)}
      />
      {expand && (
        <div className={style["print-mode-list-container"]}>
          <div className={style["print-mode-list"]}>
            {PRINTMODE.map((mode, index) => (
              <div
                className={`${style["mode-item"]} ${
                  isDesktop ? style["hover"] : style["active"]
                }`}
                key={index}
                onClick={() =>
                  handleGetShippingLabel(
                    shipping.shippingMethod?.shippingService as string,
                    mode[1],
                    shipping.externalShipping?.reservationNumber
                  )
                }
              >
                {mode[0]}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );

  async function handleGetShippingLabel(
    shippingService: string,
    mode: string,
    reservationNumber?: string
  ) {
    if (!reservationNumber) {
      return;
    }
    try {
      const response: any = await getShippingLabel(
        shippingService,
        reservationNumber,
        mode
      );

      const uint8Array = new Uint8Array(response.data);
      const blob = new Blob([uint8Array], {
        type: mode === "ZPL" ? "x-application/zpl" : "application/pdf",
      });

      const downloadLink = URL.createObjectURL(blob);
      // setDocURI(downloadLink);
      const link = document.createElement("a");
      link.href = downloadLink;
      link.download = `chronopost-${reservationNumber}.${
        mode === "ZPL" ? "zpl" : "pdf"
      }`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setExpand(false);
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
}
