import { useState } from "react";
import { IAddress, ICustomer } from "../../../../types/customer.type";
import styles from "./addressesForm.module.scss";
import Address from "../Address/Address";
import AddressForm from "../../../../components/forms/addressForm/addressForm";
import { IShippingPoint } from "../../../../types/shipping.types";
import { isDesktop } from "react-device-detect";
import clsx from "clsx";

export const INITIAL_ADDRESS: IAddress = {
  address1: "",
  address2: "",
  zipCode: "",
  countryCode: "FR",
  city: "",
  country: "France",
};

interface AddressesFormProps {
  customer: ICustomer;
  onAddressesChange: (addresses: IAddress[]) => void;
}

export default function AddressesForm({
  customer,
  onAddressesChange,
}: AddressesFormProps) {
  const [selectedAddress, setSelectedAddress] = useState<IAddress | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  if (!customer.addresses) {
    return <>Liste d'adresses indisponible</>;
  }

  const addresses = customer.addresses;

  return (
    <div className={styles["addresses-form"]}>
      <div className={styles["addresses-list"]}>
        {addresses.map((address: IAddress, index: number) => {
          return (
            <Address
              address={address}
              onDelete={() => removeAddress(index)}
              onSelect={() => handleClickOnAddress(address, index)}
              key={index}
            />
          );
        })}
        <div
          className={clsx(
            styles["add-new-button"],
            isDesktop ? styles["hover"] : styles["active"]
          )}
          onClick={addEmptyAddress}
        >
          ajouter une nouvelle adresse
        </div>
      </div>
      {selectedAddress && (
        <>
          <AddressForm
            className={styles["address-form"]}
            defaultAddress={selectedAddress as IShippingPoint}
            contact={getContactFromCustomer()}
            isShippingAddress={false}
            onChange={handleAddressChange}
          />
          <div className={styles["bottom-container"]}>
            <div
              className={styles["validate-button"]}
              onClick={handleValidateAddress}
            >
              Valider
            </div>
            <div
              className={clsx(
                styles["cancel-button"],
                isDesktop ? styles["hover"] : styles["active"]
              )}
              onClick={() => setSelectedAddress(null)}
            >
              annuler
            </div>
          </div>
        </>
      )}
    </div>
  );

  function handleClickOnAddress(address: IAddress, index: number) {
    if (index === selectedIndex) {
      setSelectedAddress(null);
      setSelectedIndex(null);
    } else {
      setSelectedAddress(address);
      setSelectedIndex(index);
    }
  }

  function addEmptyAddress() {
    setSelectedAddress(INITIAL_ADDRESS);
    setSelectedIndex(addresses.length || 0);
  }

  function handleValidateAddress() {
    if (selectedIndex === addresses.length) {
      onAddressesChange([...addresses, selectedAddress as IAddress]);
    } else {
      onAddressesChange(
        addresses.map((address, index) =>
          index === selectedIndex ? (selectedAddress as IAddress) : address
        )
      );
    }
    setSelectedAddress(null);
    setSelectedIndex(null);
  }

  function handleAddressChange(
    address: IShippingPoint,
    isShippingAddress: boolean
  ) {
    setSelectedAddress(address as IAddress);
  }

  function removeAddress(index: number) {
    setSelectedAddress(null);
    setSelectedIndex(null);
    onAddressesChange(addresses.filter((_, i) => index !== i));
  }

  function getContactFromCustomer() {
    return {
      displayname: customer.familyname,
      givenname: customer.givenname,
      familyname: customer.familyname,
      organization: "",
      phone: customer.phone,
      email: customer.email,
    };
  }
}
