import Widget from "../Widget";
import { Search } from "@mui/icons-material";
import style from "./searchProduct.module.scss";

export default function SearchProductWidget() {
  return (
    <Widget to={"search-product"}>
      <div className={style["icon-container"]}>
        <Search className={style["icon"]} />
      </div>
      <div>Chercher un article</div>
    </Widget>
  );
}
