import { isDesktop } from "react-device-detect";
import style from "./button.module.scss";
import PictureLoadingSpinner from "../loadingSpinner/pictureloadingSpinner";
import { useEffect, useRef, useState } from "react";

interface ButtonProps {
  error?: string | null;
  isLoading?: boolean;
  loadingSpinnerStyle?: string;
  children: React.ReactNode;
}

export default function Button({
  error,
  isLoading,
  loadingSpinnerStyle,
  children,
  ...props
}: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const [buttonWidth, setButtonWidth] = useState<number | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (!isLoading && buttonRef.current) {
      // Calculate and store the button's width when not loading
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, [isLoading, children]);

  return (
    <>
      <button
        {...props}
        className={`${style["button"]} ${
          isDesktop ? style["hover"] : style["active"]
        } ${props.className}`}
        onClick={isLoading ? () => {} : props.onClick}
        style={{
          width: isLoading && buttonWidth ? `${buttonWidth}px` : "auto",
          ...props.style,
        }}
        ref={buttonRef}
      >
        {isLoading ? (
          <PictureLoadingSpinner
            color="white"
            className={style["loading-spinner"]}
          />
        ) : (
          children
        )}
      </button>
      {error && <span className={style["error"]}>* {error}</span>}
    </>
  );
}
