import clsx from "clsx";
import styles from "./price.module.scss";

interface PriceProps {
  className?: string;
  price?: number;
}

export default function Price({ className, price }: PriceProps) {
  if (!price && price !== 0) {
    return (
      <p className={clsx(styles["unavailable"], className)}>{"indisponible"}</p>
    );
  }

  return <p className={clsx(styles["price"], className)}>{price / 100}</p>;
}
