import styles from "./item.module.scss";
import { useState } from "react";
import { IEstimate } from "../../../types/booking.type";
import {
  Delete,
  Edit,
  Email,
  EmailOutlined,
  Launch,
  Phone,
  PhoneOutlined,
} from "@mui/icons-material";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import { useModalComponent } from "../../../context/modalComponent.context";
import EstimateModal from "../../../components/modals/estimateModal/estimateModal";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import CenterCell from "../../../components/CenterCell/CenterCell";
import State from "../../../components/State/State";
import ProductsCell from "./ProductsCell";
import clsx from "clsx";
import { ModalContainer } from "../../../components/modals/ModalContainer";

export default function Item({
  data,
  index,
  onDelete,
}: {
  data: IEstimate;
  index: number;
  onDelete: (id?: string) => void;
}) {
  const [hover, setHover] = useState<Boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Launch />,
      name: "Ouvrir",
      onClick: () => {
        toogleEstimateModal();
      },
    },
    {
      icon: <Edit />,
      name: "Modifier",
      to: `/estimate/edit/${data.id}`,
    },
    {
      icon: <Delete />,
      name: "Supprimer",
      onClick: () => onDelete(data.id),
    },
  ];

  return (
    <tr
      className={styles["item"]}
      style={{
        backgroundColor: `${hover ? "#e4e4e7" : ""}`,
      }}
    >
      <td
        className={`${styles["item-cell"]} ${styles["item-date"]}`}
        title={`${new Date(data?.createdAt).toLocaleString()}`}
      >
        {new Date(data?.createdAt).toLocaleDateString("fr-FR")}
      </td>
      <td className={clsx(styles["item-cell"], styles["coordinates"])}>
        <div className={styles["item-coord"]}>
          <span className={styles["displayname"]}>
            {data.contact.displayname}
          </span>
          <span>{data.contact.organization}</span>
          <span>{data.contact.phone}</span>
          <span>{data.contact.email}</span>
        </div>
        {!!data.contactPreference ? (
          <>
            {data.contactPreference === "email" ? (
              <EmailOutlined className={styles["icon"]} />
            ) : (
              <PhoneOutlined className={styles["icon"]} />
            )}
          </>
        ) : null}
      </td>
      <td
        className={clsx(styles["item-cell"], styles["product-cell"])}
        onClick={toogleEstimateModal}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <ProductsCell productsList={data?.productsList} />
      </td>
      <td className={clsx(styles["item-cell"], styles["status-box"])}>
        <CenterCell>
          <State state={data.state} />
        </CenterCell>
      </td>
      <td className={`${styles["item-cell"]} ${styles["tools"]}`}>
        <ProtectedComponent roles={["ROLE_ADMIN"]}>
          <CenterCell>
            <MoreTools options={MORE_TOOLS_OPTIONS} />
          </CenterCell>
        </ProtectedComponent>
      </td>
      <ModalContainer isOpen={modalIsOpen} onCancel={toogleEstimateModal}>
        <EstimateModal id={data.id} />
      </ModalContainer>
    </tr>
  );

  function toogleEstimateModal() {
    setModalIsOpen(!modalIsOpen);
  }
}
