import { useState } from "react";
import { IShipping } from "../../../../types/shipping.types";
import style from "./generateLabel.module.scss";
import ValidateButton from "../../../../components/validateButton/validateButton";
import { toast } from "react-toastify";
import { cancelChronopostLabel } from "../../../../requests/shipping";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../../utils/toast.options";
import DownloadLabelButton from "../DownloadLabelButton/DownloadLabelButton";

interface GenerateLabelProps {
  shipping: IShipping | null;
  setShipping: (shipping: IShipping | null) => void;
  setDocument: (value: string | null) => void;
  resetForm: () => void;
  generateLabel: (setIsLoading: (value: boolean) => void) => void;
}

export default function GenerateLabel({
  shipping,
  setShipping,
  setDocument,
  resetForm,
  generateLabel,
}: GenerateLabelProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState<boolean>(false);

  function resetToShopForm() {
    resetForm();
    setShipping(null);
  }

  return (
    <>
      {shipping ? (
        <div className={style["generate-label-section"]}>
          <DownloadLabelButton
            shipping={shipping}
            // reservationNumber={shipping.externalShipping?.reservationNumber}
          />
          <ValidateButton
            className={style["generate-label-button"]}
            title="Annuler"
            handleOnClick={handleCancelShippingLabel}
            isLoading={isLoadingCancel}
          />
          <ValidateButton
            className={style["generate-label-button"]}
            title="Génerer un nouveau bon"
            handleOnClick={resetToShopForm}
            // isLoading={isLoadingCancel}
          />
        </div>
      ) : (
        <ValidateButton
          className={style["generate-label-button"]}
          title="Génerer le bon CHRONPOST"
          handleOnClick={() => generateLabel(setIsLoading)}
          isLoading={isLoading}
        />
      )}
    </>
  );

  async function handleCancelShippingLabel() {
    if (!shipping || !shipping.id) {
      return toast.error("Shipping ID is missing", TOAST_ERROR_OPTIONS);
    }
    setIsLoadingCancel(true);
    try {
      await cancelChronopostLabel(shipping.id);
      toast.success(
        "Le bon Chronppost a été annulé avec succès",
        TOAST_SUCCESS_OPTIONS
      );
    } catch (error: any) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    } finally {
      setIsLoadingCancel(false);
      setShipping(null);
      setDocument(null);
    }
  }
}
