import { Outlet, RouteObject } from "react-router-dom";
import Chronotrace from ".";
import { authloader } from "../../routes";
import ErrorPage from "../errorPage/error-page";

export const route: RouteObject = {
  path: "/chronotrace",
  element: <Outlet />,
  errorElement: <ErrorPage />,
  loader: authloader,
  children: [
    {
      index: true,
      element: <Chronotrace />,
    },
  ],
};
