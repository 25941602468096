import State from "../../../../components/State/State";
import { IShippingPro } from "../../../../types/shipping.types";
import style from "./shippingProCard.module.scss";
import { Link } from "react-router-dom";

interface ShippingProCardProps {
  shippingPro: IShippingPro;
  direction: string;
}

export default function ShippingProCard({
  shippingPro,
  direction,
}: ShippingProCardProps) {
  const SHOP_NAME =
    direction === "INCOMING"
      ? shippingPro.sender.organization
      : shippingPro.recipient.organization;

  return (
    <Link
      className={style["shipping-pro-card"]}
      to={`/quick-access/pro-${direction.toLocaleLowerCase()}-shipping/${
        shippingPro.shopId
      }?shopName=${SHOP_NAME}&shipping-id=${shippingPro.id}`}
    >
      <div className={style["shipping-pro-card-left"]}>
        <div className={style["organization"]}>{SHOP_NAME}</div>
        <div>
          <div>
            {direction === "OUTGOING" ? "Livraison" : "Collecte"} du{" "}
            {shippingPro.shippingDate
              ? new Date(shippingPro.shippingDate)
                  .toLocaleString("fr-FR")
                  .substring(0, 10)
              : "non-défini"}
          </div>
          <div>
            {shippingPro.trackId?.length} articles{" "}
            {direction === "INCOMING" ? "réceptionnés" : "préparés"}
          </div>
        </div>
      </div>
      <div className={style["state"]}>
        <State state={shippingPro.state} />
      </div>
    </Link>
  );
}
