import { Link } from "react-router-dom";
import translate from "../../../../utils/translation";
import { IShippingPro } from "../../../../types/shipping.types";
import style from "./item.module.scss";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../../utils/toast.options";
import { toast } from "react-toastify";
import ValidateButton from "../../../validateButton/validateButton";
import {
  cancelShippingProLabel,
  getShippingProLabel,
  reserveShippingProLabel,
  updateShippingPro,
} from "../../../../requests/shippingPro";
import { useState } from "react";
import { PRINTMODE, STATE_OPTIONS } from "../../../../utils/shipping.init";
import { isDesktop } from "react-device-detect";
import useClickOutside from "../../../../hooks/useClickOutSide";
import Select from "../../../Select/Select";
import { CancelScheduleSend, Download, Launch } from "@mui/icons-material";
import getStatusColor from "../../../../utils/translation/statusColor";
import Button from "../../../Button/Button";

interface ItemProps {
  refetch: () => void;
  shipping: IShippingPro;
  zIndex: number;
}

export default function Item({ refetch, shipping, zIndex }: ItemProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [expand, setExpand] = useState<boolean>(false);
  const [isCancelLoading, setIsCancelLoading] = useState<boolean>(false);
  const ref = useClickOutside<HTMLDivElement>(() => setExpand(false));

  const GENERATE_SHIPPING_LABEL =
    shipping.shippingMethod.shippingService.includes("CHRONOPOST") &&
    !shipping.externalShipping?.externalId &&
    !shipping.externalShipping?.reservationNumber;
  const SHOP_NAME =
    shipping.direction === "INCOMING"
      ? shipping.sender.organization
      : shipping.recipient.organization;

  return (
    <div className={style["shipping-pro-item"]}>
      <div className={style["shipping-pro-item-left"]}>
        <div className={style["shipping-direction"]}>
          {translate(shipping.direction)}
        </div>
        <div className={style["shipping-date"]}>
          {shipping.shippingDate
            ? new Date(shipping.shippingDate)
                .toLocaleString("fr-Fr")
                .substring(0, 10)
            : "non défini"}
        </div>
        <Select
          className={style["select-state"]}
          style={{
            width: "140px",
            backgroundColor: getStatusColor(shipping.state),
            color: "white",
          }}
          optionsList={STATE_OPTIONS}
          value={shipping.state}
          setValue={(value) => handleStateChange(value, shipping)}
        />
      </div>
      {GENERATE_SHIPPING_LABEL && (
        <ValidateButton
          title="Génerer un bon chronpost"
          handleOnClick={reserveShippingLabel}
          isLoading={isLoading}
        />
      )}
      {shipping.externalShipping?.externalId && (
        <Link
          className={style["chronopost-tracking"]}
          onClick={() => window.scrollTo(0, 0)}
          to={`https://www.chronopost.fr/tracking-no-cms/suivi-page?listeNumerosLT=${shipping.externalShipping.externalId}&langue=fr`}
        >
          {shipping.externalShipping.externalId}
        </Link>
      )}
      {shipping.externalShipping &&
        shipping.externalShipping?.reservationNumber && (
          <div className={style["shipping-label-action-container"]}>
            <div
              className={style["download-container"]}
              ref={ref}
              style={{ zIndex: `${zIndex}` }}
            >
              <Button
                className={style["download-button"]}
                onClick={() => setExpand(!expand)}
              >
                <Download />
              </Button>
              {expand && (
                <div className={style["print-mode-list-container"]}>
                  <div>
                    <div className={style["print-mode-list"]}>
                      {PRINTMODE.map((mode) => (
                        <div
                          className={`${style["mode-item"]} ${
                            isDesktop ? style["hover"] : style["active"]
                          }`}
                          onClick={() =>
                            handleGetShippingLabel(
                              mode[1],
                              shipping.externalShipping
                                ?.reservationNumber as string
                            )
                          }
                        >
                          {mode[0]}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Button
              className={style["cancel-button"]}
              onClick={() => handleCancelShippingLabel(shipping.id)}
              isLoading={isCancelLoading}
            >
              <CancelScheduleSend />
            </Button>
          </div>
        )}
      <Link
        className={style["open-link"]}
        to={`/shipping-pro/${shipping.id}?shop=${encodeURIComponent(
          SHOP_NAME || ""
        )}&shipping-date=${shipping.shippingDate}&direction=${
          shipping.direction
        }`}
      >
        <Launch />
      </Link>
    </div>
  );

  async function handleStateChange(
    newState: string,
    shippingPro: IShippingPro
  ) {
    try {
      if (
        window.confirm(
          `Êtes-vous sur de vouloir passer cette ${
            shipping.direction === "INCOMING" ? "collecte" : "livraison"
          } en "${translate(newState)}" ?`
        )
      ) {
        shippingPro.state = newState;
        const updateResponse = await updateShippingPro(shippingPro);
        refetch();
      }
    } catch (error: any) {
      toast.error(
        "Un problème est survenu lors du changement de status du document ShippingPro.",
        TOAST_ERROR_OPTIONS
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function reserveShippingLabel() {
    setIsLoading(true);
    if (!shipping.id) {
      return toast.error(
        "Un problème est survenu. [shippingId is missing]",
        TOAST_ERROR_OPTIONS
      );
    }
    try {
      await reserveShippingProLabel(shipping.id);
      refetch();
    } catch (error: any) {
      toast.error(
        "Un problème est survenu lors de la création du bon Chronopost.",
        TOAST_ERROR_OPTIONS
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function handleGetShippingLabel(
    mode: string,
    reservationNumber?: string,
    setIsLoading?: (value: boolean) => void
  ) {
    if (!reservationNumber) {
      toast.error("[reservationNumber] is missing.", TOAST_ERROR_OPTIONS);
      return;
    }
    try {
      setIsLoading && setIsLoading(true);
      const response: any = await getShippingProLabel(reservationNumber, mode);
      const uint8Array = new Uint8Array(response.data);
      const blob = new Blob([uint8Array], {
        type: mode === "ZPL" ? "x-application/zpl" : "application/pdf",
      });

      const downloadLink = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = downloadLink;
      link.download = `chronopost-${reservationNumber}.${
        mode === "ZPL" ? "zpl" : "pdf"
      }`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setExpand(false);
    } catch (error: any) {
      throw new Error(error.message);
    } finally {
      setIsLoading && setIsLoading(false);
    }
  }

  async function handleCancelShippingLabel(id?: string) {
    setIsCancelLoading(true);
    if (!id) {
      toast.error("Shipping ID is missing", TOAST_ERROR_OPTIONS);
      return;
    }
    try {
      await cancelShippingProLabel(id);
      toast.success(
        "Le bon Chronppost a été annulé avec succès",
        TOAST_SUCCESS_OPTIONS
      );
      refetch();
    } catch (error: any) {
      toast.error(
        "Un problème est survenu lors de l'annulation.",
        TOAST_ERROR_OPTIONS
      );
    } finally {
      refetch();
      setIsCancelLoading(false);
    }
  }
}
