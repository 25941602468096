import { ITracker } from "../../../types/tracker.types";
import styles from "./item.module.scss";
import { useNavigate } from "react-router-dom";
import logoChrono from "../../../assets/logo-chronopost-small.png";
import logoSalon from "../../../assets/logo-salon.png";
import logoGP from "../../../assets/favicon-32x32.png";
import CenterCell from "../../../components/CenterCell/CenterCell";
import clsx from "clsx";
import { isDesktop } from "react-device-detect";
import State from "../../../components/State/State";

export default function Item({ data }: { data: ITracker }) {
  const navigate = useNavigate();

  function getIcon(shippingService: string) {
    if (shippingService && shippingService.includes("CHRONOPOST")) {
      return logoChrono;
    } else if (shippingService && shippingService.includes("DROP")) {
      return logoSalon;
    } else {
      return logoGP;
    }
  }

  return (
    <tr
      className={clsx(
        styles["item"],
        isDesktop ? styles["hover"] : styles["active"]
      )}
      onClick={(ev) => {
        if (ev.ctrlKey) {
          window.open(`./overview/${data.id}`, "_blank");
        } else {
          navigate(`./${data.id}`);
        }
      }}
    >
      <td className={clsx(styles["item-cell"], styles["item-date"])}>
        {new Date(data.eventsList[0]?.date).toLocaleDateString("fr-FR")}
      </td>
      <td className={clsx(styles["item-cell"], styles["item-date"])}>
        {data.contact?.displayname}
      </td>
      <td
        className={clsx(
          styles["item-cell"],
          styles["state-box"],
          styles["tracker-state"]
        )}
      >
        <CenterCell>
          <State state={data.state} isTracker />
        </CenterCell>
      </td>
      <td className={clsx(styles["item-cell"], styles["state-box"])}>
        <CenterCell>
          <State state={data?.estimate?.state} />
        </CenterCell>
      </td>
      <td className={clsx(styles["item-cell"], styles["state-box"])}>
        <CenterCell>
          <State state={data?.cart?.state} />
        </CenterCell>
      </td>
      <td className={clsx(styles["item-cell"], styles["state-box"])}>
        <CenterCell className={styles["shipping"]}>
          {data?.incomingShipping && (
            <img
              className={styles["image"]}
              src={getIcon(data.incomingShipping?.shippingService)}
            />
          )}
          <State
            state={data?.incomingShipping?.state}
            className={styles["shipping-state"]}
          />
        </CenterCell>
      </td>
      <td className={clsx(styles["item-cell"], styles["state-box"])}>
        <CenterCell>
          <State state={data?.logistic?.state} />
        </CenterCell>
      </td>
      <td className={clsx(styles["item-cell"], styles["state-box"])}>
        <CenterCell>
          <State state={data?.quote?.state} />
        </CenterCell>
      </td>
      <td className={clsx(styles["item-cell"], styles["state-box"])}>
        <CenterCell className={styles["shipping"]}>
          {data?.outgoingShipping && (
            <img
              className={styles["image"]}
              src={getIcon(data.outgoingShipping?.shippingService)}
            />
          )}
          <State
            state={data?.outgoingShipping?.state}
            className={styles["shipping-state"]}
          />
        </CenterCell>
      </td>
    </tr>
  );
}
