import { toast } from "react-toastify";
import Select from "../../../components/Select/Select";
import { queryObject } from "../../../types/query.types";
import {
  OPERATION_GROUP,
  OPERTATION_TYPE,
  PRODUCT_GROUP,
} from "../../../utils/logistic.init";
import styles from "./filters.module.scss";
import { STD_ERROR_MESSAGE } from "../../../utils/constants";
import { exportOperations } from "../../../requests/operation";
import { TOAST_ERROR_OPTIONS } from "../../../utils/toast.options";
import { SmallModalContainer } from "../../../components/modals/SmallModalContainer";
import ImportXlsxModal from "../../../components/modals/ImportXlsxModal/ImportXlsxModal";
import { useState } from "react";
import FiltersContainer from "../../../components/Filters/FiltersContainer";
import clsx from "clsx";

interface FiltersProps {
  query: queryObject;
  onFilterChange: (name: string, value: string) => void;
  reset: () => void;
}

export default function Filters({
  query,
  onFilterChange,
  reset,
}: FiltersProps) {
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);

  return (
    <FiltersContainer>
      <div className={styles["import-export-section"]}>
        <span
          className={clsx(styles["import-export-item"])}
          onClick={handleToogleExportModal}
        >
          Importer
        </span>
        <span
          className={clsx(styles["import-export-item"])}
          onClick={handleExportOperations}
        >
          Exporter
        </span>
      </div>
      <div>
        <label>Groupe d'opérations</label>
        <Select
          name={"operationGroup"}
          optionsList={OPERATION_GROUP}
          value={query.operationGroup || "Tous"}
          setValue={(value) => onFilterChange("operationGroup", value)}
        />
      </div>
      <div>
        <label>Type d'opérations</label>
        <Select
          name={"operationType"}
          optionsList={OPERTATION_TYPE}
          value={query.operationType || "Tous"}
          setValue={(value) => onFilterChange("operationType", value)}
        />
      </div>
      <div>
        <label>Groupe d'articles</label>
        <Select
          name={"productGroup"}
          optionsList={PRODUCT_GROUP}
          value={query.productGroup || "Tous"}
          setValue={(value) => onFilterChange("productGroup", value)}
        />
      </div>
      <span className={clsx(styles["import-export-item"])} onClick={reset}>
        annuler les filtres
      </span>
      <SmallModalContainer
        isOpen={openImportModal}
        handleCancel={handleToogleExportModal}
      >
        <ImportXlsxModal />
      </SmallModalContainer>
    </FiltersContainer>
  );

  function handleToogleExportModal() {
    setOpenImportModal(!openImportModal);
  }

  async function handleExportOperations() {
    try {
      // setIsLoading(true);
      const response = await exportOperations(formatedExportQuery());
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "data-export.xlsx";
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error: any) {
      toast.error(STD_ERROR_MESSAGE, TOAST_ERROR_OPTIONS);
    } finally {
      // setIsLoading(false);
    }
  }

  function formatedExportQuery() {
    let formatedQuery: string = "";

    if (query.operationGroup && query.operationGroup !== "Tous") {
      formatedQuery += `&operationGroup=${query.operationGroup}`;
    }

    if (query.operationType && query.operationType !== "Tous") {
      formatedQuery += `&operationType=${query.operationType}`;
    }

    if (query.productGroup && query.productGroup !== "Tous") {
      formatedQuery += `&productGroup=${query.productGroup}`;
    }

    if (query.field && query.value) {
      formatedQuery = formatedQuery + `&${query.field}=${query.value}`;
    }

    return formatedQuery;
  }
}
