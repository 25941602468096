import style from "./createChronopost.module.scss";
import logo from "../../../assets/logo-chronopost.png";
import Widget from "../Widget";

export default function ChronopostWidget() {
  return (
    <Widget to={"create-chronopost"}>
      <div className={style["icon-container"]}>
        <img src={logo} className={style["chronopost-icon"]} />
      </div>
      <div>Bon CHRONOPOST</div>
    </Widget>
  );
}
