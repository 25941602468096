import clsx from "clsx";
import styles from "./radioButton.module.scss";

interface RadioButtonProps {
  className?: string;
  label?: string;
}

export default function RadioButton({
  className,
  label,
  ...props
}: RadioButtonProps & React.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <div className={clsx(styles["radio-button"], className)}>
      <input className={styles["radio-input"]} type="radio" {...props} />
      <label className={styles["label"]} htmlFor={props.id}>
        {label}
      </label>
    </div>
  );
}
