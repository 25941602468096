import { User } from "../../pages/login";

export function ProtectedComponent({
  children,
  roles,
}: {
  children: JSX.Element;
  roles: string[];
}) {
  let user: User = {
    uid: "",
    username: "",
    roles: [""],
    isAuthenticated: false,
  };

  if (localStorage.getItem("gp-management.user")) {
    user = JSON.parse(localStorage.getItem("gp-management.user") || "");
  }
  if (
    user.roles &&
    !user.roles.some((userRole: string) => roles.includes(userRole))
  ) {
    return <></>;
  }
  return children;
}
