import clsx from "clsx";
import styles from "./tabNavigation.module.scss";
import { isDesktop } from "react-device-detect";
import { OverviewTabs } from "../../../../../types/overview.types";
import { ITracker } from "../../../../../types/tracker.types";
import useElementDimensions from "../../../../../hooks/useElementDimensions";

interface TabNavigationProps {
  selectedTab: OverviewTabs;
  onSelect: (tab: OverviewTabs) => void;
  tracker: ITracker;
}

export default function TabNavigation({
  selectedTab,
  tracker,
  onSelect,
}: TabNavigationProps) {
  const [ref, width] = useElementDimensions<HTMLUListElement>([]);

  return (
    <div className={styles["tab-navigation"]}>
      <ul className={styles["tabs-list"]} ref={ref}>
        <Tab
          title={"Résumé"}
          onSelect={() => onSelect("summary")}
          isSelected={selectedTab == "summary"}
          isVisible
        />
        <Tab
          title={"Articles"}
          onSelect={() => onSelect("logistic")}
          isSelected={selectedTab == "logistic"}
          isVisible={!!tracker.logistic}
        />
        <Tab
          title={"Estimation"}
          onSelect={() => onSelect("estimation")}
          isSelected={selectedTab == "estimation"}
          isVisible={!!tracker.estimate}
        />
        <Tab
          title={"Livraison & Collecte"}
          onSelect={() => onSelect("shipping")}
          isSelected={selectedTab == "shipping"}
          isVisible={!!tracker.incomingShipping || !!tracker.outgoingShipping}
        />
        <Tab
          title={"Devis"}
          onSelect={() => onSelect("quote")}
          isSelected={selectedTab == "quote"}
          isVisible={!!tracker.quote}
        />
        <Tab
          title={"Historique"}
          onSelect={() => onSelect("history")}
          isSelected={selectedTab == "history"}
          isVisible={!!tracker.eventsList}
        />
      </ul>
      <div className={styles["separator"]} style={{ width }}></div>
    </div>
  );
}

interface TabProps {
  title: string;
  isSelected: boolean;
  onSelect: () => void;
  isVisible: boolean;
}

function Tab({ title, isSelected, onSelect, isVisible }: TabProps) {
  if (!isVisible) {
    return null;
  }

  return (
    <li
      className={clsx(
        styles["tab"],
        isDesktop ? styles["hover"] : styles["active"],
        isSelected && styles["selected"]
      )}
      onClick={onSelect}
    >
      {title}
    </li>
  );
}
