import { useState } from "react";
import styles from "./sectionFormTemplate.module.scss";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import clsx from "clsx";

interface SectionFormTemplateProps {
  title: string;
  collapsedDefault?: boolean;
  children: React.ReactNode;
  className?: string;
}

export default function SectionFormTemplate({
  title,
  children,
  className,
  collapsedDefault = false,
}: SectionFormTemplateProps) {
  const [collapsed, setCollapsed] = useState(collapsedDefault);
  return (
    <div
      className={clsx(
        styles["section-form-template"]
        // collapsed && styles["no-margin"]
      )}
    >
      <hr className={styles["separator"]} />
      <div
        className={clsx(
          styles["section-form"],
          collapsed && styles["collapsed"]
        )}
      >
        <div
          className={clsx(
            styles["left"],
            collapsed && styles["no-margin"],
            className
          )}
          onClick={handleCollapse}
        >
          <div className={styles["collapsible"]}>
            {collapsed ? (
              <AddCircleOutline className={styles["icon"]} />
            ) : (
              <RemoveCircleOutline className={styles["icon"]} />
            )}
          </div>
          <p>{title}</p>
        </div>
        <div className={styles["right"]}>{children}</div>
      </div>
    </div>
  );

  function handleCollapse() {
    setCollapsed((prev) => !prev);
  }
}
