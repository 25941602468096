import { useEffect, useState } from "react";
import { IProduct } from "../../../types/logistic.types";
import styles from "./quoteEdit.module.scss";
import { IQuote, IShippingMethod } from "../../../types/accounting.types";
import { updateQuote } from "../../../requests/quote";
import { Send } from "@mui/icons-material";
import ContactInfoCard from "../../../components/contactInfoCard/contactInfoCard";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../../../utils/toast.options";
import ConfirmModalComp from "../../../components/confirmationModal/ConfirmModalComp";
import { useConfirmModal } from "../../../context/confirmationModalContext";
import ProductInformations from "./ProductInformations/ProductInformations";
import TimeLimit from "./TimeLimit/TimeLimit";
import Discount from "./Discount/Discount";
import ShippingMode from "./ShippingMode/ShippingMode";
import ModalPortal from "../../../components/modals/ModalPortal";
import ConfirmModal from "../../../components/modals/ConfirmModal/ConfirmModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useChangePopState } from "../../../hooks/useChangePopState";
import Price from "../../../components/Price/Price";

interface QuoteEditProps {
  defaultQuote: IQuote;
  onSuccess?: () => void;
  onUpdate?: (quote: IQuote) => void;
  sendEmail?: boolean;
  targetState?: string;
}

export default function QuoteEdit({
  defaultQuote,
  onUpdate,
  onSuccess,
  sendEmail = true,
  targetState = "QUOTE_SENT",
}: QuoteEditProps) {
  const [quote, setQuote] = useState<IQuote>(defaultQuote);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const { setConfirmModalIsOpen, setConfirmModalChildren } = useConfirmModal();
  useChangePopState(); // Change previousUrl by a new previousUrl 🙃

  if (!quote) {
    return <></>;
  }

  return (
    <div className={styles["quote-edit-container"]}>
      <ContactInfoCard
        contact={quote?.contact}
        trackId={defaultQuote.trackId}
      />
      {/* <div className={styles["main-title"]}>Devis</div> */}
      <div className={styles["widgets-container"]}>
        <div className={styles["widget-container"]}>
          <ProductInformations
            onRefashionChange={handleProductsListChange}
            productsList={quote.productsList}
            operationsTotal={quote.operationsTotal}
          />
        </div>
        <div className={styles["right-widgets"]}>
          <div className={styles["right-widget-container"]}>
            <TimeLimit quote={quote} onChange={handleOnChange} />
          </div>
          <div className={styles["right-widget-container"]}>
            <Discount discount={quote?.discount} />
          </div>
          <div className={styles["right-widget-container"]}>
            <ShippingMode
              quote={quote}
              updateShippingMethod={handleChangeShippingMethod}
              handleUpdateDocument={handleUpdateDocument}
            />
          </div>
        </div>
      </div>
      <div className={styles["bottom-widget"]}>
        <div className={styles["widget-container"]}>
          <div className={styles["total-price-widget-container"]}>
            <span className={styles["title"]}>Total devis</span>
            <Price className={styles["title"]} price={quote?.total} />
          </div>
        </div>
        <div className={styles["send-button-container"]}>
          <button
            className={styles["send-button"]}
            onClick={handleCheckRefashion}
          >
            <Send />
          </button>
        </div>
      </div>
      <ConfirmModal
        isOpen={confirmModal}
        title={"Bonus refashion"}
        message={
          "Avant de continuer, veuillez-vous assurer d'avoir affecté les bonus réparation."
        }
        onCancel={() => setConfirmModal(!confirmModal)}
        onConfirm={() => {
          setConfirmModal(!confirmModal);
          handleConfirmCreateDocument();
        }}
      />
    </div>
  );

  function handleChangeShippingMethod(shippingMethod: IShippingMethod) {
    setQuote((prev) => ({ ...prev, shipping: shippingMethod }));
  }

  async function handleConfirmCreateDocument() {
    if (sendEmail) {
      setConfirmModalIsOpen(true);
      setConfirmModalChildren(
        <ConfirmModalComp
          confirmButtonClassName={styles["validate-button"]}
          title={"Voulez-vous envoyer le devis ?"}
          confirmText="Oui"
          onConfirm={handleCreateDocument}
        />
      );
    } else {
      handleCreateDocument();
    }
  }

  async function handleCheckRefashion() {
    if (!(quote?.minimumDurationDays && quote?.maximumDurationDays)) {
      return toast.error(
        "Veuillez entrer les délais pour le devis !",
        TOAST_ERROR_OPTIONS
      );
    }
    setConfirmModal(true);
  }

  async function handleCreateDocument() {
    try {
      await updateQuote({ ...quote, state: targetState }, true, sendEmail);
      onSuccess && onSuccess();
    } catch (error: any) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
  }

  async function handleUpdateDocument() {
    try {
      await updateQuote(quote);
      onUpdate && onUpdate(quote);
    } catch (error: any) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
  }

  function handleOnChange({ target }: { target: HTMLInputElement }) {
    const newQuote = { ...quote };
    newQuote[target.name as keyof IQuote] = parseInt(target.value);
    setQuote(newQuote);
  }

  function handleProductsListChange(products: IProduct[]) {
    setQuote({ ...quote, productsList: products });
  }
}
