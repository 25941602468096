import { ACRONYM } from "../../utils/translation/operation";
import styles from "./printOperationsChecklist.module.scss";

interface PrintOperationsChecklistProps {
  operations: string[];
  toggleAcronymInList: (value: string) => void;
}
export default function PrintOperationsChecklist({
  operations,
  toggleAcronymInList,
}: PrintOperationsChecklistProps) {
  return (
    <div className={styles["print-operations-checklist"]}>
      {ACRONYM.map((op: string, index) => (
        <OperationAcronymCheck
          acronym={op}
          handleSelectAcronym={() => toggleAcronymInList(op)}
          isChecked={operations.some((operation) => operation === op)}
          key={index}
        />
      ))}
    </div>
  );
}

interface OperationAcronymCheckProps {
  acronym: string;
  handleSelectAcronym: () => void;
  isChecked: boolean;
}

function OperationAcronymCheck({
  acronym,
  handleSelectAcronym,
  isChecked,
}: OperationAcronymCheckProps) {
  return (
    <div
      className={styles["operation-acronym-check"]}
      onClick={handleSelectAcronym}
    >
      <input
        className={styles["check-box"]}
        type="checkbox"
        checked={isChecked}
        // onChange={handleSelectAcronym}
      />
      <p className={styles["acronym"]}>{acronym}</p>
    </div>
  );
}
