import { IShop } from "../../../../types/shop.types";
import ContactForm from "../../../../components/forms/ContactForm/ContactForm";
import AddressForm from "../../../../components/forms/addressForm/addressForm";
import { IContact } from "../../../../types/logistic.types";
import PricingModal from "../../../../components/modals/pricingModal/pricingModal";
import ShopForm from "../ShopForm/ShopForm";
import ShopValidation from "../ShopValidation/ShopValidation";
import style from "./stepByStepDispatch.module.scss";

export default function StepByStepDispatch({
  step = 1,
  shop,
  onChange,
}: {
  step?: number;
  shop: IShop;
  onChange: (shop: IShop) => void;
}) {
  switch (step) {
    case 1:
      return (
        <div>
          <h1>Information license boutique</h1>
          <ShopForm
            shop={shop}
            setShop={(shop: IShop) => {
              onChange(shop);
            }}
          />
        </div>
      );
    case 2:
      return (
        <div>
          <h1>Information contact boutique</h1>
          <ContactForm
            autoComplete={false}
            contact={shop.contact}
            setContact={(contact: IContact) => {
              onChange({ ...shop, contact: contact as IShop["contact"] });
            }}
          />
        </div>
      );

    case 3:
      return (
        <div>
          <h1>Adresse de la boutique</h1>
          <AddressForm
            contact={shop.contact}
            defaultAddress={shop.contact}
            onChange={(contact: IContact) => {
              onChange({ ...shop, contact: contact as IShop["contact"] });
            }}
            isShippingAddress={false}
          />
        </div>
      );
    case 4:
      return (
        <PricingModal
          className={style["pricing-modal"]}
          shopForm={shop}
          setShopForm={(shop: IShop) => {
            onChange(shop);
          }}
        />
      );
    case 5:
      return <ShopValidation shop={shop} setShop={onChange} />;
    default:
      return <div></div>;
  }
}
