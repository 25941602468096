import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../errorPage/error-page";
import Create from "./create/create";
import { authloader } from "../../routes";
import Edit from "./edit/edit";
import ProfessionalCarts from ".";

export const route: RouteObject = {
  path: "/professionalCarts",
  element: <Outlet />,
  loader: authloader,
  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <ProfessionalCarts />,
    },
    {
      path: "/professionalCarts/:id",
      element: <ProfessionalCarts />,
    },
    {
      path: "create/:id",
      element: <Create />,
    },
    {
      path: "edit/:id",
      element: <Edit />,
    },
  ],
};
