import { MoreHoriz } from "@mui/icons-material";
import Button from "../Button/Button";
import styles from "./moreAction.module.scss";
import clsx from "clsx";
import { cloneElement, useState } from "react";
import DropDownPortal from "../DropDownPortal/DropDownPortal";
import useElementDimensions from "../../hooks/useElementDimensions";
import BeautifulDiv from "../beautifulDiv/BeautifulDiv";
import { IMoreToolsOptions } from "../MoreTools/MoreTools";
import { Link } from "react-router-dom";

interface MoreActionProps {
  options: IMoreToolsOptions[];
  className?: string;
}

export default function MoreAction({ options, className }: MoreActionProps) {
  const [expand, setExpand] = useState<boolean>(false);
  const [refDrop, widthDrop] = useElementDimensions<HTMLDivElement>([expand]);
  const [ref, width] = useElementDimensions<HTMLDivElement>([expand]);

  return (
    <div className={clsx(styles["more-action"], className)}>
      <div className={styles["more-action-container"]} ref={ref}>
        <Button
          className={styles["action-button"]}
          onClick={handleToggleDropDown}
        >
          Plus d'actions <MoreHoriz className={styles["icon"]} />
        </Button>
      </div>
      <DropDownPortal<HTMLDivElement>
        buttonRef={ref}
        className={styles["more-action-dropdown"]}
        expand={expand}
        onClickOutside={handleCloseDropDown}
        offset={{ top: 4, left: (widthDrop || 0) - (width || 0) }}
      >
        <div className={styles["option-list"]} ref={refDrop}>
          {options.map((option, index: number) => {
            const styledIcon: JSX.Element | null = option.icon
              ? cloneElement(option.icon, {
                  className: styles["option-list-item-icon"],
                })
              : null;
            if (option.hidden) {
              return null;
            }
            return option.to ? (
              <Link
                className={clsx(styles["option-list-item"], {
                  [styles["disabled"]]: option.disabled,
                })}
                key={index}
                onClick={() => handleClickOnOption(option.onClick)}
                to={option.to}
              >
                {styledIcon}
                {option.name}
              </Link>
            ) : (
              <div
                className={clsx(styles["option-list-item"], {
                  [styles["disabled"]]: option.disabled,
                })}
                key={index}
                onClick={() => handleClickOnOption(option.onClick)}
              >
                {styledIcon}
                {option.name}
              </div>
            );
          })}
        </div>
        <BeautifulDiv />
      </DropDownPortal>
    </div>
  );

  function handleClickOnOption(fct?: () => void) {
    fct && fct();
    setExpand(false);
  }

  function handleToggleDropDown() {
    setExpand(!expand);
  }

  function handleCloseDropDown() {
    setExpand(false);
  }
}
