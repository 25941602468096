import { IProduct } from "../../../types/logistic.types";
import styles from "./productsCell.module.scss";
import shoe_logo from "../../../assets/shoe.png";
import leather_logo from "../../../assets/leather.png";
import Price from "../../../components/Price/Price";

interface ProductsCellProps {
  productsList: IProduct[];
}

export default function ProductsCell({ productsList }: ProductsCellProps) {
  const productGroupList = getProductGroupList();

  return (
    <div className={styles["item-product"]}>
      <div className={styles["product-group-list"]}>
        {productGroupList.map((pg, index) => (
          <ProductGroupLogo key={index} productGroup={pg} />
        ))}
      </div>
      {productsList.map((product: IProduct, index: number) => {
        return (
          <ProductListOperationDescription key={index} product={product} />
        );
      })}
    </div>
  );

  function getProductGroupList() {
    let productGroupList: string[] = [];

    for (let product of productsList) {
      if (!productGroupList.includes(product.productGroup)) {
        productGroupList.push(product.productGroup);
      }
    }

    return productGroupList;
  }
}

interface ProductGroupLogoProps {
  productGroup: string;
}

function ProductGroupLogo({ productGroup }: ProductGroupLogoProps) {
  switch (productGroup) {
    case "SHOES":
      return <img src={shoe_logo} className={styles["icon"]} />;
    case "LEATHER":
      return <img src={leather_logo} className={styles["icon"]} />;
    default:
      return <></>;
  }
}

interface ProductListDescriptionProps {
  product: IProduct;
}

function ProductListOperationDescription({
  product,
}: ProductListDescriptionProps) {
  const totalPrice = product?.operationsList?.reduce(
    (accumulator, product) => accumulator + (product.price ?? 0),
    0
  );

  if (!totalPrice) {
    return <></>;
  }

  return (
    <div className={styles["product-desc"]}>
      <div className={styles["brand"]}>{product.brand}</div>
      <Price
        className={styles["price"]}
        price={product?.operationsList?.reduce(
          (accumulator, product) => accumulator + (product.price ?? 0),
          0
        )}
      />
    </div>
  );
}
