import { toast } from "react-toastify";
import AddressForm from "../../../../../components/forms/addressForm/addressForm";
import ValidateButton from "../../../../../components/validateButton/validateButton";
import { IContact } from "../../../../../types/logistic.types";
import {
  IPickUpLocationInfo,
  IShippingPoint,
  PickUpInfo,
} from "../../../../../types/shipping.types";
import style from "./addressSection.module.scss";
import { useRef, useState } from "react";
import { TOAST_ERROR_OPTIONS } from "../../../../../utils/toast.options";
import { getPickUpLocation } from "../../../../../requests/shipping";
import { PickUpLocationAddress } from "../../../../../components/forms/shippingForm/addressMiniature/pickUpLocationMiniature";
import {
  findCountyFromCountryCode,
  scrollToElement,
} from "../../../../../utils/utils";

interface AddressSectionProps {
  setPickUpInfo: (pickUpInfo: PickUpInfo | null) => void;
  shippingForm: IShippingPoint | null;
  setShippingForm: (shippingForm: IShippingPoint | null) => void;
  setStep: (value: number) => void;
}

export default function AddressSection({
  setPickUpInfo,
  shippingForm,
  setShippingForm,
  setStep,
}: AddressSectionProps) {
  const NO_ADDRESS_SELECTED = -1;
  const FORM_FILLED = 2;
  const [addressForm, setAddressForm] = useState<IShippingPoint | null>(
    shippingForm
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pickUpLocationList, setPickUpLocationList] = useState<
    IPickUpLocationInfo[]
  >([]);
  const [selectedAddress, setSelectedAddress] =
    useState<number>(NO_ADDRESS_SELECTED);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <AddressForm
        className={style["address-form"]}
        contact={shippingForm as IContact}
        defaultAddress={shippingForm as IContact}
        isShippingAddress
        onChange={setAddressForm}
      />
      <ValidateButton
        className={style["generate-label-button"]}
        handleOnClick={handleGetPickUpLocation}
        isLoading={isLoading}
        title="Trouver le point relais"
      />
      <div className={style["pick-up-list"]} ref={ref}>
        {pickUpLocationList.map((address, index) => (
          <PickUpLocationAddress
            address={address}
            isSelected={index === selectedAddress}
            key={index}
            setSelectedAddress={() => setSelectedAddress(index)}
          />
        ))}
      </div>
      {selectedAddress > -1 && (
        <ValidateButton
          className={style["generate-label-button"]}
          title="Valider"
          handleOnClick={handleValidateAddress}
        />
      )}
    </>
  );

  function handleValidateAddress() {
    if (addressForm) {
      setShippingForm({
        ...shippingForm,
        ...formatAddress(pickUpLocationList[selectedAddress]),
      });
      setStep(FORM_FILLED);
      setPickUpInfo({
        pickUpId: pickUpLocationList[selectedAddress].id,
        name: pickUpLocationList[selectedAddress].name,
      });
    }
  }

  async function handleGetPickUpLocation() {
    setSelectedAddress(NO_ADDRESS_SELECTED);
    if (!shippingForm) {
      return;
    }
    if (!addressForm) {
      return;
    }
    try {
      setIsLoading(true);
      const response = await getPickUpLocation(addressForm, new Date());
      setPickUpLocationList(response);
      scrollToElement(ref.current, 1000);
    } catch (error: any) {
      toast.error(
        "Un problème est survenu. Veuillez entrez une adresse avec un numéro de rue.",
        TOAST_ERROR_OPTIONS
      );
      setPickUpLocationList([]);
    } finally {
      setIsLoading(false);
    }
  }

  function formatAddress(address: IShippingPoint & IPickUpLocationInfo) {
    return {
      address1: address?.address1 || "",
      address2: address?.address2 || "",
      city: address?.city || "",
      zipCode: address?.zipCode || "",
      country: findCountyFromCountryCode(address?.countryCode || "FR"),
      countryCode: address?.countryCode || address?.country_code || "FR",
    };
  }
}
