import { Moped, Store } from "@mui/icons-material";
import styles from "./shippingServiceLogo.module.scss";
import translate from "../../utils/translation";
import chronopostLogo from "../../assets/logo-chronopost.png";
import { Link } from "react-router-dom";
import clsx from "clsx";

interface ShippingServiceLogoProps {
  shippingService?: string;
  externalId?: string;
}

export default function ShippingServiceLogo({
  shippingService,
  externalId,
}: ShippingServiceLogoProps) {
  if (!shippingService) {
    return <></>;
  }

  switch (true) {
    case shippingService.includes("INTERNAL"):
      return (
        <div className={styles["shipping-service"]}>
          <Moped className={styles["icon"]} />
          {translate(shippingService)}
        </div>
      );
    case shippingService.includes("DROP-OR-COLLECT"):
      return (
        <div className={styles["shipping-service"]}>
          <Store className={styles["icon"]} />
          {translate(shippingService)}
        </div>
      );
    case shippingService.includes("CHRONO"):
      return !!externalId ? (
        <Link
          className={styles["chronopost-link"]}
          target="_blank"
          to={`https://www.chronopost.fr/tracking-no-cms/suivi-page?listeNumerosLT=${externalId}&langue=fr`}
        >
          <img src={chronopostLogo} className={styles["logo"]} />
        </Link>
      ) : (
        <img
          src={chronopostLogo}
          className={clsx(styles["logo"], styles["unavailable"])}
        />
      );
    default:
      return (
        <div className={styles["shipping-service"]}>
          {translate(shippingService)}
        </div>
      );
  }
}
