import clsx from "clsx";
import { IDiscount } from "../../types/accounting.types";
import styles from "./discountValue.module.scss";

interface DiscountValueProps {
  className?: string;
  discount?: IDiscount | null;
}

export default function DiscountValue({
  className,
  discount,
}: DiscountValueProps) {
  if (!discount) {
    return null;
  }
  return (
    <div
      className={clsx(
        className,
        styles["discount-value"],
        discount.isRate ? styles["rate"] : styles["price"]
      )}
    >
      {discount.isRate ? discount.value : discount.value / 100}
    </div>
  );
}
