import Widget from "../Widget";
import style from "./climbing.module.scss";
import logo from "../../../assets/climbing.png";

export default function ClimbingWidget() {
  return (
    <Widget to={"climbing"}>
      <div className={style["icon-container"]}>
        <img src={logo} className={style["icon"]} />
      </div>
      <div>Chaussons d'escalades</div>
    </Widget>
  );
}
