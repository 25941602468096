import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useHideSideBar } from "../../../context/hideSideBar.context";
import { useGetPath } from "../../../utils/utils";
import MenuItemContainer from "../../../layouts/NavigationBar/MenuItemContainer/MenuItemContainer";
import { SvgIconTypeMap } from "@mui/material";
import styles from "./responsiveMenuItem.module.scss";
import { Link } from "react-router-dom";
import clsx from "clsx";

interface ResponsiveMenuItemProps {
  link: string;
  title: string;
  Logo: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  SelectedLogo: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  onClick?: () => void;
}

export default function ResponsiveMenuItem({
  link,
  title,
  Logo,
  SelectedLogo,
  onClick,
}: ResponsiveMenuItemProps) {
  const isSelected = useGetPath(title);

  return (
    <MenuItemContainer title={title}>
      <Link to={link} className={styles["menu-item-link"]} onClick={onClick}>
        <div className={styles["menu-item-icon-container"]}>
          {isSelected ? (
            <SelectedLogo className={styles["menu-item-icon"]} />
          ) : (
            <Logo className={styles["menu-item-icon"]} />
          )}
        </div>
        <p className={clsx(styles["menu-item-title"])}>{title}</p>
      </Link>
    </MenuItemContainer>
  );
}
