import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchQuote } from "../../../../../requests/quote";
import OverviewSection from "../OverviewSection/OverviewSection";
import styles from "./quoteSection.module.scss";
import translate from "../../../../../utils/translation";
import { IInvoice } from "../../../../../types/tracker.types";
import { ModalContainer } from "../../../../../components/modals/ModalContainer";
import QuoteModal from "../../../../../components/modals/quoteModal/quoteModal";
import OverviewSectionHeader from "../OverviewSectionHeader/OverviewSectionHeader";
import QuoteProduct from "./QuoteProduct/QuoteProduct";
import QuoteSumUp from "./QuoteSumUp/QuoteSumUp";
import PictureLoadingSpinner from "../../../../../components/loadingSpinner/pictureloadingSpinner";
import DataError from "../../../../../components/errors/DataError/DataError";
import QuoteAction from "./QuoteAction/QuoteAction";
import State from "../../../../../components/State/State";
import RequiredAction from "../../../../../components/RequiredAction/RequiredAction";

interface QuoteSectionProps {
  quoteId: string;
  invoice?: IInvoice;
  requiredActions?: boolean;
}

export default function QuoteSection({
  quoteId,
  invoice,
  requiredActions,
}: QuoteSectionProps) {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const { data, isLoading, refetch } = useQuery(
    ["quote", quoteId],
    () => fetchQuote(quoteId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(quoteId),
    }
  );

  useEffect(() => {
    refetch();
  }, [modalIsOpen]);

  if (!quoteId) {
    return <></>;
  }

  if (isLoading) {
    return (
      <OverviewSection className={styles["loading-section"]}>
        <PictureLoadingSpinner />
      </OverviewSection>
    );
  }

  if (!data) {
    <OverviewSection className={styles["loading-section"]}>
      <DataError error={"quote"} />
    </OverviewSection>;
  }

  return (
    <OverviewSection className={styles["quote-section"]}>
      <OverviewSectionHeader title={"Devis"} onClick={handleOpenQuoteModal}>
        <State state={data?.state} className={styles["state"]} />
      </OverviewSectionHeader>
      {requiredQuoteActions() && (
        <RequiredAction className={styles["required-action"]} />
      )}
      <ul className={styles["product-list"]}>
        {data?.productsList?.map((product, i) => (
          <QuoteProduct product={product} key={i} />
        ))}
      </ul>
      <QuoteSumUp
        className={styles["sub-total"]}
        title={"Sous-total"}
        value={data?.operationsTotal || 0}
      />
      <QuoteSumUp
        className={styles["sub-total"]}
        title={`Livraison (${translate(data?.shipping?.shippingService)})`}
        value={data?.shipping?.price || 0}
      />
      {!!data?.discountTotal && (
        <QuoteSumUp
          title={"Code promo"}
          value={-data?.discountTotal}
          className={styles["sub-total"]}
        />
      )}
      <QuoteSumUp
        title={"Total"}
        value={data?.total || 0}
        className={styles["total"]}
      />
      <QuoteAction trackId={data?.trackId} />
      <ModalContainer isOpen={modalIsOpen} onCancel={handleOpenQuoteModal}>
        <QuoteModal
          id={quoteId}
          invoice={invoice}
          requiredActions={requiredActions}
        />
      </ModalContainer>
    </OverviewSection>
  );

  function requiredQuoteActions() {
    return requiredActions && data?.state === "QUOTE_VALIDATED";
  }

  function handleOpenQuoteModal() {
    setModalIsOpen(!modalIsOpen);
  }
}
