import styles from "./item.module.scss";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import { Check, Close, Edit, Launch } from "@mui/icons-material";
import { IAddress, ICustomer } from "../../../types/customer.type";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import CenterCell from "../../../components/CenterCell/CenterCell";
import clsx from "clsx";

export default function Item({ data }: { data: ICustomer }) {
  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Edit />,
      name: "Modifier",
      to: `/customers/edit/${data.id}`,
    },
  ];

  return (
    <tr className={styles["item"]}>
      <td className={clsx(styles["item-cell"], styles["item-coord"])}>
        <span>
          {data.familyname} {data.givenname}
        </span>
      </td>
      <td className={clsx(styles["item-cell"], styles["item-coord"])}>
        {data.email}
      </td>
      <td className={clsx(styles["item-cell"], styles["item-coord"])}>
        {data.phone}
      </td>
      <td className={clsx(styles["item-cell"], styles["address-list"])}>
        {data.addresses?.map((address: IAddress, key: number) => {
          return (
            <div className={styles["address"]} key={key}>
              <div>
                {address.address1} {address.address2}
                {","} {address.zipCode} {address.city}
                {","} {address.country}
              </div>
              <div></div>
            </div>
          );
        })}
      </td>
      <td className={`${styles["item-cell"]} ${styles["status-box"]}`}>
        <CenterCell>{data.verified ? <Check /> : <Close />}</CenterCell>
      </td>
      <td className={clsx(styles["item-cell"], styles["tools"])}>
        <ProtectedComponent roles={["ROLE_ADMIN"]}>
          <CenterCell>
            <MoreTools options={MORE_TOOLS_OPTIONS} />
          </CenterCell>
        </ProtectedComponent>
      </td>
    </tr>
  );
}
