import styles from "./descriptionSection.module.scss";
import SectionFormTemplate from "../../../../../../components/forms/sectionFormTemplate/SectionFormTemplate";

interface DescriptionSectionProps {
  description?: string;
  onChange: (name: string, value: string) => void;
}

export default function DescriptionSection({
  description,
  onChange,
}: DescriptionSectionProps) {
  return (
    <SectionFormTemplate title={"Description"}>
      <textarea
        className={styles["description"]}
        value={description}
        onChange={({ target }) => onChange("description", target.value)}
      />
    </SectionFormTemplate>
  );
}
