import { useQuery } from "react-query";
import PictureLoadingSpinner from "../../../../../../components/loadingSpinner/pictureloadingSpinner";
import OverviewSection from "../../OverviewSection/OverviewSection";
import styles from "./overviewContact.module.scss";
import { fetchCustomer } from "../../../../../../requests/customer";
import DataError from "../../../../../../components/errors/DataError/DataError";
import { Launch, MailOutlined, PhoneOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";

interface OverviewContactProps {
  contactId: string;
  trackId: string;
}

export default function OverviewContact({
  contactId,
  trackId,
}: OverviewContactProps) {
  const { data, isLoading, refetch } = useQuery(
    ["contact", contactId],
    () => fetchCustomer(contactId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(contactId),
    }
  );

  if (!contactId) {
    return (
      <OverviewSection className={styles["contact-section"]}>
        <></>
      </OverviewSection>
    );
  }

  if (isLoading) {
    return (
      <OverviewSection className={styles["loading-section"]}>
        <PictureLoadingSpinner />
      </OverviewSection>
    );
  }

  if (!data) {
    <OverviewSection className={styles["contact-section"]}>
      <DataError error={"contact"} />
    </OverviewSection>;
  }

  return (
    <OverviewSection className={styles["contact-section"]}>
      <div className={styles["displayname-container"]}>
        <h1 className={styles["displayname"]}>
          {data?.givenname + " " + data?.familyname}
        </h1>
        <Link
          to={`/customers/edit/${contactId}`}
          className={styles["to-customer"]}
        >
          <Launch className={styles["icon"]} />
        </Link>
      </div>
      <div className={styles["coordinates"]}>
        <p className={styles["email"]}>
          <MailOutlined className={styles["coordinates-icon"]} />
          {data?.email}
        </p>
        <p className={styles["phone"]}>
          <PhoneOutlined className={styles["coordinates-icon"]} />
          {data?.phone}
        </p>
      </div>
    </OverviewSection>
  );
}
