import React, { useEffect, useState } from "react";
import { IProduct } from "../../../../../../types/logistic.types";
import styles from "./productTab.module.scss";
import { fetchLogisticList } from "../../../../../../requests/logistic";
import OperationsForm from "../OperationForm/OperationForm";
import ProductInformationSection from "../ProductInformationSection/ProductInformationSection";
import { workshopIdIsInvalid } from "../../../../../../utils/logistic.function";

export default function ProductTab({
  product,
  onChange,
  trackId,
}: {
  product: IProduct;
  onChange: (product: IProduct) => void;
  trackId: string;
}) {
  const [validationError, setValidationError] = useState<string[]>([]);

  useEffect(() => {
    handleValidateForm(product);
  }, [product]);

  return (
    <div className={styles["product-tab"]}>
      <div className={styles["product-tab-content"]}>
        <ProductInformationSection
          trackId={trackId}
          product={product}
          onChange={onChange}
          validationError={validationError}
          setValidationError={setValidationError}
        />
        <OperationsForm product={product} onChange={handleChange} />
      </div>
    </div>
  );

  function handleChange(product: IProduct): void {
    onChange && onChange(product);
  }

  async function handleValidateForm(product: IProduct) {
    const newValidationError = [...validationError];
    newValidationError.splice(0);
    (!product.color || product.color === "") &&
      newValidationError.push("color");
    (!product.brand || product.brand === "") &&
      newValidationError.push("brand");
    (!product.productGroup || product.productGroup === "") &&
      newValidationError.push("productGroup");
    (!product.operationGroup || product.operationGroup === "") &&
      newValidationError.push("operationGroup");
    if (!product.size) {
      product.size = "";
    }
    (!product.workshopId ||
      product.workshopId.length < 7 ||
      (await workshopIdIsInvalid(product.workshopId, product.id))) &&
      newValidationError.push("workshopId");
    !product.category && newValidationError.push("category");
    setValidationError(newValidationError);
  }
}
