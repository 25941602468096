import { Fragment, useEffect } from "react";
import { fetchOperationsList } from "../../../../requests/operation";
import { useQuery } from "react-query";
import LoadingSpinner from "../../../loadingSpinner/loadingSpinner";
import { OperationForm } from "../../../../types/accounting.types";
import { IShopOperation } from "../../../../types/shop.types";
import { OPERATION_GROUP } from "../../../../utils/logistic.init";
import styles from "./operationTable.module.scss";
import TableHeader from "./TableHeader/TableHeader";
import OperationItem from "./OperationItem/OperationItem";
import OperationGroupItem from "./OperationGroupItem/OperationGroupItem";

interface OperationTableProps {
  operationsList: IShopOperation[];
  onOperationSelect: (
    operation: OperationForm[] | OperationForm,
    operationGroup?: string
  ) => void;
  proCategory: "base" | "luxe";
}

export default function OperationTable({
  operationsList,
  onOperationSelect,
  proCategory,
}: OperationTableProps) {
  const { data, isLoading } = useQuery(
    ["all-operation-list"],
    () => fetchOperationsList(),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (isLoading || !data || !operations) {
      return;
    }
    const updatedPricesList = operations.filter((dbOp) =>
      operationsList.find((op) => dbOp.slug === op.slug)
    );
    onOperationSelect(updatedPricesList);
  }, [proCategory]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!data) {
    return <>Erreur operation</>;
  }

  const { operations } = data as {
    operations: OperationForm[];
    totalCount: number;
  };

  return (
    <table className={styles["table"]}>
      <TableHeader />
      <tbody className={styles["table-body"]}>
        {OPERATION_GROUP.map((opGroup, opIndex) => (
          <Fragment key={opIndex}>
            <OperationGroupItem
              key={opIndex}
              isSelected={operationGroupIsChecked(opGroup)}
              operationGroup={opGroup}
              onSelect={() =>
                onOperationSelect(
                  operations.filter((op) => op.operationGroup === opGroup) ||
                    [],
                  operationGroupIsChecked(opGroup) ? opGroup : undefined
                )
              }
            />
            {operations
              .filter((op) => op.operationGroup === opGroup)
              .map((op, index) => (
                <OperationItem
                  key={`${opIndex}-${index}`}
                  isChecked={isChecked(op.slug)}
                  operation={op}
                  proCategory={proCategory}
                  onOperationSelect={() => onOperationSelect(op)}
                />
              ))}
          </Fragment>
        ))}
      </tbody>
    </table>
  );

  function isChecked(slug: string) {
    return operationsList.find((op) => op.slug === slug) !== undefined;
  }

  function operationGroupIsChecked(operationGroup: string) {
    const db = operations.filter(
      (op) => op.operationGroup === operationGroup
    ).length;
    const shop = operationsList.filter(
      (op) => op.operationGroup === operationGroup
    ).length;

    return db === shop;
  }
}
