import { useState } from "react";
import translate from "../../../../../utils/translation";
import { OperationForm } from "../../../../../types/accounting.types";
import styles from "./operationGroupItem.module.scss";

interface OperationGroupItemProps {
  isSelected: boolean;
  operationGroup: string;
  onSelect: () => void;
}

export default function OperationGroupItem({
  isSelected,
  operationGroup,
  onSelect,
}: OperationGroupItemProps) {
  return (
    <tr className={styles["operation-category-title"]}>
      <td className={styles["item-cell"]} colSpan={1}>
        <input
          className={styles["checkbox"]}
          type="checkbox"
          checked={isSelected}
          onChange={() => {}}
          onClick={onSelect}
        />
      </td>
      <td className={styles["item-cell"]} colSpan={5}>
        {translate(operationGroup)}
      </td>
    </tr>
  );
}
