import clsx from "clsx";
import Price from "../../../../../../components/Price/Price";
import { IOperation } from "../../../../../../types/accounting.types";
import styles from "./quoteOperation.module.scss";

interface QuoteOperationProps {
  operation: IOperation;
}

export default function QuoteOperation({ operation }: QuoteOperationProps) {
  return (
    <li
      className={clsx(
        styles["operation"],
        operation.canceled && styles["canceled"]
      )}
    >
      <p className={styles["description"]}>{operation.description}</p>
      <div className={styles["dotted"]}></div>
      <Price price={operation.price} className={styles["price"]} />
    </li>
  );
}
